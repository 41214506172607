import { ROUTING_URLS } from "../utils/constants";

export const routesMapping = [
  {
    path: ROUTING_URLS.artists,
    name: "My Artists",
  },
  {
    path: ROUTING_URLS.upcomingBookings,
    name: "Bookings Upcoming",
  },
  {
    path: ROUTING_URLS.history,
    name: "Payments History",
  },
  {
    path: ROUTING_URLS.artists,
    name: "Artists",
  },
  {
    path: ROUTING_URLS.artists,
    name: "Artists",
  },
  {
    path: ROUTING_URLS.address,
    name: "Account Details",
  },
  {
    path: ROUTING_URLS.contact,
    name: "Contact",
  },
  {
    path: ROUTING_URLS.changePassword,
    name: "Change Password",
  },
  {
    path: ROUTING_URLS.billingAddress,
    name: "Billing Address",
  },
  {
    path: ROUTING_URLS.bankDetails,
    name: "Bank Details",
  },
  {
    path: ROUTING_URLS.vatDetails,
    name: "VAT Details",
  },
  {
    path: ROUTING_URLS.receipts,
    name: "Receipts",
  },
  {
    path: ROUTING_URLS.notifications,
    name: "Notifications",
  },
  {
    path: ROUTING_URLS.account,
    name: "Account registration",
  },
  {
    path: ROUTING_URLS.payoutReceipts,
    name: "Payout Receipts",
  },
  {
    path: ROUTING_URLS.invoiceReceipts,
    name: "Invoice Receipts",
  },
  {
    path: ROUTING_URLS.termsAndConditions,
    name: "Terms & Conditions",
  },
  {
    path: ROUTING_URLS.privacyPolicy,
    name: "Privacy Policy",
  },
  {
    path: ROUTING_URLS.rules,
    name: "Rules",
  },
  {
    path: ROUTING_URLS.impressum,
    name: "Impressum",
  },
  {
    path: ROUTING_URLS.publicPrivacyPolicy,
    name: "Privacy Policy",
  },
  {
    path: ROUTING_URLS.publicTermsAndConditions,
    name: "Terms & Conditions",
  },
];

export const pageNames = {
  dashboard: 'Dashboard',
  myArtist: 'My Artists',
  upcoming: 'Upcoming Bookings',
  paymentHistory: 'Payment History',
  reciepts: 'Receipts',
  invoiceReciepts: 'invoice Receipts',
  billingAddress: 'Billing Address',
  bankDetails: 'Bank Details',
  vatDetails: 'VAT Details',
  accountDetails: 'Account Details',
  account: 'Account',
  contact: 'Contact',
  password: 'Change Password',
  terms: 'Terms & Conditions',
  privacy: 'Privacy policy',
  impressum: 'Impressum'
}
import React, { useImperativeHandle, forwardRef } from "react";
import { DeepPartial, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

interface Props<T> {
  defaultValues?: DeepPartial<T>;
  onSubmit: SubmitHandler<T>;
  children: React.ReactElement | React.ReactElement[];
  [key: string]: any;
}

export const Form = forwardRef<any, any>((props, ref): JSX.Element => {
  const { defaultValues, validationSchema, children, onSubmit } = props;
  const options: any = {
    mode: "onBlur",
    defaultValues: defaultValues || {},
    resolver: null,
  };
  if (Object.keys(validationSchema).length)
    options.resolver = yupResolver(validationSchema);
  const methods = useForm(options);
  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = methods;

  useImperativeHandle(ref, () => ({
    reset: () => {
      reset();
    },
  }));

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {Array.isArray(children)
        ? children.map((child: React.ReactElement) => {
            return child.props?.name
              ? React.createElement(child.type, {
                  ...{
                    ...child.props,
                    control: methods.control,
                    getValues: methods.getValues,
                    key: child.props.name,
                    error: errors ? !!errors[child.props.name] : false,
                    helperText:
                      errors && errors[child.props.name]
                        ? errors[child.props.name]?.message
                        : child.props.helperText,
                  },
                })
              : child;
          })
        : children}
    </form>
  );
});
export default Form;

import CIcon from "@coreui/icons-react";
import { CLink, CTooltip } from "@coreui/react";
import { useState } from "react";
import AlertBox from "../../../components/AlertBox";
import './style.scss';

const alertData = {
    title: 'You want to delete your studio account?',
    text: (
        <>
            <p>To delete your account please contact us directly using the email address:</p>
            <a href="mailto:hello@inckd.com">hello@inckd.com</a>
            <p className="mt-3">inckd. Team</p>
        </>
    )
};

function DeleteAccount() {
    const [alertOpen, setAlertOpen] = useState(false);

    const handleDeleteAccount = () => {
        setAlertOpen(true);
    };
    return (
        <>
            <AlertBox 
            data={alertData} 
            alertOpen={alertOpen} 
            setAlertOpen={setAlertOpen}></AlertBox>
            <div className="d-flex justify-content-end">
                <CTooltip content="Delete Account">
                    <CLink> 
                        <CIcon onClick={handleDeleteAccount} icon="cil-trash" className="delete-btn icon-custom-size text-danger"></CIcon>
                    </CLink>
                </CTooltip>
            </div>
        </>
    )
}

export default DeleteAccount

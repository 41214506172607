import React, { useState } from "react";
import {
  CAvatar,
  CBadge,
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownItemPlain,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { useDispatch, useSelector } from "../../../store";
import moment from "moment";
import getRedirectionObj from "../../../modules/notifications/functions";
import { Link, useHistory } from "react-router-dom";
import { readNotification } from "../../../slices/notifications";
import "./style.scss";
import { useAuth } from "../../../hooks";
import { ReactComponent as BellIcon } from './../../../icons/bell.svg';

moment.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "%d second",
    ss: "%d seconds",
    m: "%d minute",
    mm: "%d minutes",
    h: "%d hour",
    hh: "%d hours",
    d: "%d day",
    dd: "%d days",
    w: "a week",
    ww: "%d weeks",
    M: "a month",
    MM: "%d months",
    y: "a year",
    yy: "%d years",
  },
});

const NotificationDropdown = () => {
  const { rows, isLoading } = useSelector((state) => state.notifications);
  let history = useHistory();
  const { active_status } = useAuth();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [keyRef, setKeyRef] = useState(0);
  const rowsRefined = rows.filter((x: any) => x.read_status !== "read");
  function handleClick(to: string, uid: string) {
    setVisible(false);
    setKeyRef(keyRef + 1);
    dispatch(readNotification(uid));
    history.push(to);
  }
  function handleViewAllClick() {
    setVisible(false);
    setKeyRef(keyRef + 1);
    history.push("/notifications");
  }
  return (
    <CDropdown
      className={`d-md-down-none notification-wrapper ${!active_status ? 'in-active' : 'active'}`}
      variant="nav-item"
      visible={visible}
      key={keyRef}
    >
      <CDropdownToggle
        className="py-0"
        caret={false}
        onClick={() => setVisible(true)}
      >
        {/* <CIcon name="cil-bell" className="me-2" /> */}
        <BellIcon className="me-2" />
        {rowsRefined.length > 0 && (
          <span className="notification-count">
            {/* <span>
              {rowsRefined.length}
            </span> */}
          </span>
        )}
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        {!rowsRefined.length && (
          <CDropdownItem>
            <p className="p-4 m-0">No unread notifications</p>
          </CDropdownItem>
        )}
        {rowsRefined.slice(0, 5).map((row: any, index: any) => {
          const { navigateTo } = getRedirectionObj(row);
          return (
            <CDropdownItem
              onClick={() => handleClick(navigateTo, row.notification_uid)}
              key={index}
            >
              <div
                className="row"
                style={{
                  display: "flex",
                }}
              >
                <div className="notification-time">
                  <small className="text-muted float-right mt-1">
                    {moment(row.created_at).fromNow()}
                  </small>
                </div>
                <div className="text-body font-weight-bold notification-titile">
                  <span className="fa fa-exclamation text-danger"></span>{" "}
                  {row.title}
                </div>
                <div className="small notification-text">
                  <div dangerouslySetInnerHTML={{ __html: row.content }} />
                </div>
              </div>
            </CDropdownItem>
          );
        })}
        <CDropdownDivider />
        <CDropdownItem
          component="div"
          className="text-center notification-view-all"
          onClick={handleViewAllClick}
        >
          View all messages
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default NotificationDropdown;

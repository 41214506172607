import { CAvatar, CBadge, CButton, CModalFooter } from "@coreui/react";
import moment from "moment";
import AddToCalendar from "../../../components/AddToCalendar";
import { EArtistTypes } from "../../../utils/enums";
import { formateDate, numToFixed } from "../../../utils/functions";
import { getArtistName } from "../../artists/functions";
import BookingDetails from "./Details";
import { getStatusColor } from "./functions";

function BookingDetailsPopup({ data }: any) {
    const {
        status,
        artist,
        project_name,
        customer,
        price,
        offer_date,
        duration,
        studio,
        currency: { code },
        payment: { studio_price_withVAT }
    } = data;
    const isFreelancer = studio.relation === EArtistTypes.Freelancer;
    const displayName = getArtistName(artist);
    const customerName = getArtistName(customer);
    const startTime = moment(offer_date).format('HH:mm');
    const endTime = moment(offer_date).add(duration, 'minutes').format('HH:mm');
    let statusLabel = status;
    const labelColorClass = getStatusColor(statusLabel)
    if (statusLabel === 'postponed_pending') statusLabel = 'new date pending';
    return (
        <>
            <div className="mb-4">
                <div className="d-flex align-items-center justify-content-between mb-2">
                    <div className="d-flex align-items-center">
                        <h6 className="text-capitalize mb-0 text-truncate pe-2 title-xs-upcoming">
                            <b>{displayName}</b>
                        </h6>
                    </div>
                    <CBadge className={`text-capitalize rel-label ${labelColorClass} border-0 text-nowrap`}>{statusLabel}</CBadge>
                </div>
            </div>
            <div className="d-flex justify-content-between mb-3">
                <div className="d-flex flex-column">
                    <h6 className="mb-0"><b>{project_name}</b></h6>
                    <span>{customerName}</span>
                </div>
                <div className="d-flex justify-content-between currency-wrap">
                    <span className="currency"><b>{code}</b></span>
                    <b>{(isFreelancer && !studio.periodic) ? numToFixed(price) : numToFixed(studio_price_withVAT)}</b>
                </div>
            </div>
            <div className="d-flex flex-column mb-5">
                <span>Date: {formateDate(offer_date)}</span>
                <span>Time: {startTime} to {endTime}</span>
            </div>
            <BookingDetails row={data} />
            <CModalFooter className="mt-4 px-0 pb-0 custom-modal-footer">
                <AddToCalendar
                    event={{
                        title: project_name,
                        description: "inckd. appointment",
                        location: `${studio.name}, ${studio.city_name}`,
                        startTime: moment(offer_date).format(),
                        endTime: moment(offer_date)
                            .add(duration, "minutes")
                            .format(),
                    }}
                />
            </CModalFooter>
        </>
    )
}

export { BookingDetailsPopup }

import moment from "moment";
import { EReceiptTypes } from ".";

const RecieptCard = (props: any) => {
    const { data:
        { amount: studio_amount, inckd_amount, date, payout_id, currency, read_status, invoice_read_status },
        navigate, type }
        = props;
    const isPayout = type === EReceiptTypes.Payout;
    const isInvoice = type === EReceiptTypes.Invoice;
    const formatDate = (date: string) => moment(date).format('DD.MM.YYYY');
    const amount = isPayout ? studio_amount : inckd_amount;
    // const isNew = isPayout ? !read_status : !invoice_read_status;
    return (
        <div className="reciept-card" onClick={() => { navigate(payout_id) }}>
            <div className="top-cont">
                <p className="date">{formatDate(date)}</p>
                <p className="id">{payout_id}</p>
            </div>
            <div className="bottom-cont">
                <span className="detail-btn">Show details</span>
                <p className="amount">
                    <span className="currency">{currency}</span>
                    <span className="price">{amount.toFixed(2)}</span>
                </p>
            </div>
        </div>
    )
}

export default RecieptCard;
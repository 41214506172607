import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import CIcon from "@coreui/icons-react";
import { Tooltip } from 'antd';
import DatePicker, { utils } from '@hassanmojab/react-modern-calendar-datepicker';
import _ from 'lodash';
import moment from 'moment';
import * as icon from '@coreui/icons';

import { useHttp } from '../../../hooks';

import { DateRangeSelect, FilterType } from '../_lib/constants';
import FilterChip from './FilterChip';

import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import "../styles.css";

interface AnalyticCardProps {
    iconName?: any;
    title?: string;
    apiEndPoint?: string;
    ref: any;
}

const AnalyticCard: React.FC<AnalyticCardProps> = forwardRef(({
    iconName,
    title,
    apiEndPoint
}, ref) => {
    const [count, setCount] = useState<number>(0);
    const [filterKey, setFilterKey] = useState<string>('');
    const [filterChip, setFilterChip] = useState<string>(FilterType?.total);
    const [dateRange, setDateRange] = useState<Record<string, any>>({
        from: '',
        to: ''
    });
    const [selectedDayRange, setSelectedDayRange] = useState<DateRangeSelect>({
        from: null,
        to: null
    });
    const [globalState, setGlobalState] = useState<boolean>(false);

    const [{ data: res, loading, error }, fetchCountDetails] = useHttp(
        {
            url: `${apiEndPoint}${filterKey}`,
            method: "GET",
        },
        { manual: true }
    );

    const handleTodayClick = (type: string): void => {
        setFilterChip(type);
        setFilterKey(`?filter=${FilterType?.today}`)
        setSelectedDayRange({
            from: null,
            to: null
        })
    };

    const handleDateFilter = (dateRange: DateRangeSelect): void => {
        setSelectedDayRange(dateRange);
        const { from, to } = dateRange;
        const fromDate = `${from?.day}/${from?.month}/${from?.year}` || '';
        const toDate = to ? `${to?.day}/${to?.month}/${to?.year}` : null;
        if (fromDate && toDate) {
            setDateRange({
                from: fromDate,
                to: toDate
            });
            setFilterKey(`?start_date=${moment.utc(fromDate, 'D/M/YYYY').toISOString()}&end_date=${moment.utc(toDate, 'D/M/YYYY').toISOString()}`)
            setFilterChip(FilterType?.dateRange);
            setGlobalState(false);
        }
        
    }

    useEffect(() => {
        fetchCountDetails();
    }, [filterKey]);

    useEffect(() => {
        if (res) {
            const countStat = Number(Object.values(res)[0]);
            setCount(countStat);
        }
    }, [res, error]);

    // Client Updates Global Filter for keep the flow use forwardRef
    useImperativeHandle(ref, () => ({
        toggleToday: () => {
            setFilterChip(FilterType.today);
            setFilterKey(`?filter=${FilterType?.today}`)
            setSelectedDayRange({
                from: null,
                to: null
            });
            setGlobalState(true);
        },
        toggleDateRange: (fromDate: string, toDate: string) => {
            if (fromDate && toDate) {
                setFilterChip(FilterType.dateRange);
                setFilterKey(`?start_date=${moment.utc(fromDate, 'D/M/YYYY').toISOString()}&end_date=${moment.utc(toDate, 'D/M/YYYY').toISOString()}`)
                setGlobalState(true);
            }
        },
        resetFilter: () => {
            if (!loading) {
                setFilterChip(FilterType?.total);
                setGlobalState(false);
                setSelectedDayRange({
                    from: null,
                    to: null
                });
                setFilterKey('');
            }
        }
    }));

    const triggerReset = (): void => {
        setFilterChip(FilterType?.total);
        setSelectedDayRange({
            from: null,
            to: null
        });
        setFilterKey('');
    }

    const renderCustomInput = ({ ref }: any) => (
        <input
            readOnly
            ref={ref}
            className={`datepicker-input ${globalState ? 'gloabl-inp' : ''}`}
            title="Date Range"
            disabled={globalState}
        />
    )

    return (
        <div className='analytics-card mb-xxl-0 mb-md-2 mb-2'>
            <div className={`loader-component ${loading ? 'loading' : ''}`}>
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
            <div className={`card-inner ${loading ? 'loading' : ''}`}>
                <div className='card-head'>
                    <FilterChip
                        filterKey={filterChip !== FilterType?.dateRange ? filterChip : `${dateRange?.from} - ${dateRange.to}`}
                        isGlobal={globalState}
                    />
                    <div className='card-action-row'>
                        <button
                            className={`filter-button ${filterChip === FilterType.today && !globalState ? 'active-btn' : ''} ${globalState ? 'global-active' : ''}`}
                            onClick={handleTodayClick.bind(this, FilterType.today)}
                            title="Today"
                            disabled={globalState}
                        >
                            <CIcon icon='cilCalendarCheck' />
                        </button>
                        <div>
                            <button
                                disabled={globalState}
                                className={`filter-button ${filterChip === FilterType.dateRange && !globalState ? 'active-btn' : null} ${globalState ? 'global-active' : ''}`}
                            >
                                <CIcon icon='cilCalendar' />
                            </button>
                            <DatePicker
                                value={selectedDayRange}
                                onChange={handleDateFilter}
                                shouldHighlightWeekends
                                maximumDate={utils('en').getToday()}
                                renderInput={renderCustomInput}
                            />
                        </div>
                        {filterChip !== FilterType?.total && !globalState && (
                            <button
                                className='filter-button'
                                onClick={triggerReset}
                                title="Reset"
                            >
                                <CIcon icon={icon?.cilReload} />
                            </button>
                        )}
                    </div>
                </div>
                <div className='card-content'>
                    <div className='count-content'>
                        <h4>{count}</h4>
                        <h6>{title}</h6>
                    </div>
                    <div className='right-component'>
                        <CIcon icon={iconName} size='xxl' />
                    </div>
                </div>
            </div>
        </div>
    )
});

export default AnalyticCard;

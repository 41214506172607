import React from "react";

import { CButton, CSpinner } from "@coreui/react";

export function FormButton(props: any) {
  const { label, isLoading, ...rest } = props;
  const buttonIsLoading = isLoading || false;

  return (
    <CButton className="rounded" color="primary" disabled={buttonIsLoading} {...rest} type="submit">
      {label}
      {buttonIsLoading && <CSpinner color="light" size="sm" className="ms-3" />}
    </CButton>
  );
}

import { CAvatar, CBadge } from '@coreui/react'
/* 
for future ref. missing file
import { RefObject } from 'hoist-non-react-statics/node_modules/@types/react';
*/
import React, { useRef, useEffect, useState, useMemo, createRef } from 'react'
import { EArtistTypes } from '../../../utils/enums';
import { formateDate, numToFixed } from '../../../utils/functions';
import { getArtistName } from '../../artists/functions';
import BookingDetails from '../../bookings/Upcoming/Details';

function HistoryDetailsPopup({ row }: any) {
    const {
        artist_firstname,
        artist_lastname,
        artist_profile_image,
        payout_status,
        offer,
        status
    } = row;
    const {
        offer_date,
        price,
        studio,
        project_name,
        customer,
        payment: { studio_price_withVAT, inckd_vat, inckd_studio_service_price, inckd_studio_service_vat_price, studio_earning },
        currency: { code }
    } = offer;
    const customerName = getArtistName(customer);
    const isFreelancer = studio.relation === EArtistTypes.Freelancer;
    let payoutLabel = '';
    (payout_status === 'complete') ? payoutLabel = 'Payout executed' : payoutLabel = 'Payout Pending';

    return (
        <>
            <div className="mb-4">
                <div className="d-flex align-items-center justify-content-between mb-2">
                    <div className="d-flex align-items-center">
                        <h6 className="text-capitalize mb-0 text-truncate pe-2 title-xs-history">
                            <b>
                                {artist_firstname} {artist_lastname}
                            </b>
                        </h6>
                    </div>
                    <CBadge
                        color={payout_status === 'complete' ? 'payout-success' : 'warning'}
                        textColor={payout_status === 'complete' ? 'white' : 'dark'}
                        className={`text-capitalize rel-label border-0 text-nowrap flex-shrink-0`}>{payoutLabel}</CBadge>
                </div>

            </div>
            <div className="d-flex justify-content-between mb-5 tran-box pe-0">
                <div className="d-flex flex-column">
                    <span><b>{project_name}</b></span>
                    <span>{customerName}</span>
                    <span>Date: {formateDate(offer_date)}</span>
                    {status === 'cancelled' && (
                        <span>Status: Cancelled</span>
                    )}
                </div>
                <div className="d-flex justify-content-between currency-wrap">
                    <span className="currency"><b>{code}</b></span>
                    <b>{numToFixed(studio_earning)}</b>
                </div>
            </div>
            <BookingDetails row={offer} />
            <h6 className="mb-2 mt-3"><b>Service Fee Studio</b></h6>
            <div className="service-fee mb-2">
                <div className="tran-box px-0">
                    <div>
                        <span className="label">Total Studio Price</span>
                        <div className="d-flex justify-content-between currency-wrap">
                            <span className="currency"></span>
                            <span className="text">{numToFixed(studio_price_withVAT)}
                            </span>
                        </div>
                    </div>
                    <div>
                        <span className="label ps-1"> - Service fee</span>
                        <div className="d-flex justify-content-between currency-wrap">
                            <span className="currency"></span>
                            <span className="text">{numToFixed(inckd_studio_service_price)}
                            </span>
                        </div>
                    </div>
                    <div className="mb-2">
                        <span className="label ps-1"> - VAT ({inckd_vat}%)</span>
                        <div className="d-flex justify-content-between currency-wrap">
                            <span className="currency"></span>
                            <span className="text">{numToFixed(inckd_studio_service_vat_price)}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tran-box" style={{ fontWeight: 'bold', padding: '10px' }}>
                <div>
                <span className="label ps-1">Payout studio</span>
                <div className="d-flex justify-content-between currency-wrap">
                    <span className="currency">{code}</span>
                    <span className="text">{numToFixed(studio_earning)}
                    </span>
                </div>
                </div>
            </div>
        </>
    )
}

export default HistoryDetailsPopup

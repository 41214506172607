import PropTypes from "prop-types";
import React, { FC, ElementType, useMemo } from "react";
import {
  CNavGroup,
  CNavGroupItems,
  CNavItem,
  CNavLink,
  CNavTitle,
} from "@coreui/react";
import { matchPath, useLocation } from "react-router-dom";
import { useSelector } from "../../../store";

export interface CSidebarNavItemGeneratorProps {
  anchor: string;
  as: string | ElementType;
  _component: string;
  items?: any[];
  idx?: string;
}

interface CCreateNavItemProps {
  idx?: string;
  items: any[];
}

export const SidebarContent: FC<CCreateNavItemProps> = ({ items, idx }) => {
  const { isOnlyperiodic } = useSelector(state => state.auth);
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderItem = (item: any, index: number, idx?: string) => {
    const {
      _component,
      as,
      anchor,
      items,
      ...rest
    }: CSidebarNavItemGeneratorProps = item;
    const components: any = {
      CNavGroup,
      CNavGroupItems,
      CNavItem,
      CNavLink,
      CNavTitle,
    };

    const Component = components[_component] || _component;

    const children = items
      ? items.map((item, index) => renderItem(item, index))
      : anchor;
    const match = matchPath(location.pathname, {
      path: item.to,
      exact: true,
      strict: false,
    });

    return (
      <Component
        component={as}
        key={index}
        {...(items && { idx: `${idx}.${index}`, toggler: anchor })}
        {...rest}
        // active={!!match}
      >
        {children}
      </Component>
    );
  };

  const generatedItems = useMemo(() => {
    return items && items.map((item, index) => {
      if(item.anchor === 'Payments' && isOnlyperiodic) {
        return null;
      } else {
        return renderItem(item, index, idx)
      }
    });
  }, [JSON.stringify(items), location.pathname, isOnlyperiodic]);

  return <React.Fragment>{generatedItems}</React.Fragment>;
};
export default SidebarContent;

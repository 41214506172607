import { CBadge } from "@coreui/react";
import { ContentModal } from "../../../components";
import { numToFixed } from "../../../utils/functions";
import HistoryDetailsPopup from "./DetailsPopup";

const HistoryCard = (props: any) => {
    const { data } = props;
    const payoutStatus = data.payout_status;
    let payoutLabel = '';
    (payoutStatus === 'complete') ? payoutLabel = 'Payout executed' : payoutLabel = 'Payout Pending';
    return (
        <ContentModal
            buttonElm={
                <div className="payment-card">
                    <div className="user-img">
                        <img src={data.artist_profile_image} alt="" />
                    </div>
                    <div className="content">
                        <p className="name">{data.artist_firstname} {data.artist_lastname}</p>
                        <p className="project">{data.project}</p>
                        <div className="footer-det">
                            <CBadge
                                textColor={payoutStatus === 'complete' ? 'white' : 'dark'}
                                color={payoutStatus === 'complete' ? 'payout-success' : 'warning'} className="text-capitalize">{payoutLabel}</CBadge>
                            <p className="price">{data.currency} {numToFixed(data.amount - data.studio_service_fee)}</p>
                        </div>
                    </div>
                </div>
            }
            size="lg"
        >
            <HistoryDetailsPopup row={data} />
        </ContentModal>

    )
}

export default HistoryCard;
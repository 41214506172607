import { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./Routes";
import "./scss/style.scss";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ConfirmModal } from "./components";
import { useMediaQuery } from "react-responsive";
import { useDispatch } from "react-redux";
import { toggleSideBar } from "./slices/system";


const App = () => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [confirmModalProps, setConfirmModalProps] = useState({});
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' })
  const handleUserConfirmation = (message: any, callback: any): any => {
    const confirmModaldata = {};
    setConfirmModalProps({
      ...confirmModaldata,
      onSuccess: () => callback(false),
      onClose: () => callback(true),
    });
    setVisible(true);
  };

  useEffect(() => {
    isMobile ? dispatch(toggleSideBar(false)) : dispatch(toggleSideBar(true));
    window.onerror = null;
  },[isMobile]);

  return (
    <>
      <ConfirmModal
        visible={visible}
        setVisible={setVisible}
        cancelButtonText="Don’t save"
        confirmButtonText="Review changes"
        title="Unsaved changed!"
        body={[
          "Unvoluntary changes may affect your artists’ and customers’ experience. Please review, save or reject your changes prior to leaving.",
        ]}
        {...confirmModalProps}
      />
      <Router getUserConfirmation={handleUserConfirmation}>
        <Routes />
      </Router>
      <ToastContainer
        position="bottom-center"
        autoClose={2500}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default App;

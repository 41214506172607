import { createSlice } from "@reduxjs/toolkit";

interface IState {
    pageName: string;
}

const initialState: IState = {
    pageName: 'Dashboard',
};

const page = createSlice({
    name: "page",
    initialState,
    reducers: {
        setPageName(state, action) {
            state.pageName = action.payload
        },
    },
});

export const { setPageName } = page.actions;
export const reducer = page.reducer;

export default page;

import { CButton } from "@coreui/react";
import React from "react";
import { API_END_POINTS } from "../../../utils/constants";
import { EArtistStatus } from "../../../utils/enums";
import {
  decodeArtistData,
  getArtistName,
  getArtistTypeLabel,
  getCardType,
} from "../functions";
import { ActionButton } from "./ActionButton";

const classes: any = {};

export const RowActions = ({ row, fecthList }: any): JSX.Element => {
  const {
    id: profile_uid,
    studio: { id: studio_uid },
    pid,
    first_name,
    last_name,
    artist_type,
    artist_is_periodic,
    artist_image: artistImage,
    studio_share,
    profile_active: profileActive,
  } = row;
  const {
    share,
    new_share: newShare,
    status: shareStatus,
  } = studio_share || {};

  const type = getCardType(row);

  const {
    isActive,
    isOnRequest,
    isInactive,
    isFreelancer,
    isPeriodicalFreelancer,
    isNotPeriodicalFreelancer,
    isLateSplit
  } = decodeArtistData(row);
  
  const STATUS_API_URL =
    share !== newShare
      ? API_END_POINTS.updateShareStatus
      : API_END_POINTS.updateArtistStatus;

  const artistName = getArtistName(row);
  const artistLabel = getArtistTypeLabel(row);

  return (
    <>
      {isOnRequest && (
        <>
          {/* {isNotPeriodicalFreelancer &&
            (newShare === share ? (
              <small className="primary">
                By accepting this request, <span>{artistName}</span> will be
                affiliated to your studio as a <span>{artistLabel}</span>. You
                will earn <span>{`${+share || 0}%`}</span> of his revenue.
              </small>
            ) : (
              <small className="primary">
                By accepting this new share request, you will earn{" "}
                <span>{`${+newShare || 0}%`}</span> of the artist’s revenue on
                future contracts.
              </small>
            ))}
          {isPeriodicalFreelancer && (
            <small className="primary">
              By accepting this request, <span>{artistName}</span> will be
              affiliated to your studio as a <span>{artistLabel}</span>. You
              will get a periodical fee.
            </small>
          )}
          {!isFreelancer && (
            <small className="primary">
              By accepting this request, <span>{artistName}</span> will be
              affiliated to your studio as a <span>{artistLabel}</span>.
            </small>
          )} */}

          <>
            <ActionButton
              label="Accept"
              api={STATUS_API_URL}
              data={{
                studio_uid,
                profile_uid,
                pid,
                status: EArtistStatus.Approved,
                share_status: EArtistStatus.Approved,
              }}
              onSuccess={fecthList}
              needVatConfirm={!isPeriodicalFreelancer}
              successMessage="Artist accepted"
              color="success"
              buttonProps={{
                className: "ms-0 me-3",
              }}
            />
            <ActionButton
              label="Reject"
              buttonProps={{
                className: "ms-0",
              }}
              api={STATUS_API_URL}
              data={{
                studio_uid,
                profile_uid,
                pid,
                status: EArtistStatus.Rejected,
                share_status: EArtistStatus.Rejected,
              }}
              onSuccess={fecthList}
              successMessage="Artist rejected"
              color="danger"
            />
          </>
        </>
      )}
      {isActive && !isLateSplit && (
        <ActionButton
          label="Revoke"
          api={API_END_POINTS.revokeArtist}
          data={{ profile_uid, active_status: "False", pid }}
          onSuccess={fecthList}
          buttonProps={{
            color: "dark",
          }}
          successMessage="Artist revoked"
          confirm={{
            title: "Danger zone!",
            body: [
              "Are you sure you want to revoke this artist? He or she may still have customer appointments scheduled with your studio and you might loose business.",
              "If you revoke this artist, you will not be able to reactivate it on your own. You will need to ask the artist to resend a request.",
            ],
            buttonText: "Revoke artist",
          }}
        />
      )}
    </>
  );
};

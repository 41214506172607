import React from "react";
import { CAlert } from "@coreui/react";
import CIcon from "@coreui/icons-react";
const EmptyNotice = () => (
  <CAlert color="info" className="m-4 d-flex" >
    <CIcon icon="cil-bell" size="lg" className="mx-4" />
    <div>No data available</div>
  </CAlert>
);
export default EmptyNotice;

import axios from "axios";
import { apiURL, AUTH_TOKEN_KEY } from "./config";
import { API_END_POINTS, ROUTING_URLS } from "./constants";

const axiosInstance = axios.create({
  baseURL: apiURL,
});

axiosInstance.interceptors.request.use(async (config: any) => {
  const token = localStorage.getItem(AUTH_TOKEN_KEY);
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});
const urlExcludeARr = [
  API_END_POINTS.login,
  API_END_POINTS.signIn,
  API_END_POINTS.forgotPswd,
];
axiosInstance.interceptors.response.use(
  (response: any) => {
    return response;
  },
  (error: any) => {
    const { response } = error;
    if (!response) return { status: 500, message: "No response from server" };
    if (response.status && response.status === 401) {
      if (!urlExcludeARr.includes(response.config?.url))
        window.location.href = ROUTING_URLS.logout;
      return {
        status: response.status || 500,
        message: response?.data?.message || "No response from server",
      };
    }
    return Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    );
  }
);

export { axiosInstance };

export default axiosInstance;

import { CFormControl } from "@coreui/react";
import React, { useEffect, useState } from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
// import { useForm, Controller } from "react-hook-form";

interface Props {
  [key: string]: any;
}

export const GoogleInputField = React.forwardRef((props: Props, ref: any) => {
  // const { handleSubmit, control } = useForm();
  // const [stuff, setData] = useState([]);
  const [inputValue, setInputValue] = useState('')
  const { onChange, onBlur, setValue: setFieldValue, initialvalue, getValues, ...rest } = props;
  const { city_name } = getValues();
  useEffect(() => {
    setInputValue(city_name);
  }, [city_name]);
  
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });

  const handleInput = (e: any) => {
    // Update the keyword of the input element
    setFieldValue('city_name', e.target.value, { shouldDirty: true})
    setValue(e.target.value);
  };
  const clickRef = useOnclickOutside(() => {
    clearSuggestions();
  });

  const handleSelect =
    ( {description} : any) =>{
      return () => {
        // When user selects a place, we can replace the keyword without request data from API
        // by setting the second parameter to "false"
        setValue(description, false);
        onChange(description);
        clearSuggestions();
  
        // Get latitude and longitude via utility functions
        getGeocode({ address: description })
          .then((results) => getLatLng(results[0]))
          .then((latLng) => {
            const { lat, lng } = latLng;
            setFieldValue('latitude', lat)
            setFieldValue('longitude', lng)
            setFieldValue('city_name', description)
          })
          .catch((error) => {
            console.log("😱 Error: ", error);
          });
      };
    }

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li key={place_id} onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });
  return (
    <div className="place-autocomplete-wrap" ref={clickRef}>
      <CFormControl
        {...rest}
        value={ inputValue }
        onChange={handleInput}
        disabled={!ready}
      />
      {status === "OK" && <ul className="place-suggestion">{renderSuggestions()}</ul>}
    </div>
  );
});
export default GoogleInputField;

import React, { useState, useEffect } from 'react';

import { API_END_POINTS } from '../../../utils/constants';
import axios from '../../../utils/axios';
import { CButton } from '@coreui/react';

export const ConnectAccount: React.FC<any> = (props: any) => {
    const handleCreateAccountLink = async () => {
        const { location: { pathname } } = props;
        localStorage.setItem('CONNECT_ACCOUNT', 'verifying');
        try {
            const response = await axios.post(API_END_POINTS.createCustomAccountLink,{
                refresh_url: `${window.location.origin}${pathname}#failed`,
                return_url: `${window.location.origin}${pathname}#success`
            });
            if (!!response) {
                const { data: { url } } = response.data;
                const anchorEl = document.createElement('a');
                anchorEl.setAttribute('href', url);
                anchorEl.click();
            }
        } catch(err) {
            console.log(err)
        }
    };

    const checkCreateAccountStatus = async () => {
        const isConnectAccountVerifying = localStorage.getItem('CONNECT_ACCOUNT');

        if (!!isConnectAccountVerifying) {
            const { location: { hash } } = props;
            localStorage.removeItem('CONNECT_ACCOUNT');

            if (hash === '#success') {
                try {
                    const res = axios.put(API_END_POINTS.createCustomAccountLink,{
                        status: true
                    });
                    if (!!res) {
                        console.log('success')
                    }
                } catch(err) {
                    console.log(err)
                }
            }
            if (hash === '#failed') {
                console.log('Payout Verification error! Please try again.');
            }
        }
    };

    useEffect(() => {
        checkCreateAccountStatus();
    }, [])

    return (
        <div className="payout">
            <h6>Payout verification</h6>
            <p>
                In order to keep your payments rolling, please keep your Stripe account information up to date.<br /><br />
                Please click <span 
                    style={{
                        color: '#704eaf', 
                        cursor: 'pointer', 
                        textDecoration: 'underline'
                    }} onClick={handleCreateAccountLink}>here</span> to update your KYC informations.
            </p>
        </div>
    );
}


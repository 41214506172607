import { useState } from "react";
import { CFormCheck, CModal, CModalBody, CModalHeader, CModalTitle } from "@coreui/react"

function AlertBox({data, alertOpen, setAlertOpen, checkboxHandler, isChecked }:any) {
    const [checked, setChecked] = useState(isChecked);
    const handleCheckboxChange = (event: Record<string, any>) => {
        const val = event.target.checked
        setChecked(val);
        checkboxHandler(val);
    };
    return (
        <CModal alignment="center" visible={alertOpen} onDismiss={() => {setAlertOpen(false)}}>
            <CModalHeader onDismiss={() => {setAlertOpen(false)}}>
                <CModalTitle>{data.title}</CModalTitle>
            </CModalHeader>
            <CModalBody>
                <p>
                    {data.text}
                </p>
                {
                    checkboxHandler && 
                    <CFormCheck 
                        id="flexCheckDefault" 
                        label="Don't show again" 
                        defaultChecked={checked} onChange={handleCheckboxChange}
                    />
                }
                
            </CModalBody>
      </CModal>
    )
}

export default AlertBox

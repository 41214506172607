// available redirection types
export enum RedirectionTypes {
  STUDIO_LIST = "studio_list",
  STUDIO = "studio",
  BOOKINGS = "studio_bookings",
  HISTORY = "studio_history",
  PAYOUT = "studio_payout",
  STUDIO_SHARE = "studio_share",
  ARTIST_APPROVAL = "studio_artist_approval",
  KYC = "KYC",
}

// available notification types
export enum NotificationTypes {
  OFFER_ACCEPT = "studio_offer_accept",
  OFFER_CANCEL = "studio_offer_cancel",
  PAYOUT = "studio_payout",
  SGARE_CHANGE = "studio_share_change",
  NEW_ARTIST = "studio_new_artist",
}

// available notification seen status
export enum NotificationReadTypes {
  READ = "read",
  UNREAD = "unread",
}

// available notification done status
export enum NotificationDoneTypes {
  DONE = "done",
  UNDONE = "undone",
}

// available task completion status
export enum TaskDoneTypes {
  DONE = "done",
  UNDONE = "undone",
}

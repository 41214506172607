import { Form } from "..";

export const VatFields: React.FC<any> = (props) => {
  const { getInputProps, getValues, watch, defaultVal, reset, isDirty } = props;
  const { checkedA, checkedB, country } = getValues();
  const isCheckedA = watch("checkedA", checkedA);
  const isCheckedB = watch("checkedB", checkedB);
  const onClickCheckbox = () => {
    !defaultVal.checkedA && isDirty && reset(defaultVal)
  }
  return (
    <>
      <div className="mb-4 form-wrap">
        <Form.RadioGroup
          onClick={onClickCheckbox}
          {...getInputProps("checkedA")}
          title="Are you a taxable person for VAT purpose?"
          options={[
            { label: "Yes", value: true },
            { label: "No", value: false },
          ]}
        />
      </div>
      {isCheckedA !== null && (
        <div className="mb-4 pb-3">
          {isCheckedA
            ? `My turnover is above the threshold for VAT liability in ${country?.name}. I charge VAT on my services and undertake to declare and settle these independently.`
            : `My turnover is not above the threshold value for VAT liability in ${country?.name} and I do not levy any VAT on my services.`}
        </div>
      )}
      {checkedA && (
        <>
          <Form.Select
            {...getInputProps("country", "Choose Country")}
            disabled={true}
            gridtype="col"
          ></Form.Select>

          <Form.Input
            gridtype="col"
            {...getInputProps("vat_value")}
            label="VAT affiliation*"
            placeholder="Please enter the VAT number"
          ></Form.Input>
          <div className="disclaimer">
            <div className="form-wrap mb-3">
              <Form.Checkbox
                checked={isCheckedB}
                {...getInputProps("checkedB")}
                label={
                  isCheckedB
                    ? "I have read and accepted the disclaimer"
                    : "Please read and accept"
                }
                switch
              />
            </div>
            <div>
              <h6>Disclaimer</h6>
              <ol>
                <li className="mb-2">
                  I hereby certify that i have provided correct information to
                  inckd. regarding the tax situation of my company. So that
                  inckd. can correctly invoice on behalf of my company for the
                  services my company provides on the inckd. Platform.
                </li>
                <li className="mb-2">
                  I understand and accept that by providing VAT number, inckd.
                  will lead my business as subject to VAT.
                </li>
                <li>
                  I understand and accept that inckd. assumes no responsibility
                  for any misrepresentation by me related to: my VAT number as
                  well as whether or not a small business regulation applies.
                </li>
              </ol>
            </div>
          </div>
        </>
      )}
    </>
  );
};

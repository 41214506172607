import { createSlice } from "@reduxjs/toolkit";
import { checkOnlyPeriodic } from "../modules/artists/functions";
import { AppDispatch } from "../store";
import axios from "../utils/axios";
import { AUTH_TOKEN_KEY } from "../utils/config";
import { API_END_POINTS } from "../utils/constants";
import { Mixpanel } from "../utils/mixpanel";
import { EToastType, showToast } from "../utils/toast";

interface IState {
  isLoading: boolean;
  isLoggedIn: boolean;
  access_token: string | null;
  active_status: any;
  message: string | null;
  user: Record<string, any> | null;
  isOnlyperiodic: boolean;
}

const initialState: IState = {
  isLoading: false,
  isLoggedIn: false,
  access_token: null,
  active_status: null,
  message: null,
  user: null,
  isOnlyperiodic: false
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload || false;
    },
    logout(state) {
      localStorage.removeItem(AUTH_TOKEN_KEY);
      state.access_token = null;
      state.active_status = null;
      state.isLoggedIn = false;
      state.isLoading = false;
      state.message = null;
      state.user = null;
    },
    clearResults() {

    },
    updateAuth(state, action) {
      const { access_token, active_status, user } = action.payload;
      localStorage.setItem(AUTH_TOKEN_KEY, access_token);
      state.user = user;
      state.access_token = access_token;
      state.active_status = active_status;
      state.isLoggedIn = !!access_token;
      state.isLoading = false;
      state.message = !!access_token ? "Logged In" : "Unauthorized";
    },
    updateActiveStatus(state, action) {
      state.active_status = action.payload;
    },
    updateUser(state, action) {
      state.user = {
        ...state.user,
        ...action.payload,
      }
    },
    updateIsOnlyPeriodic(state, action) {
      state.isOnlyperiodic = action.payload || false
    }
  },
});

export const {
  updateAuth,
  updateUser,
  logout,
  updateActiveStatus,
  setLoading,
  updateIsOnlyPeriodic,
  clearResults
} = slice.actions;
export const reducer = slice.reducer;

export const login =
  (data: any, type: string) => async (dispatch: AppDispatch) => {
    dispatch(slice.actions.setLoading(true));
    const postUrl =
      type === "login" ? API_END_POINTS.login : API_END_POINTS.signIn;
    const response: any = await axios
      .post(postUrl, data)
      .catch(function (error) {
        // handle error
        console.log(error);
        dispatch(setLoading(false));
      });
    if (response?.status === 200 || response?.status === 201) {
      const { data, active_status, user } = response.data;
      dispatch(
        updateAuth({
          access_token: data.access_token,
          active_status,
          user,
        })
      );
      const artistList: any = await axios
        .get(API_END_POINTS.getStudioArtists)
        .catch((error) => {
          console.log(error)
        })
      const {data: {data: artists}} = artistList;
      const isOnlyperiodic = checkOnlyPeriodic(artists);
      dispatch(
        updateIsOnlyPeriodic(isOnlyperiodic)
      )
      type === 'signIn' && Mixpanel.track('Studio created Account', {
        'Studio Name': data?.name,
        'Studio Email': data?.email,
      });
    } else {
      showToast("Invalid username or password", EToastType.error);
      dispatch(setLoading(false));
    }
  };

export default slice;

import { CCard, CCardBody } from "@coreui/react";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useAuth, useAxios } from "../../../hooks";
import { API_END_POINTS, ROUTING_URLS } from "../../../utils/constants";
import { EStaticPageTypes } from "../../../utils/enums";
import Loader from "./Loader";

const pageContentKeys = {
  [ROUTING_URLS.termsAndConditions]: EStaticPageTypes.TermsAndConditions,
  [ROUTING_URLS.publicTermsAndConditions]: EStaticPageTypes.TermsAndConditions,
  [ROUTING_URLS.privacyPolicy]: EStaticPageTypes.PrivacyPolicy,
  [ROUTING_URLS.publicPrivacyPolicy]: EStaticPageTypes.PrivacyPolicy,
  [ROUTING_URLS.impressum]: EStaticPageTypes.Impressum,
  [ROUTING_URLS.publicImpressum]: EStaticPageTypes.Impressum,
  [ROUTING_URLS.rules]: EStaticPageTypes.Rules,
  [ROUTING_URLS.publicRules]: EStaticPageTypes.Rules,
};

export const Informations = (props: any) => {
  const { isLoggedIn } = useAuth();
  const [pageContent, setPageContent] = React.useState<string>("");
  let { page } = useParams<any>();
  const history = useHistory<string>();
  const pathname = `/informations/${page}`;

  const [{ data, loading, error }, fetchStaticContents] = useAxios(
    {
      url: `${API_END_POINTS.getStaticContents}`,
      method: "POST",
      data: { language: "en" },
    },
    { manual: true }
  );

  const contentLinkHandler = () => {
    let timeout = setTimeout(() => {
      clearTimeout(timeout);
      const elementList = document.querySelectorAll("#information-wrapper a");
      if (elementList?.length) {
        Array.from(elementList).forEach((element: any) => {
          const href = element.getAttribute("href");
          element.addEventListener("click", (e: any) => {
            e.preventDefault();

            const path = `/${pathname.split("/")[1]}/${e.target.getAttribute(
              "href"
            )}`;
            if (!!pageContentKeys[path]) {
              history.push(
                isLoggedIn ? path : path.replace("/informations/", "/public/")
              );
            }
          });
        });
      }
    }, 300);
  };

  useEffect(() => {
    const resData = data?.data || [];
    const pageData: Record<string, any> = resData.find(
      (content: Record<string, any>) =>
        content.type === pageContentKeys[pathname]
    );
    const { title: pageTitle, content } = pageData || {};
    setPageContent(content);
    contentLinkHandler();
  }, [data, loading, error]);

  useEffect(() => {
    fetchStaticContents();
  }, [page]);

  return (
    <CCard>
      <CCardBody>
        {loading ? (
          <Loader />
        ) : (
          <div id="information-wrapper">
            <div dangerouslySetInnerHTML={{ __html: pageContent }} />
          </div>
        )}
      </CCardBody>
    </CCard>
  );
};
export default Informations;

export enum ECardTypes {
  Request = "request",
  Active = "active",
  Inactive = "inactive",
  Rejected = "rejected",
}

export enum EArtistStatus {
  Approved = "approved",
  Rejected = "rejected",
  Pending = "pending",
  Deactivated = "deactivated",
}

export enum EArtistStatusLabel {
  Pending = "Pending",
  Accepted = "Active",
  Inactive = "Inactive",
  Rejected = "Rejected",
}


export enum EArtistTypes {
  Freelancer = "freelance",
  Employee = "employee",
  Owner = "owner",
}

// available offer status
export enum EOfferWorkingStatus {
  ACCEPTED = "accepted",
  CANCELLED = "cancelled",
  COMPLETED = "completed",
  DRAFT = "draft",
  DELETED = "deleted",
  CHANGE_REQUESTED = "changerequest",
  OVERTIME = "overtime",
  PENDING = "pending",
  POSTPONED = "postponed",
  POSTPONED_PENDING = "postponed_pending",
  REJECTED = "rejected",
  SCHEDULED = "scheduled",
  EMAIL_OFFERS = "email_offers",
}

export enum EStaticPageTypes {
  TermsAndConditions = "terms&conditions",
  PrivacyPolicy = "privacy_policy",
  Impressum = "impressum",
  Rules = "rules",
}

export enum EBookingLabel {
  Scheduled = "scheduled",
  Postponed = "postponed",
  Pending = "postponed_pending",
}

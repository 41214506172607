import React, { useEffect } from "react";
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import { TButton } from "../Table";

export interface IConfirmModal {
  title?: string;
  body?: string[];
  confirmButtonText?: string;
  cancelButtonText?: string;
  onSuccess?: Function;
  onClose?: Function;
  visible: boolean;
  setVisible: Function;
}

export const ConfirmModal = ({
  visible,
  setVisible,
  title,
  body,
  confirmButtonText,
  cancelButtonText,
  onSuccess,
  onClose,
}: IConfirmModal) => {
  return (
    <>
      <CModal visible={visible} onDismiss={() => setVisible(false)}>
        <CModalHeader onDismiss={() => setVisible(false)}>
          <CModalTitle>{title || "Confirm"}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          {(body || []).map((x, i) => (
            <p key={i}>{x}</p>
          ))}
        </CModalBody>
        <CModalFooter>
          <TButton
            color="dark"
            onClick={() => {
              setVisible(false);
              onClose && onClose();
            }}
          >
            {cancelButtonText || "Close"}
          </TButton>
          {confirmButtonText && (
            <TButton
              color="info"
              onClick={() => {
                setVisible(false);
                console.log("onSuccess", onSuccess);

                onSuccess && onSuccess();
              }}
            >
              {confirmButtonText || "Confirm"}
            </TButton>
          )}
        </CModalFooter>
      </CModal>
    </>
  );
};

export default ConfirmModal;

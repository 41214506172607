import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from "@coreui/react";
import { DatePicker, Space } from "antd";
import moment, { Moment } from "moment";
import { useEffect, useMemo, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { updateArtistFilter, updateDate, updateIsMobileWeek } from "../../../slices/booking";
import { useDispatch, useSelector } from "../../../store";
import CalendarMobFilterModal from "./CalendarMobFilterModal";
import { datePickerLabel, getArtistImgFromId } from "./functions";
import MobileWeekTimeMobile from "./MobileWeekTimeMobile";

const ViewChangeBar = (props: any) => {
    const { views, onViewClick, current } = props;
    const onElClick = (i: any, index: any) => {
        onViewClick(i, index)
    }
    return (
        <div className="view-nav">
            {
                views.map((i: string, index: number) => (
                    <div
                        key={index}
                        className={index === current ? 'active' : ''}
                        onClick={() => onElClick(i, index)}>{i}
                    </div>
                ))
            }
        </div>
    )
}

const CustomToolbar = (props: any) => {
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' })
    const { activeArtistFilter, artistsList, isMobileWeek, calDate } = useSelector(state => state.bookings);
    const [visible, setVisible] = useState(false)
    const dispatch = useDispatch();
    const [current, setCurrent] = useState<Number>();
    const [isToday, setIsToday] = useState<boolean>(false);
    const { date, views, view, onView, onNavigate, localizer } = props;
    const artistImage = useMemo(() => {
        return getArtistImgFromId(artistsList, activeArtistFilter)
    }, [activeArtistFilter, artistsList])
    const now = new Date();
    const goToToday = () => {
        date.setMonth(now.getMonth());
        date.setYear(now.getFullYear());
        date.setDate(now.getDate());
        onNavigate("current");
        dispatch(updateDate(now))
    }

    const monthCellRender = (date: Moment) => {
        return (<>{date.format('MMMM')}</>)
    }
    const onViewClick = (i: string, index: number) => {
        setCurrent(index);
        onView(i);
        i !== 'week' && dispatch(updateIsMobileWeek(false))
    }
    useEffect(() => {
        switch (view) {
            case 'day':
                setCurrent(0);
                break;
            case 'week':
                setCurrent(1);
                break;
            default: setCurrent(2)
        }
    }, [view]);

    useEffect(() => {
        const start = localizer.startOf(date, view);
        const end = localizer.endOf(date, view);
        const isInRange = localizer.inRange(now, start, end, view);
        setIsToday(isInRange);
    }, [date])


    const onChange = (date: any) => {
        dispatch(updateDate(date._d))
    }
    const onDateNav = (action: string) => {
        if (!isMobileWeek) {
            onNavigate(action)
        } else {
            const dateIncrement = action === 'PREV' ? 1 : -1;
            const toDate: any = moment(date).subtract(dateIncrement, 'w')
            dispatch(updateDate(toDate._d))
        }
    }
    return (
        <>
            <div className="cal-toolbar">
                {!isMobile &&
                    <div className="view-nav">
                        <div className={isToday ? 'active' : ''} onClick={goToToday}>Today</div>
                    </div>}
                <div className="toolbar-picker">
                    <div className="cal-header-label">
                        <div className="prev" onClick={() => onDateNav('PREV')}>
                            <img src="/images/left.svg" alt="" />
                        </div>
                        <span className="label">{datePickerLabel(view, date, isMobile, isMobileWeek)}</span>
                        <div className="next" onClick={() => onDateNav('NEXT')}>
                            <img src="/images/right.svg" alt="" />
                        </div>
                    </div>
                    <div className="monthpicker-bar" style={{ opacity: '0' }}>
                        <Space direction="vertical">
                            <DatePicker
                                inputReadOnly={true}
                                defaultValue={moment(calDate)}
                                value={moment(calDate)}
                                onChange={onChange}
                                monthCellRender={monthCellRender}
                                dropdownClassName="booking-cal"
                                className='cust-datepicker'
                                picker={isMobileWeek && isMobile ? 'week' : view} />
                        </Space>
                    </div>
                </div>
                {
                    !isMobile ? (
                        <div className="view-nav">
                            <ViewChangeBar views={views} onViewClick={onViewClick} current={current} setCurrent={setCurrent} />
                        </div>
                    ) : (
                        <div className="filter-info-wrap" onClick={() => setVisible(!visible)}>
                            <div className={`${!artistImage ? 'artist-group' : ''} artist-img`}>
                                <img src={!!artistImage ? artistImage : '/images/artist-group.svg'} alt="" />
                            </div>
                            <div className="settings-trigger">
                                <img src="/images/settings.svg" alt="" />
                            </div>
                            {
                                visible && (
                                    <CalendarMobFilterModal visible={visible} setVisible={setVisible}>
                                        <ViewChangeBar views={views} onViewClick={onViewClick} current={current} setCurrent={setCurrent} />
                                    </CalendarMobFilterModal>
                                )
                            }

                        </div>
                    )
                }
            </div>
            {isMobile && isMobileWeek && <MobileWeekTimeMobile />}
        </>
    )
}

export default CustomToolbar;
import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import CIcon from "@coreui/icons-react";
import { Tooltip } from 'antd';
import DatePicker, { utils } from '@hassanmojab/react-modern-calendar-datepicker';
import moment from "moment";
import * as icon from '@coreui/icons';

import FilterChip from './FilterChip';
import { DateRangeSelect, FilterType } from "../_lib/constants";

import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';

interface OfferAnalyticCardProps {
    amount_eur?: string;
    amount_chf?: string;
    amount_gbp?: string;
    isLoading?: boolean;
    count?: number;
    title?: string;
    onDateRangeChange?: Function;
    onTodayClick?: Function;
    onTriggerReset?: Function;
    isGlobalToday?: boolean;
    ref?: any;
}

const OfferAnalyticCard: React.FC<OfferAnalyticCardProps> = forwardRef(({
    amount_chf,
    amount_eur,
    amount_gbp,
    isLoading = false,
    count = 0,
    title = '',
    onDateRangeChange,
    onTodayClick,
    onTriggerReset,
    isGlobalToday = false
}, ref) => {
    const [filterChip, setFilterChip] = useState<string>(FilterType?.total);
    const [dateRange, setDateRange] = useState<Record<string, any>>({
        from: '',
        to: ''
    });
    const [selectedDayRange, setSelectedDayRange] = useState<DateRangeSelect>({
        from: null,
        to: null
    });
    const [isGlobalFilter, setIsGlobalFilter] = useState<boolean>(isGlobalToday);

    const handleTodayClick = (type: string): void => {
        setFilterChip(type);
        setSelectedDayRange({
            from: null,
            to: null
        });
        onTodayClick?.();
    };

    const handleDateFilter = (dateRange: DateRangeSelect): void => {
        setSelectedDayRange(dateRange);
        const { from, to } = dateRange;
        const fromDate = from ? `${from?.day}/${from?.month}/${from?.year}` : null;
        const toDate = to ? `${to?.day}/${to?.month}/${to?.year}` : null;
        if (fromDate && toDate) {
            setDateRange({
                from: fromDate,
                to: toDate
            });
            setFilterChip(FilterType?.dateRange);
            onDateRangeChange?.({
                from: moment.utc(fromDate, 'D/M/YYYY').toISOString(),
                to: moment.utc(toDate, 'D/M/YYYY').toISOString()
            });
            setIsGlobalFilter(false);
        }
    };

    const triggerReset = (): void => {
        setFilterChip(FilterType?.total);
        setSelectedDayRange({
            from: null,
            to: null
        });
        if (onTriggerReset) onTriggerReset()
    };

    useImperativeHandle(ref, () => ({
        resetFilter: () => {
            setFilterChip(FilterType?.total);
            setSelectedDayRange({
                from: null,
                to: null
            });
            // if (onTriggerReset) onTriggerReset();
        }
    }));

    useEffect(() => {
        if (isGlobalToday) {
            setFilterChip(FilterType?.today);
        } else {
            setFilterChip(FilterType?.total);
        }
        setIsGlobalFilter(isGlobalToday);
    }, [isGlobalToday]);

    const renderCustomInput = ({ ref }: any) => (
        <>
            <Tooltip placement='top' title="">
                <input
                    readOnly
                    ref={ref}
                    className={`datepicker-input ${isGlobalToday ? 'gloabl-inp' : ''}`}
                    title="Date Range"
                    disabled={isGlobalToday}
                />
            </Tooltip>

        </>
    )

    return (
        <div className='analytics-card mb-lg-0 mb-md-2 mb-2'>
            <div className={`loader-component ${isLoading ? 'loading' : ''}`}>
                <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
            <div className={`card-inner ${isLoading ? 'loading' : ''}`}>
                <div className='card-head'>
                    <FilterChip
                        filterKey={filterChip !== FilterType?.dateRange ? filterChip : `${dateRange?.from} - ${dateRange.to}`}
                        isGlobal={isGlobalToday}
                    />
                    <div className='card-action-row'>
                        <button
                            className={`filter-button ${filterChip === FilterType.today && !isGlobalToday ? 'active-btn' : ''} ${isGlobalToday ? 'global-active' : ''}`}
                            onClick={handleTodayClick.bind(this, FilterType.today)}
                            disabled={isGlobalToday}
                            title="Today"
                        >
                            <CIcon icon='cilCalendarCheck' />
                        </button>
                        <div>
                        <button
                            className={`filter-button ${filterChip === FilterType.dateRange && !isGlobalToday ? 'active-btn' : ''} ${isGlobalToday ? 'global-active' : ''}`}
                            title="Date Range"
                            disabled={isGlobalToday}
                        >
                            <CIcon icon='cilCalendar' />
                        </button>
                        <DatePicker
                            value={selectedDayRange}
                            onChange={handleDateFilter}
                            shouldHighlightWeekends
                            maximumDate={utils('en').getToday()}
                            renderInput={renderCustomInput}
                        />
                        </div>
                        {filterChip !== FilterType?.total && !isGlobalFilter && (
                            <button
                                className='filter-button'
                                onClick={triggerReset}
                                title="Reset"
                            >
                                <CIcon icon={icon?.cilReload} />
                            </button>
                        )}
                    </div>
                </div>
                <div className='card-content'>
                    <div className='count-content'>
                        <h4>{count}</h4>
                        <h6>{title}</h6>
                    </div>
                    <div className='right-component'>
                        <ul className="currency-li">
                            <li>EUR {amount_eur}</li>
                            <li>CHF {amount_chf}</li>
                            <li>GBP {amount_gbp}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
});

export default OfferAnalyticCard;
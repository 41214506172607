import { useState } from "react";
import { FormBody, FormHead, FormHeader, FormSubHead } from "../styles";
import ResetPasswordForm from "./Form";

function ResetPassword() {
  const [frgtUsrSuccess, setFrgtUsrSuccess] = useState(false);
  const [frgtUsrError, setFrgtUsrError] = useState("");
  const [userEmail, setUserEmail] = useState("");
  return (
    <>
      <FormHeader>
        <img src="/images/Logo.svg" alt="" />
        <FormHead>Got you covered. Reset password.</FormHead>
        <FormSubHead className="text-wrap-md">
          Secure your account with a new password first and we’ll prompt you to
          your login again.
        </FormSubHead>
      </FormHeader>
      <FormBody>
        <ResetPasswordForm
          setFrgtUsrSuccess={setFrgtUsrSuccess}
          frgtUsrError={frgtUsrError}
          setFrgtUsrError={setFrgtUsrError}
          setUserEmail={setUserEmail}
        ></ResetPasswordForm>
      </FormBody>
    </>
  );
}

export default ResetPassword;

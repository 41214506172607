import { CCard, CCardBody } from "@coreui/react";
import Skeleton from "react-loading-skeleton";
const Loader = (props: any) => {
  const {className} = props;
  return(
    <CCard className={className}>
      <CCardBody>
        <h1>
          <Skeleton />
        </h1>
        <Skeleton count={5} />
      </CCardBody>
    </CCard>
  )
};
export default Loader;

import { CButton, CModal, CModalBody, CModalFooter } from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { updateArtistFilter, updateIsMobileWeek, updtaeCurrentCalendarView } from "../../../slices/booking";
import { useDispatch, useSelector } from "../../../store";
import ArtistDropDown from "./ArtistDropdown";

const CalendarMobFilterModal = (props: any) => {
    const { visible, setVisible, children } = props;
    const dispatch = useDispatch();
    const { activeArtistFilter, currentCalendarView, isMobileWeek, artistsList } = useSelector(state => state.bookings);
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' })
    const [currentView, setCurrentView] = useState<string>();
    const [currentArtistSel, setCurrentArtistSel] = useState<string>();
    useEffect(() => {
        !currentView && setCurrentView(currentCalendarView);
        !currentArtistSel && setCurrentArtistSel(activeArtistFilter);
        return () =>{
            setCurrentView('')
        }
    }, []);

    useEffect(() => {
        visible && isMobileWeek && dispatch(updtaeCurrentCalendarView('week'));
    }, [visible])
    const onCalFilterSubmit = () => {
        dispatch(updateArtistFilter(activeArtistFilter));
        setCurrentView(currentCalendarView)
        setCurrentArtistSel(activeArtistFilter)
        if (currentCalendarView === 'week') {
            dispatch(updtaeCurrentCalendarView('day'));
            dispatch(updateIsMobileWeek(true))
        } else {
            dispatch(updtaeCurrentCalendarView(currentCalendarView))
            dispatch(updateIsMobileWeek(false))
        }
        setVisible(false)
    }
    const onCalCancel = () => {
        setVisible(false);
        dispatch(updateArtistFilter(currentArtistSel));
        if (currentView === 'week') {
            dispatch(updtaeCurrentCalendarView('day'))
        } else {
            dispatch(updtaeCurrentCalendarView(currentView))
        }

    }
    return (
        <CModal fullscreen visible={visible} onDismiss={() => setVisible(false)} className="cal-filter-modal">
            <div className="modal-close" onClick={onCalCancel} >
                <img src="/images/close-solid.svg" alt="" />
            </div>
            <CModalBody>
                <h2>Calendar view</h2>
                {React.Children.map(children, child => {
                    return React.cloneElement(child, { isInModal: true })
                })}
                {
                    artistsList.length ? (
                        <>
                            <h2>Artist</h2>
                            <ArtistDropDown />
                        </>
                    ) : ''
                }
            </CModalBody>
            <CModalFooter>
                <CButton shape="rounded-pill" color="light" variant="outline" onClick={onCalCancel}>
                    Cancel
                </CButton>
                <CButton onClick={onCalFilterSubmit} shape="rounded-pill" color="dark">Save</CButton>
            </CModalFooter>
        </CModal>
    )
}

export default CalendarMobFilterModal;
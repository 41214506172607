export const onProduction = true;

export const AUTH_TOKEN_KEY = "INCKD_STUDIO_WA";

export enum AppBuildTypes {
  DEVELOPMENT = "development",
  PRODUCTION = "production",
  STAGING = "staging",
}

export const AppBaseConfig: Record<string, Record<string, string>> = {
  [AppBuildTypes.DEVELOPMENT]: {
    api_url: "http://203.129.251.10:8000/",
    webAppUrl: "http://demo.mypits.org:15069/",
  },
  [AppBuildTypes.PRODUCTION]: {
    api_url: "https://apiadmin.inckd.com/",
    webAppUrl: "https://studio.inckd.com/",
  },
  [AppBuildTypes.STAGING]: {
    api_url: "https://admin.inckd.com/",
    webAppUrl: "http://demo.mypits.org:15070/",
  },
};
export const apiURL = AppBaseConfig[AppBuildTypes.PRODUCTION].api_url;

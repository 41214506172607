import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";

import { useHttp } from "../../../hooks";
import { API_END_POINTS } from "../../../utils/constants";

import { DateRangeSelect, FilterType, OfferAnalyticData, OfferAnalyticRef, OfferType } from "../_lib/constants";
import ContentHeader from "./ContentHeader";
import OfferAnalyticCard from "./OfferAnalyticCard";
import moment from "moment";

const OfferStatusInitial: string[] = [
    OfferType?.pending,
    OfferType?.scheduled,
    OfferType?.completed,
    OfferType?.draft,
    OfferType?.rejected
]

interface OfferAnalyticsProps {
    title?: string;
    ref?: any
}

const OfferAnalytics: React.FC<OfferAnalyticsProps> = forwardRef(({ title = '' }, ref) => {

    const [filter, setFilter] = useState<string | null>(null);
    const [offerStatus, setOfferStatus] = useState<Array<string>>(OfferStatusInitial);
    const [dateFilter, setDateFilter] = useState<any>({
        from: null,
        to: null
    });
    const [offerAnalyticState, setOfferAnalyticsState] = useState<OfferAnalyticData | null>(null);
    const [offerFilterToday, setOfferFilterToday] = useState<boolean>(false);
    const [globalFilter, setGlobalFilter] = useState<boolean>(false);

    const [{ data: res, loading }, fetchOfferAccountDetails] = useHttp(
        {
            url: `${API_END_POINTS.getAnalyticsOfferCount}`,
            method: "POST",
        },
        { manual: true }
    );

    const offerAnalyticPendingRef = useRef<OfferAnalyticRef>(null);
    const offerAnalyticScheduledRef = useRef<OfferAnalyticRef>(null);
    const offerAnalyticCompletedRef = useRef<OfferAnalyticRef>(null);
    const offerAnalyticDraftRef = useRef<OfferAnalyticRef>(null);
    const offerAnalyticRejectedRef = useRef<OfferAnalyticRef>(null);

    const handleTodayClick = (offerType: string): void => {
        setFilter(FilterType?.today);
        setDateFilter({
            from: null,
            to: null
        });
        setOfferStatus([offerType]);
    }

    const handleDateRange = (dateRange: any, offerType: string): void => {
        setDateFilter({ ...dateRange });
        setFilter(null);
        setOfferStatus([offerType]);
    };

    const handleReset = (offerType: string): void => {
        setFilter(null);
        setDateFilter({
            from: null,
            to: null
        });
        setOfferStatus([offerType]);
    }

    const handleTodayFilterAll = (): void => {
        setFilter(FilterType?.today);
        setOfferStatus(OfferStatusInitial);
        setOfferFilterToday(true);
    };

    const handleGlobalFilterReset = (): void => {
        if (!loading) {
            setFilter('');
            setOfferFilterToday(false);
            setOfferStatus(OfferStatusInitial);
            setDateFilter({
                from: null,
                to: null
            });
            offerAnalyticPendingRef?.current?.resetFilter();
            offerAnalyticCompletedRef?.current?.resetFilter();
            offerAnalyticDraftRef?.current?.resetFilter();
            offerAnalyticRejectedRef?.current?.resetFilter();
            offerAnalyticScheduledRef?.current?.resetFilter();
        }
    };

    // Client Updates Global Filter for keep the flow use forwardRef
    useImperativeHandle(ref, () => ({
        toggleOfferTodayFilter: () => {
            setFilter(FilterType?.today);
            setOfferStatus(OfferStatusInitial);
            setOfferFilterToday(true);
        },
        toggleOfferDateRangeFilter: (from: string, to: string) => {
            setDateFilter({
                from: moment.utc(from, 'D/M/YYYY').toISOString(),
                to: moment.utc(to, 'D/M/YYYY').toISOString(),
            });
            setFilter(null);
            setOfferStatus(OfferStatusInitial);
            setOfferFilterToday(true);
        },
        toggleOfferReset: handleGlobalFilterReset,

    }));

    useEffect(() => {
        fetchOfferAccountDetails({
            data: {
                status: offerStatus,
                filter: filter,
                start_date: dateFilter?.from,
                end_date: dateFilter?.to
            }
        });
    }, [offerStatus, filter, dateFilter]);

    useEffect(() => {
        const offerType = offerStatus[0];
        if (res && offerStatus.length === 5) {
            setOfferAnalyticsState({ ...res?.data });
        } else if (res) {
            setOfferAnalyticsState((prevState: any) => {
                return ({
                    ...prevState,
                    [`${offerType}_count`]: res?.data[`${offerType}_count`],
                    [`${offerType}_offer_amount_chf`]: res?.data[`${offerType}_offer_amount_chf`],
                    [`${offerType}_offer_amount_eur`]: res?.data[`${offerType}_offer_amount_eur`],
                    [`${offerType}_offer_amount_gbp`]: res?.data[`${offerType}_offer_amount_gbp`],
                });
            })
        }
    }, [res, offerStatus]);

    return (
        <>
            <ContentHeader
                title='Offer analytics data'
                description={
                    <p>The dashboard displays key metrics of customers, artist and offers.
                        <br />
                        At the top of each section, you will find filters that allow you to set today or specific date ranges.
                    </p>
                }
            />
            <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                    <OfferAnalyticCard
                        title="Offer Pending"
                        count={offerAnalyticState?.pending_count}
                        onDateRangeChange={(dateRange: any) => handleDateRange(dateRange, OfferType?.pending)}
                        onTodayClick={handleTodayClick.bind(this, OfferType?.pending)}
                        amount_chf={offerAnalyticState?.pending_offer_amount_chf}
                        amount_eur={offerAnalyticState?.pending_offer_amount_eur}
                        amount_gbp={offerAnalyticState?.pending_offer_amount_gbp}
                        onTriggerReset={handleReset.bind(this, OfferType?.pending)}
                        isLoading={offerStatus.includes(OfferType?.pending) && loading}
                        isGlobalToday={offerFilterToday}
                        ref={offerAnalyticPendingRef}
                    />
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                    <OfferAnalyticCard
                        title="Offer Scheduled"
                        count={offerAnalyticState?.scheduled_count}
                        onDateRangeChange={(dateRange: any) => handleDateRange(dateRange, OfferType?.scheduled)}
                        onTodayClick={handleTodayClick.bind(this, OfferType?.scheduled)}
                        amount_chf={offerAnalyticState?.scheduled_offer_amount_chf}
                        amount_eur={offerAnalyticState?.scheduled_offer_amount_eur}
                        amount_gbp={offerAnalyticState?.scheduled_offer_amount_gbp}
                        onTriggerReset={handleReset.bind(this, OfferType?.scheduled)}
                        isLoading={offerStatus.includes(OfferType?.scheduled) && loading}
                        isGlobalToday={offerFilterToday}
                        ref={offerAnalyticScheduledRef}
                    />
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                    <OfferAnalyticCard
                        title="Offer Completed"
                        count={offerAnalyticState?.completed_count}
                        onDateRangeChange={(dateRange: any) => handleDateRange(dateRange, OfferType?.completed)}
                        onTodayClick={handleTodayClick.bind(this, OfferType?.completed)}
                        amount_chf={offerAnalyticState?.completed_offer_amount_chf}
                        amount_eur={offerAnalyticState?.completed_offer_amount_eur}
                        amount_gbp={offerAnalyticState?.completed_offer_amount_gbp}
                        onTriggerReset={handleReset.bind(this, OfferType?.completed)}
                        isLoading={offerStatus.includes(OfferType?.completed) && loading}
                        isGlobalToday={offerFilterToday}
                        ref={offerAnalyticCompletedRef}
                    />
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12 col-12 mt-lg-3 mt-0">
                    <OfferAnalyticCard
                        title="Offer Draft"
                        count={offerAnalyticState?.draft_count}
                        onDateRangeChange={(dateRange: any) => handleDateRange(dateRange, OfferType?.draft)}
                        onTodayClick={handleTodayClick.bind(this, OfferType?.draft)}
                        amount_chf={offerAnalyticState?.draft_offer_amount_chf}
                        amount_eur={offerAnalyticState?.draft_offer_amount_eur}
                        amount_gbp={offerAnalyticState?.draft_offer_amount_gbp}
                        onTriggerReset={handleReset.bind(this, OfferType?.draft)}
                        isLoading={offerStatus.includes(OfferType?.draft) && loading}
                        isGlobalToday={offerFilterToday}
                        ref={offerAnalyticDraftRef}
                    />
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12 col-12 mt-lg-3 mt-0">
                    <OfferAnalyticCard
                        title="Offer Rejected"
                        count={offerAnalyticState?.rejected_count}
                        onDateRangeChange={(dateRange: any) => handleDateRange(dateRange, OfferType?.rejected)}
                        onTodayClick={handleTodayClick.bind(this, OfferType?.rejected)}
                        amount_chf={offerAnalyticState?.rejected_offer_amount_chf}
                        amount_eur={offerAnalyticState?.rejected_offer_amount_eur}
                        amount_gbp={offerAnalyticState?.rejected_offer_amount_gbp}
                        onTriggerReset={handleReset.bind(this, OfferType?.rejected)}
                        isLoading={offerStatus.includes(OfferType?.rejected) && loading}
                        isGlobalToday={offerFilterToday}
                        ref={offerAnalyticRejectedRef}
                    />
                </div>
            </div>
        </>
    )
});

export default OfferAnalytics;
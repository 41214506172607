import axios from "axios";
import { makeUseAxios } from "axios-hooks";
import { axiosInstance } from "../utils/axios";

const useAxios = makeUseAxios({
  axios: axiosInstance,
  defaultOptions: { manual: false },
});

export default useAxios;

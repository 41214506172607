import React from "react";
import { Controller } from "react-hook-form";

import { CFormLabel, CFormControl, CFormText } from "@coreui/react";
import GoogleInputField from "./GoogleInputField";

interface Props {
  [key: string]: any;
}

export function LocationSearchInput(props: Props) {
  const {
    name,
    control,
    defaultValue,
    label,
    error,
    helperText,
    ...rest
  } = props;
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field }) => {
        const { value, ...fieldRest } = field;
        return (
          <div className="mb-4 pb-3 form-wrap">
            <div className="row">
              <div className="col-lg-4">
                {label && <CFormLabel>{label}</CFormLabel>}
              </div>
              <div className="col-lg-8">
                <GoogleInputField {...field} {...rest} />
                {helperText && <CFormText>{helperText}</CFormText>}
              </div>
            </div>
          </div>
        );
      }}
    />
  );
}

import { EArtistTypes } from "../../../utils/enums";
import { numToFixed } from "../../../utils/functions";

function BookingDetails({ row }: any) {
  if (!row) return null;
  const { payment } = row;
  const { studio: { relation, periodic }, price } = row;
  const isFreelancer = relation === EArtistTypes.Freelancer;
  const {
    artist_price: artistPrice,
    artist_price_withVAT: artistPriceIncluVAT,
    artist_vat_price: artistPriceVAT,
    inckd_service_price: inckdServiceFee,
    inckd_service_price_withVAT: inckdServiceFeeIncluVAT,
    inckd_studio_service_vat_price: inckdServiceFeeVAT,
    inckd_vat: inckdVAT,
    studio_price: studioPrice,
    studio_price_withVAT: studioPriceIncluVAT,
    studio_vat_price: studioPriceVAT,
    artist_vat: artistVAT,
    studio_vat: studioVAT,
    total_payment: totalPayment,
    voucher_applied_total_payment: voucherAppliedTotal,
    currency
  } = payment;

  return (
    <>
      <section>
        <h6 className="mb-3"><b>Price Calculation</b></h6>
        <div className="">
          <div className=" px-0">
            {
              (isFreelancer && !periodic) &&
              <div className="price-calculation tran-box mb-3">
                <div>
                  <span className="label">Artist price</span>
                  <div className="d-flex justify-content-between currency-wrap">
                    <span className="currency"></span>
                    <span className="text">{numToFixed(artistPrice)}</span>
                  </div>
                </div>
                <div>
                  <span className="label">VAT {artistVAT || 0}%</span>
                  <div className="d-flex justify-content-between currency-wrap">
                    <span className="currency"></span>
                    <span className="text">{numToFixed(artistPriceVAT)}</span>
                  </div>
                </div>
                <div>
                  <span className="divider my-0 flex-grow-1"></span>
                </div>
                <div className="">
                  <span className="label"><b>Total Artist price</b></span>
                  <div className="d-flex justify-content-between currency-wrap">
                    <span className="currency"><b>{currency}</b></span>
                    <span className="text">
                      <b> {numToFixed(artistPriceIncluVAT)}</b>
                    </span>
                  </div>
                </div>
              </div>
            }
            <div className="price-calculation tran-box mb-3">
              <div>
                <span className="label">{(isFreelancer && !periodic) ? 'Studio price' : 'Tattoo Price'}</span>
                <div className="d-flex justify-content-between currency-wrap">
                  <span className="currency"></span>
                  <span className="text">{numToFixed(studioPrice)}</span>
                </div>
              </div>
              <div>
                <span className="label">VAT {studioVAT || 0}%</span>
                <div className="d-flex justify-content-between currency-wrap">
                  <span className="currency"></span>
                  <span className="text">{numToFixed(studioPriceVAT)}</span>
                </div>
              </div>
              <div>
                <span className="divider my-0 flex-grow-1"></span>
              </div>
              {
                (!isFreelancer || periodic) &&
                <div>
                  <span className="label"><b>Total Price</b></span>
                  <div className="d-flex justify-content-between currency-wrap">
                    <span className="currency"><b>{currency}</b></span>
                    <span><b>
                      {(isFreelancer && !periodic) ? numToFixed(price) : numToFixed(studioPriceIncluVAT)}
                    </b>
                    </span>
                  </div>
                </div>
              }
              {
                (isFreelancer && !periodic) &&
                <div className="">
                  <span className="label"><b>Total studio price</b></span>
                  <div className="d-flex justify-content-between currency-wrap">
                    <span className="currency"><b>{currency}</b></span>
                    <span className="text">
                      <b> {numToFixed(studioPriceIncluVAT)}</b>
                    </span>
                  </div>
                </div>
              }
            </div>
            {
              (isFreelancer && !periodic) &&
              <div className="tran-box py-0" style={{padding: '10px'}}>
                <div>
                  <span className="label"><b>Total Price</b></span>
                  <div className="d-flex justify-content-between currency-wrap">
                    <span className="currency"><b>{currency}</b></span>
                    <span><b>
                      {(isFreelancer && !periodic) ? numToFixed(price) : numToFixed(studioPriceIncluVAT)}
                    </b>
                    </span>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </section>
    </>
  );
}

export { BookingDetails };
export default BookingDetails;

import { useEffect, useState } from "react";
import { CAlert, CButton, CCard, CCardBody, CCol, CModal, CSpinner } from '@coreui/react';
import _ from 'lodash';
import moment from "moment";
import { useAxios } from '../../../hooks';
import { API_END_POINTS } from "../../../utils/constants";
import RecieptsDetailsModal from './RecieptsDetailsModal';
import RecieptsList from './RecieptsList';
import { useParams } from "react-router";
import { groupByMonth } from "../../../utils/functions";
import { DatePicker, Space } from "antd";
import { useMediaQuery } from "react-responsive";
import { Table } from "../../../components";
import { ReactComponent as ChevronIcon } from './../../../icons/down-arrow.svg'
import HistoryCard from "../History/HistoryCard";
import CIcon from "@coreui/icons-react";
import RecieptCard from "./RecieptCard";

export enum EReceiptTypes {
  Payout = 'payout',
  Invoice = 'invoice'
};

export const RECEIPT_CONFIG: Record<string, any> = {
  [EReceiptTypes.Payout]: {
    cancelledSign: '+'
  },
  [EReceiptTypes.Invoice]: {
    cancelledSign: '-'
  }
};

function Reciepts() {
  let { type: activeReceipt } = useParams<any>();
  const [payoutId, setPayoutId] = useState('');
  const [detailOpen, setDetailOpen] = useState(false);
  const [receiptData, setReceiptData] = useState<Record<string, any>>({});
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' })
  const [month, setMonth] = useState('');

  const [{ data: res, loading, error }, fetchPayoutsList] = useAxios(
    API_END_POINTS.getStudioPayouts,
    { manual: true }
  );

  useEffect(() => {
    fetchPayoutsList()
  }, [])

  const openDetail: any = (id: string | number) => {
    setPayoutId(`${id}`);
    setDetailOpen(true);
  };

  useEffect(() => {
    if (!!res) {
      const { data } = res;
      setReceiptData(groupByMonth(data) || {})
    }
    !!error && console.log(error)
  }, [res, error])

  useEffect(() => {
    setMonth(Object.keys(receiptData)[0]);
  }, [receiptData])

  const onChange = (date: any, dateString: any) => {
    setMonth(date.format('MMMM YYYY'));
  }

  const monthCellRender = (date: any, locale: any) => {
    return (<>{date.format('MMMM')}</>)
  }


  return (
    <>
      {
        !!loading ? <CSpinner /> :
          <>
            <CModal alignment="center" scrollable visible={detailOpen} onDismiss={() => setDetailOpen(false)} size="lg">
              <RecieptsDetailsModal detailOpen={detailOpen} setDetailOpen={setDetailOpen} id={payoutId} type={activeReceipt}></RecieptsDetailsModal>
            </CModal>
            {
              Object.keys(receiptData).length ?
                <>
                  <div className="monthpicker-bar">
                    <Space direction="vertical">
                      <DatePicker
                        placeholder="Choose Month"
                        onChange={onChange}
                        monthCellRender={monthCellRender}
                        inputReadOnly={true}
                        allowClear={false}
                        suffixIcon={<ChevronIcon />}
                        className='cust-datepicker'
                        picker="month" />
                    </Space>
                  </div>
                  {
                    !isMobile ? (
                      <RecieptsList
                        data={receiptData[month]}
                        type={activeReceipt}
                        navigate={openDetail}
                        loading={loading}
                        error={error}
                      />
                    ) : (
                      <div className="reciept-card-wrap">
                        {
                          !!receiptData[month] ?
                            receiptData[month]?.map((item: any) => (
                              <RecieptCard
                                navigate={openDetail}
                                data={item}
                                type={activeReceipt}
                              />
                            ))
                            : (
                              <CAlert color="info" className="my-4 d-flex" >
                                <CIcon icon="cil-bell" size="lg" className="mx-4" />
                                <div>No data available</div>
                              </CAlert>
                            )
                        }
                      </div>
                    )
                  }
                </>
                :
                <p>No Payouts/Invoices!</p>
            }
          </>
      }
    </>
  )
}

export default Reciepts

import { CAvatar, CBadge, CButton } from "@coreui/react";
import { DatePicker, Select, Space } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Column } from "react-table";
import { ContentModal, Table, ViewNavBar } from "../../../components";
import AddToCalendar from "../../../components/AddToCalendar";
import { TButton } from "../../../components/Table";
import { useAxios } from "../../../hooks";
import { API_END_POINTS } from "../../../utils/constants";
import { formateDate, numToFixed } from "../../../utils/functions";
import { getArtistName } from "../../artists/functions";
import { BookingDetailsPopup } from "./DetailsPopup";
import { getArtists, getStatusColor } from "./functions";
import { ReactComponent as ChevronIcon } from './../../../icons/down-arrow.svg'
import BookingCalendar from "./BookingCalendar";
import { daata } from "./data";
import { useDispatch, useSelector } from "../../../store";
import { updateActiveMonthFilter, updateArtistFilter, updateArtistsList, updateBookingData, updateFilteredMonthData } from "../../../slices/booking";
import ArtistDropDown from "./ArtistDropdown";

function Upcoming() {
  const dispatch = useDispatch();
  const { bookingData, 
    isArtistFiltered, 
    artistFilter, 
    activeArtistFilter, 
    artistsList,
    currentCalendarView } = useSelector((state) => state.bookings);
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' })
  const [view, setView] = useState('calendar');
  const columns = React.useMemo<Column<any>[]>(
    () => [
      {
        Header: "Artist",
        sortType: (rowA: any, rowB: any, id: any, desc: any) => {
          const refValA = getArtistName(rowA.original.artist);
          const refValB = getArtistName(rowB.original.artist);
          if (refValA > refValB) return -1;
          if (refValB > refValA) return 1;
          return 0;
        },
        accessor: (row) => {
          const displayName = getArtistName(row.artist);
          return (
            <div className="d-flex align-items-center">
              <CAvatar size={"sm"} src={row.artist.image_medium} className="me-2 artist-avatar hide-xs" />
              {displayName}
            </div>
          );
        },
      },
      {
        Header: "Customer",
        className: 'hide-xs',
        accessor: (row) => (
          <>{row.customer?.first_name} {row.customer?.last_name}</>
        ),
      },
      {
        Header: "Project",
        className: 'hide-xs',
        accessor: "project_name",
      },
      {
        Header: "Price",
        className: 'hide-xs',
        accessor: (row) => `${row.currency.code} ${numToFixed(row.price)}`,
      },
      {
        Header: "Status",
        className: 'hide-xs',
        accessor: (row) => {
          let statusLabel = row.status;
          const labelColorClass = getStatusColor(statusLabel);
          if (statusLabel === 'postponed_pending') statusLabel = 'new date pending';
          return (
            <CBadge style={{ minWidth: '112px' }} className={`text-capitalize rel-label ${labelColorClass} border-0 text-nowrap`}>{statusLabel}</CBadge>
          )
        }
      },
      {
        Header: "Date",
        accessor: (row) => {
          const status = row.status;
          if (status !== 'postponed') return formateDate(row.offer_date)
          return '-'
        },
      },
      {
        Header: "Status",
        className: 'xs-action-none show-xs-table-cell text-end upcoming-status',
        id: 'mobile-status',
        accessor: (row) => {
          const statusLabel = row.status;
          const labelColorClass = getStatusColor(statusLabel)
          return (
            <div
              style={{ height: "15px", width: '15px', margin: 'auto' }}
              className={`${labelColorClass} rounded-circle`}></div>
          )
        }
      },
      {
        Header: "",
        className: 'xs-action-none show-xs-table-cell  text-end',
        id: 'mobile-detail',
        accessor: (row) => {
          return (
            <ContentModal
              buttonElm={
                <CButton color="primary" variant="outline">Details</CButton>
                // <CBadge color="primary">Details</CBadge>
              }
              size="lg"
            >
              <BookingDetailsPopup data={row} />
            </ContentModal>
          )
        }
      },
      {
        Header: "",
        accessor: "id",
        className: 'hide-xs actions',
        Cell: ({ row }: any) => (
          <div className="upcoming-buttons-column">
            {!row.original.studio.periodic && (
              <ContentModal
                buttonElm={<TButton size="sm">Details</TButton>}
                size="lg"
              >
                <BookingDetailsPopup data={row.original} />
              </ContentModal>
            )}
            <AddToCalendar
              event={{
                title: row.original.project_name,
                description: "inckd. appointment",
                location: `${row.original.studio.name}, ${row.original.studio.city_name}`,
                startTime: moment(row.original.offer_date).format(),
                endTime: moment(row.original.offer_date)
                  .add(row.original.duration, "minutes")
                  .format(),
              }}
            />
          </div>
        ),
      },
    ],
    []
  );
  const [{ data, error, loading }, fetchList] = useAxios(
    API_END_POINTS.getStudioUpcomingBookings,
    { manual: true }
  );

  useEffect(() => {
    fetchList();
  }, [])

  useEffect(() => {
    if (data) {
      // dispatch(updateBookingData(daata))
      // dispatch(updateArtistsList(getArtists(daata)))
      dispatch(updateBookingData(data.data))
      dispatch(updateArtistsList(getArtists(data.data)))

    }
  }, [data])

  useEffect(() => {
    setView('calendar')
  }, [isMobile])

  const onChange = (date: any) => {
    const selectedDate = date.format('YYYY-MM');
    dispatch(updateFilteredMonthData(selectedDate))
  }

  useEffect(() => {
    if(view === 'calendar') {
      dispatch(updateActiveMonthFilter(''))
    } else {
      const now = new Date();
      dispatch(updateActiveMonthFilter(moment(now).format('YYYY-MM')))
    }
    dispatch(updateArtistFilter(activeArtistFilter))
  }, [view])

  const monthCellRender = (date: any, locale: any) => {
    return (<>{date.format('MMMM')}</>)
  }

  const tableData = isArtistFiltered ? artistFilter : bookingData;

  return (
    <>
      {!isMobile && (
        <ViewNavBar
          labelOne="calendar"
          labelTwo="list"
          view={view}
          setView={setView}
        >
          {artistsList.length ? (<ArtistDropDown view={view} />) : <span></span>}
          {view !== 'calendar' && (
            <div className="monthpicker-bar">
              <Space direction="vertical">
                <DatePicker
                  defaultValue={moment(new Date())}
                  placeholder="Choose Month"
                  onChange={onChange}
                  monthCellRender={monthCellRender}
                  inputReadOnly={true}
                  allowClear={false}
                  suffixIcon={<ChevronIcon />}
                  className='cust-datepicker'
                  picker="month" />
              </Space>
            </div>
          )}
        </ViewNavBar>
      )}
      {view !== 'calendar' ? (
        <Table
          columns={columns}
          data={tableData}
          loading={loading}
          error={!!error}
          extraConfig={{
            initialState: {
              sortBy: [
                {
                  id: "Scheduled On",
                  desc: true,
                },
              ],
            },
          }}
        />
      ) :
        (<div className={`calendar-wrap ${currentCalendarView === 'month' ? 'month-view' : ''}`}>
          <BookingCalendar loading={loading} />
        </div>)}
    </>
  )
}

export { Upcoming };
export default Upcoming;

import React from "react";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderDivider,
  CHeaderNav,
  CHeaderToggler,
  CNavLink,
  CNavItem,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";

import { AppHeaderDropdown, NotificationDropdown } from "./header/index";
import { toggleSideBar } from "../../slices/system";
import AppBreadcrumb from "./AppBreadcrumb";
import { ReactComponent as Hamburger } from './../../icons/hamburger.svg';


const AppHeader = () => {
  const dispatch = useDispatch();
  const { sidebarShow } = useSelector((state: any) => state.system);
  const {pageName} = useSelector((state: any) => state.page);

  return (
    <CHeader position="sticky" className="mb-4">
      <CContainer fluid>
        <CHeaderToggler
          className={`${sidebarShow ? 'show' : ''} ms-md-3 d-lg-none`}
          onClick={() => dispatch(toggleSideBar(!sidebarShow))}
        >
          {/* <CIcon name="cil-menu" size="lg" /> */}
          <Hamburger />
        </CHeaderToggler>
        {/* <CHeaderBrand className="d-md-none flex-grow-1">
          <img src="/images/Logo.svg" alt="" style={{ width: 100, marginTop: '-7px' }} />
        </CHeaderBrand> */}

        <CHeaderNav className="page-title">{pageName}</CHeaderNav>
        <CHeaderNav className="ms-3">
          <NotificationDropdown />
          <AppHeaderDropdown />
        </CHeaderNav>
      </CContainer>
      {/* <CHeaderDivider /> */}
      {/* <CContainer fluid>
        <AppBreadcrumb />
      </CContainer> */}
    </CHeader>
  );
};

export default AppHeader;

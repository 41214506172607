import { CButton } from "@coreui/react";
import { EReceiptTypes } from ".";

function RecieptExport({label, data, type}:any) {

    const download = (downloadUrl: string) => {
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.target = '_blank';
        link.download = `${type}${new Date().getTime()}.pdf`;
        link.click();
    };

    const exportReceipt = () => {
        const { studio_invoice_receipt, studio_payout_receipt } = data;
        const exportLink = type === EReceiptTypes.Payout ? studio_payout_receipt : studio_invoice_receipt;
        !!exportLink && download(exportLink);
    };
    return (
        <CButton color="info" variant="outline" onClick={exportReceipt}>{ label }</CButton>
    )
}

export default RecieptExport

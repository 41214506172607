import { EArtistTypeLabels } from "../../utils/constants";
import { EArtistStatus, EArtistStatusLabel, EArtistTypes, ECardTypes } from "../../utils/enums";

export const getCardType = (row: any): string => {
  const { status, active_status, artist_type, studio_share } = row;
  const { status: shareStatus } = studio_share || {};
  const isFreelancer = artist_type === EArtistTypes.Freelancer;
  let cardType = null;
  if (status === EArtistStatus.Pending) {
    cardType = ECardTypes.Request;
  } else if (status === EArtistStatus.Rejected) {
    cardType = ECardTypes.Rejected;
  } else if (status === EArtistStatus.Deactivated) {
    cardType = ECardTypes.Inactive;
  } else {
    cardType = !!active_status ? ECardTypes.Active : ECardTypes.Inactive;
    if (isFreelancer && shareStatus === EArtistStatus.Pending)
      cardType = ECardTypes.Request;
  }
  return cardType;
};

export const getStatusLabel = (row: any): string => {
  const cardType = getCardType(row);
  let label = "Unknown";

  switch (cardType) {
    case ECardTypes.Request:
      label = EArtistStatusLabel.Pending;
      break;
    case ECardTypes.Active:
      label = EArtistStatusLabel.Accepted;
      break;
    case ECardTypes.Inactive:
      label = EArtistStatusLabel.Inactive;
      break;
    case ECardTypes.Rejected:
      label = EArtistStatusLabel.Rejected;
      break;
    default:
      label = "Unknown";
  }

  return label;
};

export const isFreelancer = (row: any): boolean =>
  row.artist_type === EArtistTypes.Freelancer;

export const decodeArtistData = (row: any) => {
  const type = getCardType(row);
  const isActive = type === ECardTypes.Active;
  const isOnRequest = type === ECardTypes.Request;
  const isInactive = type === ECardTypes.Inactive;
  const isFreelancer = row.artist_type === EArtistTypes.Freelancer;
  const isPeriodicalFreelancer = isFreelancer && !!row.artist_is_periodic;
  const isNotPeriodicalFreelancer = isFreelancer && !row.artist_is_periodic;
  const isLateSplit = row.artist_is_late_split;
  return {
    isActive,
    isOnRequest,
    isInactive,
    isFreelancer,
    isPeriodicalFreelancer,
    isNotPeriodicalFreelancer,
    isLateSplit
  };
};

export const getArtistName = (row: any) => {
  return `${row.first_name} ${row.last_name}` || row.artist_name;
}

export const getArtistImage = (row: any) => {
  if (row.artist_image) return row.artist_image
  return '/images/avatar-dummy.png'
};

export const getArtistTypeLabel = (row: any) => {
  switch (row.artist_type) {
    case EArtistTypes.Freelancer:
      return "FreelancerLabel";
    case EArtistTypes.Employee:
      return "EmployeeLabel";
    default:
      return "EmployeeLabel";
  }
};

export const getArtistRelLabel = (data: any) => {
  let relLabel = data.artist_type
    ? EArtistTypeLabels[data.artist_type]
    : "";
  if (
    data.artist_type === EArtistTypes.Freelancer &&
    data.artist_is_periodic
  )
    relLabel = 'tenant';
  if(data.artist_is_late_split) relLabel = EArtistTypeLabels[EArtistTypes.Freelancer]
  return relLabel;
}

export const getArtistLabel = (label: string) => {
  if (label === "employee") return "An employee means that you pay a fixed salary for this artist."
  if (label === "tenant") return " A tenant means that this artist pays a fixed amount for the chair rental."
  if (label === "independent") return "A independent, share with each tattoo a portion of the income with you for the chair rent."
}

export const checkOnlyPeriodic = (artistArr: []) => {
  const res = artistArr.some((elem: any) =>
    (elem.artist_type === 'freelance' || elem.artist_type === 'employee') && elem.artist_is_periodic === false)
  return !res
}

export const badgeBgGen = (status: string) => {
  let badgeBg = 'Unknown';
  let badgeTextColor = 'Unknown'
  switch (status) {
    case EArtistStatusLabel.Pending:
      badgeBg = "warning";
      badgeTextColor = 'dark';
      break;
    case EArtistStatusLabel.Accepted:
      badgeBg = "success";
      badgeTextColor = 'white';
      break;
    case EArtistStatusLabel.Inactive:
      badgeBg = "secondary";
      badgeTextColor = 'dark';
      break;
    case EArtistStatusLabel.Rejected:
      badgeBg = "rejected";
      badgeTextColor = 'white';
      break;
    default:
      badgeBg = "Unknown";
      badgeTextColor = 'Unknown'
  }
  return [badgeBg, badgeTextColor];
}
export const artistStatusIcon = (status: string) => {
  let icon = 'Unknown';
  switch (status) {
    case EArtistStatusLabel.Pending:
      icon = 'cil-bolt'
      break;
    case EArtistStatusLabel.Accepted:
      icon = 'cil-pencil'
      break;
    case EArtistStatusLabel.Inactive:
    case EArtistStatusLabel.Rejected:
      icon = 'cil-magnifying-glass'
      break;
    default:
      icon = 'Unknown'
  }
  return icon;
}
import { FC } from "react";
import * as yup from "yup";

import { login } from "../../../slices/auth";
import { useDispatch } from "../../../store";
import { FormHeader, FormHead, FormSubHead, FormBody } from "../styles";
import LoginForm from "./Form";

const schema = yup.object().shape({
  username: yup.string().required(),
  password: yup.string().required(),
});

export default function App(props: any) {
  const {
    location: { search },
  } = props;
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const { token, fromRP } = params;
  return (
    <>
      {
        !!token ? (
          <FormHeader>
                <img src="/images/Logo.svg" alt="" />
                <FormHead>Open your studio account.</FormHead>
                <FormSubHead>Manage your artists and payments.</FormSubHead>
            </FormHeader>
        ) : (
          <FormHeader>
            <img src="/images/Logo.svg" alt="" />
            {fromRP ? (
              <>
                <FormHead>
                  Welcome back! <br /> Log in again.
                </FormHead>
                <FormSubHead>Use your new password.</FormSubHead>
              </>
            ) : (
              <>
                <FormHead>
                  Login to your <br /> studio account.
                </FormHead>
                <FormSubHead>Manage your artists and payments.</FormSubHead>
              </>
            )}
          </FormHeader>
        )
      }
      <FormBody>
        <LoginForm token={token} />
      </FormBody>
    </>
  );
}

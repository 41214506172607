import { CBadge, CProgress, CProgressBar } from '@coreui/react'
import { useState } from 'react'
import { IArtistCardData } from '..'
import { EArtistTypeLabels } from '../../../utils/constants';
import { EArtistStatusLabel, EArtistTypes } from '../../../utils/enums';
import { badgeBgGen, getArtistLabel, getArtistRelLabel, getStatusLabel } from '../functions';
import ArtistShareProgressBar from './ArtistShareProgressBar';
import { RowActions } from './RowActions';

// interface IProps {
//     artist: IArtistCardData
// }

const ArtistCard = (props: any) => {
    const { artist, fecthList } = props;
    const [isPhoneActive, setIsPhoneActive] = useState(false);
    const [isCardDetOpen, setIsCardDetOpen] = useState(false);
    const relLabel = getArtistRelLabel(artist)
    const status = getStatusLabel(artist);
    const [badgeBg, badgeTextColor] = badgeBgGen(status);
    const studioShare = parseInt(artist.studio_share.share) || 0;
    const studioNewShare = parseInt(artist.studio_share.new_share) || 0;
    const studioShareStatus = artist.studio_share.status;
    const isFreelance = artist.artist_type === EArtistTypes.Freelancer;
    const isPeriodic = artist.artist_type === EArtistTypes.Freelancer && artist.artist_is_periodic;
    const isLateSplit = artist.artist_is_late_split;

    return (
        <div className="artist-card">
            <div className="artist-card-header" onClick={() => setIsCardDetOpen(!isCardDetOpen)}>
                <div className="artist-avatar-wrap">
                    <div className="artist-avatar-img">
                        <img src={artist.artist_image} alt="" />
                    </div>
                    {/* <span className="status-icon">
                        {status !== EArtistStatusLabel.Pending ? 
                            <img src="images/calendar.svg" alt="" /> : 
                            <img src="images/coffee.svg" alt="" />
                        }
                    </span> */}
                </div>
                <div className="artist-info">
                    <div className="artist-label">
                        <CBadge
                            className={`text-capitalize rel-label me-2 ${relLabel}`}>{relLabel}</CBadge>
                        <CBadge
                            textColor={badgeTextColor}
                            color={badgeBg}
                            style={{ ['--cui-secondary' as any]: (status === EArtistStatusLabel.Inactive || status === EArtistStatusLabel.Rejected) && '#ced2d8' }}
                        >{status}</CBadge>
                    </div>
                    <h3 className="artist-name">{artist.first_name} {artist.last_name}</h3>
                </div>
            </div>
            <div className={`artist-card-det-wrap ${status.toLowerCase()} ${isCardDetOpen ? 'open' : 'close'}`}>
                <div className="artist-card-body">
                    <div className="progress-label">
                        <span>Studio</span>
                        <span>Artist</span>
                    </div>
                    {(studioShareStatus === 'pending') ?
                        (studioShare === studioNewShare) ?
                            (
                                <ArtistShareProgressBar
                                    className="active"
                                    share={isPeriodic || isLateSplit ? 0 :
                                        !isFreelance ? 100 : studioShare
                                    }
                                />
                            )
                            : (
                                <>
                                    <ArtistShareProgressBar
                                        className="active"
                                        share={isPeriodic || isLateSplit ? 0 :
                                            !isFreelance ? 100 : studioShare
                                        }
                                    />
                                    <ArtistShareProgressBar
                                        className="pending"
                                        share={isPeriodic || isLateSplit ? 0 :
                                            !isFreelance ? 100 : studioNewShare
                                        }
                                    />
                                </>
                            )
                        : (
                            <ArtistShareProgressBar
                                className="active"
                                share={isPeriodic || isLateSplit ? 0 :
                                    !isFreelance ? 100 : studioShare
                                }
                            />
                        )}
                </div>
                <div className="artist-card-footer">
                    <div className="footer-btn">
                        {
                            status !== EArtistStatusLabel.Pending && (
                                <div className="contact-btn">
                                    <a href={`mailto:${artist.email}`} className="mail inner-btn">
                                        <img src="images/mail.svg" alt="" />
                                    </a>
                                    <div className="phone inner-btn" onClick={() => setIsPhoneActive(!isPhoneActive)}>
                                        <img src="images/phone.svg" alt="" />
                                    </div>
                                    {
                                        isPhoneActive && (
                                            <div className="phone-number inner-btn">
                                                {artist.phone_code} {artist.phone_no}
                                            </div>
                                        )
                                    }
                                </div>
                            )
                        }
                        <RowActions row={artist} fecthList={fecthList} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ArtistCard
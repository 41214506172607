import { createSlice } from "@reduxjs/toolkit";

interface IState {
  sidebarShow: boolean;
  sidebarUnfoldable: boolean|undefined;
}

const initialState: IState = {
  sidebarShow: true,
  sidebarUnfoldable: undefined,
};

const slice = createSlice({
  name: "system",
  initialState,
  reducers: {
    toggleSideBar(state, action) {
      state.sidebarShow = action.payload || !state.sidebarShow;
    },
    toggleSidebarUnfoldable(state, action) {
      state.sidebarUnfoldable = action.payload || !state.sidebarUnfoldable;
    },
  },
});

export const { toggleSideBar, toggleSidebarUnfoldable } = slice.actions;
export const reducer = slice.reducer;

export default slice;

import React, { useEffect } from "react";
import { useAuth } from "../../../hooks";
import { Redirect } from "react-router-dom";
import { CSpinner } from "@coreui/react";

const Logout = () => {
  const { logout, isLoggedIn } = useAuth();

  useEffect(() => {
    logout();
  }, []);

  if (!isLoggedIn) {
    return <Redirect to="/" />;
  }

  return <CSpinner />;
};

export default Logout;

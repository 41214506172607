import React, { useEffect, useRef, useState } from 'react';
import * as icon from '@coreui/icons';

import { API_END_POINTS } from '../../utils/constants';

import AnalyticCard from './components/analyticCard';
import ContentHeader from './components/ContentHeader';
import OfferAnalytics from './components/OfferAnalytics';
import { AnalyticCardRef, DateRangeSelect, OfferAnalyticCardRef } from './_lib/constants';
import CIcon from '@coreui/icons-react';
import DatePicker, { utils } from '@hassanmojab/react-modern-calendar-datepicker';
import FilterData from './components/FilterData';


const DataAnalytics: React.FC<{}> = () => {

  const analyticRefCustomer = useRef<AnalyticCardRef>(null);
  const analyticRefArtist = useRef<AnalyticCardRef>(null);
  const analyticRefLeads = useRef<AnalyticCardRef>(null);
  const analyticRefContacted = useRef<AnalyticCardRef>(null);
  const offerAnalyticsRef = useRef<OfferAnalyticCardRef>(null);

  useEffect(() => {
    document.title = "Inckd analytics"
  }, [])

  const toggleAllToday = (): void => {
    analyticRefCustomer?.current?.toggleToday();
    analyticRefArtist?.current?.toggleToday();
    analyticRefLeads?.current?.toggleToday();
    analyticRefContacted?.current?.toggleToday();
    offerAnalyticsRef?.current?.toggleOfferTodayFilter();

  };

  const toggleAllTodayReset = (): void => {
    analyticRefCustomer?.current?.resetFilter();
    analyticRefArtist?.current?.resetFilter();
    analyticRefLeads?.current?.resetFilter();
    analyticRefContacted?.current?.resetFilter();
    offerAnalyticsRef?.current?.toggleOfferReset();
  }

  const toggleDateRange = (from: string, to: string): void => {
    analyticRefCustomer?.current?.toggleDateRange(from, to);
    analyticRefArtist?.current?.toggleDateRange(from, to);
    analyticRefLeads?.current?.toggleDateRange(from, to);
    analyticRefContacted?.current?.toggleDateRange(from, to);
    offerAnalyticsRef?.current?.toggleOfferDateRangeFilter(from, to);
  }

  const handleGlobalDateRangeFilter = (dateRange: DateRangeSelect) => {
    const { from, to } = dateRange;
    if (from && to) {
      const fromDate = `${from?.day}/${from?.month}/${from?.year}` || '';
      const toDate = to ? `${to?.day}/${to?.month}/${to?.year}` : '';
      toggleDateRange(fromDate, toDate);
    }
  }

  return (
    <>
      <header className='analytics-header'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div><img src="/images/Logo-white.svg" alt="" style={{ width: 100 }} /></div>
            </div>
          </div>
        </div>
      </header>
      <section className='analytics-layout'>
        <div className='container'>
          <FilterData
            onSelectToday={toggleAllToday}
            onSelectDateRange={handleGlobalDateRangeFilter}
            onFilterReset={toggleAllTodayReset}
          />
          <ContentHeader
            title='Real-time user analytics data'
            description={
              <p>The dashboard displays key metrics of customers, artist and offers.
                <br />
                At the top of each section, you will find filters that allow you to set today or specific date ranges.
              </p>
            }
          />

          <div className='row'>
            <div className='col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12'>
              <AnalyticCard
                title="Customers"
                apiEndPoint={API_END_POINTS.getAnalyticsCountCustomer}
                iconName={icon?.cilPeople}
                ref={analyticRefCustomer}
              />
            </div>
            <div className='col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12'>
              <AnalyticCard
                title="Artist"
                apiEndPoint={API_END_POINTS.getAnalyticsCountArtist}
                iconName={icon?.cilPenNib}
                ref={analyticRefArtist}
              />
            </div>
            <div className='col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12'>
              <AnalyticCard
                title="Leads"
                apiEndPoint={API_END_POINTS.getAnalyticsCountLeads}
                iconName={icon?.cilSend}
                ref={analyticRefLeads}
              />
            </div>
            <div className='col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12'>
              <AnalyticCard
                title="Contacted Customers"
                apiEndPoint={API_END_POINTS.getAnalyticsCountContactedCustomers}
                iconName={icon?.cilPhone}
                ref={analyticRefContacted}
              />
            </div>
          </div>
          <div className='spacer' />
          <OfferAnalytics ref={offerAnalyticsRef} />
        </div>
      </section>
    </>
  )
};

export default DataAnalytics;
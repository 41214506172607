import React from "react";
import { TButton } from "../Table";

import { isMobile, getRandomKey, buildUrl } from "./helpers";

const listItems = [
  { apple: "Apple Calendar" },
  { google: "Google" },
  { outlook: "Outlook" },
  { outlookcom: "Outlook.com" },
  { yahoo: "Yahoo" },
];

const isCrappyIE = false;

const AddToCalendar = ({ event }: any) => {
  const handleDropdownLinkClick: any = (e: {
    preventDefault: () => void;
    currentTarget: { getAttribute: (arg0: string) => any };
  }) => {
    e.preventDefault();
    let url = e.currentTarget.getAttribute("href");

    if (!isMobile() && (url.startsWith("data") || url.startsWith("BEGIN"))) {
      let filename = "download.ics";
      let blob = new Blob([url], { type: "text/calendar;charset=utf-8" });

      if (isCrappyIE) {
        window.navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        /****************************************************************
            // many browsers do not properly support downloading data URIs
            // (even with "download" attribute in use) so this solution
            // ensures the event will download cross-browser
            ****************************************************************/
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } else {
      window.open(url, "_blank");
    }
  };
  let items = listItems.map((listItem: any) => {
    let currentItem = Object.keys(listItem)[0];
    let currentLabel = listItem[currentItem];

    let icon = null;

    return (
      <li key={getRandomKey()}>
        <a
          className={currentItem + "-link"}
          onClick={handleDropdownLinkClick}
          href={buildUrl(event, currentItem, isCrappyIE)}
          target="_blank"
        >
          {icon}
          {currentLabel}
        </a>
      </li>
    );
  });
  const handleClick = () => {
    let url = buildUrl(event, "google", isCrappyIE);

    if (!isMobile() && (url.startsWith("data") || url.startsWith("BEGIN"))) {
      let filename = "download.ics";
      let blob = new Blob([url], { type: "text/calendar;charset=utf-8" });

      if (isCrappyIE) {
        window.navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        /****************************************************************
              // many browsers do not properly support downloading data URIs
              // (even with "download" attribute in use) so this solution
              // ensures the event will download cross-browser
              ****************************************************************/
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } else {
      window.open(url, "_blank");
    }
  };

  return (
    <TButton onClick={handleClick} className="ms-2" color="info">
      Add to calendar
    </TButton>
  );
};
export default AddToCalendar;

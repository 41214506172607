import axios from "axios";
import { makeUseAxios } from "axios-hooks";
import { axiosInstancePublic } from "../utils/axiospublicapi";

const useHttp = makeUseAxios({
  axios: axiosInstancePublic,
  defaultOptions: { manual: false },
});

export default useHttp;

import React, { useEffect } from "react";
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import { useDispatch, useSelector } from "../../store";
import { setModalVisible } from "../../slices/modal";

export interface IConfirmModal {
  title?: string | JSX.Element;
  children: JSX.Element;
  buttonElm: JSX.Element;
  size?: "sm" | "lg" | "xl";
}

export const ConfirmModal = ({
  title,
  children,
  buttonElm,
  size,
}: IConfirmModal) => {
  const {modalVisible} = useSelector((state) => state.modal);
  const dispatch = useDispatch()
  const [visible, setVisible] = React.useState(false);
  const onClick = () => {
    setVisible(true);
    dispatch(setModalVisible(true))
  }
  if(!!modalVisible) {
    return (
      <>
        {React.isValidElement(buttonElm) &&
          React.cloneElement<any>(buttonElm, { onClick: onClick})}
        <CModal
          visible={visible}
          onDismiss={() => setVisible(false)}
          size={size || "xl"}
          alignment="center"
          scrollable
        >
          <CModalHeader onDismiss={() => setVisible(false)} className={`${!title ? 'border-0' : ''}`}>
            <CModalTitle>{title}</CModalTitle>
          </CModalHeader>
          <CModalBody>{children}</CModalBody>
        </CModal>
      </>
    );
  }
  return (<>{React.isValidElement(buttonElm) &&
    React.cloneElement<any>(buttonElm, { onClick: onClick})}</>)
};

export default ConfirmModal;

import { CProgress, CProgressBar } from '@coreui/react'
import React from 'react'

const ArtistShareProgressBar = (props: any) => {
    const {share, className} = props;
    return (
        <>
            <CProgress className={className}>
                <CProgressBar value={share} />
            </CProgress>
            <div className={`${className} progress-label`}>
                <div>
                    <span>{share}%</span>
                    <span className="status-icon">
                        {className === 'pending' ? 
                            <img src="images/info-icon.svg" alt="" /> :
                            <img src="images/tick.svg" alt="" /> 
                        }
                    </span>
                </div>
                <div>
                    <span>{100 - share}%</span>
                    <span className="status-icon">
                        {className === 'pending' ? 
                            <img src="images/info-icon.svg" alt="" /> :
                            <img src="images/tick.svg" alt="" /> 
                        }
                    </span>
                </div>
            </div>
        </>
    )
}

export default ArtistShareProgressBar

import styled from "styled-components";

export const FormHead = styled.div`
    margin-bottom: 25px;
    font-size: 28px;
    letter-spacing: 0.02em;
    font-weight: 600;
    color: #8C62DB;
  @media (max-width: 767px) {
    margin-bottom: 10px;
    font-size: 27px;
    line-height: 1.3;
  }
`;

export const FormBody = styled.div`
  /*  @media(min-width: 768px) {
        padding: 0 15px;
    } */
  & {
    .MuiFormControl-root {
      width: 100%;
      &.disclaimer-sec {
        margin-top: 80px;
        @media (max-width: 767px) {
          margin-top: 60px;
        }
      }
    }
    .MuiInputLabel-formControl {
      position: static;
      transform: none;
      font-size: 20px;
      color: "#ccc";
      font-weight: 500;
      margin: 0 0 19px;
      padding-left: 5px;
      letter-spacing: 0.03em;
      @media (max-width: 767px) {
        font-size: 16px;
        margin-bottom: 10px;
      }
    }
  }
`;

export const FormSubHead = styled.div`
  font-size: 0.875rem;
  color: #646464;
  letter-spacing: 0.02em;
  a {
    text-decoration: none;
    color: "purple";
  }
  @media (max-width: 767px) {
    &.text-wrap {
      &-md {
        max-width: 245px;
      }
    }
  }
`;

export const FormHeader = styled.div`
  margin-bottom: 20px;
  img {
    max-width: 172px;
    margin-bottom: 40px;
    @media (max-width: 767px) {
      display: none;
    }
  }
  @media (min-width: 768px) {
    text-align: center;
  }
  @media (max-width: 767px) {
    padding: 0 10px;
  }
`;

export const BottomNav = styled.div`
  font-size: 12px;
  text-align: center;
  margin-top: 12px;
  position: absolute;
  left: 0;
  right: 0;
  a {
    color: #646464;
    text-decoration: none;
  }
`;

export const FormAction = styled.div`
  padding: 25px 20px;
  background-color: "#cceeee";
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    padding: 14px 20px;
    flex-direction: column;
    align-items: flex-start;
  }
  & {
    .error-msg {
      margin-left: 15px;
      font-size: ${(props) => props.theme.typography.pxToRem(14)};
      color: ${(props) => props.theme.palette.info.main};
      letter-spacing: 0.03em;
      @media (max-width: 767px) {
        margin: 12px 0 0 5px;
      }
    }
  }
`;

export const SettingsFormWrap = styled.div`
  max-width: 1054px;
  padding: 60px 27px 0;
  .MuiFormControl-root {
    margin: 0 0 25px;
  }
  .MuiSwitch-root {
    margin-left: -12px;
  }
  .switch-active {
    color: #77bb20;
  }
  .switch-inactive {
    color: #ff3e30;
  }
  .MuiSwitch-track {
    background-color: #ff3e30;
    opacity: 1;
  }
  .MuiSwitch-colorSecondary.Mui-checked {
    color: #f2eefa;
    & + .MuiSwitch-track {
      background-color: #77bb20;
      opacity: 1;
    }
  }
  @media (min-width: 768px) and (max-width: 1199px) {
    max-width: 754px;
  }
`;

import { combineReducers } from "@reduxjs/toolkit";
import { reducer as auth } from "../slices/auth";
import { reducer as system } from "../slices/system";
import { reducer as modal } from "../slices/modal";
import { reducer as countries } from "../slices/countryLists";
import { reducer as notifications } from "../slices/notifications";
import { reducer as page } from "../slices/page";
import { reducer as bookings } from "../slices/booking";
import storage from "redux-persist/lib/storage";
import { RootState } from ".";

const appReducer = combineReducers({
  auth,
  system,
  countries,
  notifications,
  modal,
  page,
  bookings
});

const rootReducer = (state:any, action:any) => {
  if (action.type === 'auth/clearResults') {
    storage.removeItem('persist:root')
    state = {} as RootState
  }
  
  return appReducer(state, action)
}


export default rootReducer;

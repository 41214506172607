import React from "react";
import { useLocation } from "react-router-dom";
import { useAuth } from ".";
import { getRows as getNotificationRows } from "../slices/notifications";
import { useDispatch } from "../store";

function usePageViews() {
  let location = useLocation();
  const dispatch = useDispatch();
  const { isLoggedIn } = useAuth();

  React.useEffect(() => {
    if (isLoggedIn) dispatch(getNotificationRows());
  }, [location]);
}

export default usePageViews;

import { createSlice, current } from "@reduxjs/toolkit";
import moment from "moment";

const monthFilter = (data: any, value: any) => {
    return data.filter((el: any) => {
        const offerDate = moment(el.offer_date).format('YYYY-MM')
        return value === offerDate
    })
}

const artistSelectFilter = (data: any, value: any) => {
    return data.filter((el: any) => {
        return el.artist.id === value
    });
}

const initialState: any = {
    bookingData: [],
    artistFilter: [],
    activeArtistFilter: 'all',
    activeMonthFilter: '',
    isFilteres: false,
    artistsList: [],
    currentCalendarView: 'month',
    isMobileWeek: false,
    calDate: new Date(),
};

const slice = createSlice({
    name: "bookings",
    initialState,
    reducers: {
        updateBookingData(state, { payload }) {
            return {
                ...state,
                bookingData: payload
            }
        },
        updateArtistFilter(state, { payload }) {
            const curState = current(state);
            const activeFilterDate = curState.activeMonthFilter;
            if (activeFilterDate) {
                if (payload === 'all') {
                    const items = monthFilter(curState.bookingData, activeFilterDate)
                    return {
                        ...state,
                        artistFilter: items,
                        activeArtistFilter: 'all',
                        isArtistFiltered: true
                    }
                }
                else {
                    const items = monthFilter(curState.bookingData, activeFilterDate)
                    const itemsMod = artistSelectFilter(items, payload)
                    return {
                        ...state,
                        artistFilter: itemsMod,
                        activeArtistFilter: payload,
                        isArtistFiltered: true
                    }
                }
            } else {
                if (payload === 'all') {
                    return {
                        ...state,
                        artistFilter: [],
                        activeArtistFilter: 'all',
                        isArtistFiltered: false
                    }
                } else {
                    const items = artistSelectFilter(curState.bookingData, payload)
                    return {
                        ...state,
                        artistFilter: items,
                        activeArtistFilter: payload,
                        isArtistFiltered: true
                    }
                }
            }
        },
        updateActiveMonthFilter(state, {payload}) {
            return {
                ...state,
                activeMonthFilter: payload
            }
        },
        updateArtistsList(state, { payload }) {
            return {
                ...state,
                artistsList: payload
            }
        },
        updateFilteredMonthData(state, { payload }) {
            const curState = current(state);
            const activeArtistFilter = curState.activeArtistFilter
            if (activeArtistFilter !== 'all') {
                const items = artistSelectFilter(curState.bookingData, activeArtistFilter);
                const itemsMod = monthFilter(items, payload)
                return {
                    ...state,
                    artistFilter: itemsMod,
                    activeMonthFilter: payload,
                    isArtistFiltered: true
                }
            } else {
                const months = monthFilter(curState.bookingData, payload)
                return {
                    ...state,
                    artistFilter: months,
                    activeMonthFilter: payload,
                    isArtistFiltered: true
                }
            }
        },
        updtaeCurrentCalendarView(state, { payload }) {
            return {
                ...state,
                currentCalendarView: payload
            }
        },
        updateDate(state, { payload }) {
            return {
                ...state,
                calDate: payload
            }
        },
        updateIsMobileWeek(state, { payload }) {
            return {
                ...state,
                isMobileWeek: payload
            }
        }
    }
});
export const {
    updateBookingData,
    updateArtistFilter,
    updateArtistsList,
    updateFilteredMonthData,
    updtaeCurrentCalendarView,
    updateIsMobileWeek,
    updateActiveMonthFilter,
    updateDate } = slice.actions;

export const reducer = slice.reducer;

export default slice;

import CIcon from "@coreui/icons-react";
import {
  CAlert,
  CAvatar,
  CButton,
  CCol,
  CRow,
  CTable,
  CWidgetIcon,
} from "@coreui/react";
import _ from "lodash";
import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import { Column } from "react-table";
import { ContentModal, Table, UserCard } from "../../../components";
import { TButton } from "../../../components/Table";
import { useAxios } from "../../../hooks";
import { API_END_POINTS } from "../../../utils/constants";
import { formateDate, groupByMonth, numToFixed } from "../../../utils/functions";
import { getArtistImage, getArtistName } from "../../artists/functions";
import PaymentList from "./PaymentList";
import { DatePicker, Space } from 'antd';
import { useMediaQuery } from "react-responsive";
import ArtistCard from "../../artists/components/ArtistCard";
import HistoryCard from "./HistoryCard";
import { ReactComponent as ChevronIcon } from './../../../icons/down-arrow.svg'

function PaymentsHistory() {
  const [paymentData, setPaymentData] = useState<Record<string, any>>({});
  const [month, setMonth] = useState('');
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' })
  const [{ data: res, error, loading }, fetchList] = useAxios(
    API_END_POINTS.getStudioPayoutHistory,
    { manual: true }
  );
  useEffect(() => {
    fetchList()
  }, [])

  useEffect(() => {
    setMonth(Object.keys(paymentData)[0]);
  }, [paymentData])

  useEffect(() => {
    if (!!res) {
      const { data } = res;
      setPaymentData(groupByMonth(data) || {})

    }
    !!error && console.log(error)
  }, [res, error])

  const widgetDataArr: any = [
    {
      color: "primary",
      icon: "cil-chart-pie",
      title: "Total Earnings",
      value: `${res?.data?.currency?.code || ""} ${numToFixed(
        res?.data?.payout_amount || 0
      )}`,
    },
    {
      color: "primary",
      icon: "cil-dollar",
      title: "Next Payout",
      value: `${res?.data?.currency?.code || ""} ${numToFixed(
        res?.data?.next_payout || 0
      )}`,
    },
    {
      color: "primary",
      icon: "cil-money",
      title: "Next Payout Date",
      value: res?.data?.next_payout_date
        ? formateDate(res?.data?.next_payout_date)
        : "-",
    },
  ];

  const onChange = (date: any, dateString: any) => {
    setMonth(date.format('MMMM YYYY'));
  }

  const monthCellRender = (date: any, locale: any) => {
    return (<>{date.format('MMMM')}</>)
  }

  return (
    <>
      <CRow>
        {widgetDataArr.map((x: any) => (
          <CCol md="4">
            <CWidgetIcon
              className="mb-3"
              padding={0}
              icon={<CIcon icon={x.icon} size="xl" />}
              iconPadding={4}
              title={x.title}
              value={x.value}
              color={x.color}
            />
          </CCol>
        ))}
      </CRow>
      {
        Object.keys(paymentData).length ?
          <>
            <div className="monthpicker-bar">
              <Space direction="vertical">
                <DatePicker
                  placeholder="Choose Month"
                  onChange={onChange}
                  monthCellRender={monthCellRender}
                  inputReadOnly={true}
                  allowClear={false}
                  suffixIcon={<ChevronIcon />}
                  className='cust-datepicker'
                  picker="month" />
              </Space>
            </div>
            {!isMobile ? (<PaymentList
              data={paymentData[month]}
              loading={loading}
              error={error}
            />) : (
              <div className="payment-card-wrap">
                {
                  !!paymentData[month] ?
                    paymentData[month]?.map((item: any) => (
                      <HistoryCard
                        data={item}
                      />
                    )) : (
                      <CAlert color="info" className="my-4 d-flex" >
                        <CIcon icon="cil-bell" size="lg" className="mx-4" />
                        <div>No data available</div>
                      </CAlert>
                    )
                }
              </div>
            )}

          </>
          :
          <Table
            columns={[]}
            data={[]}
            loading={loading}
            error={!!error}
          />
      }
    </>
  )
}

export { PaymentsHistory };
export default PaymentsHistory;

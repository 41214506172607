import {AccountForm} from "./Form";

function Account() {
    return (
        <div>
            <div className="text-banner">First, please complete the registration page with your data before you can use your studio account with all its features.</div>
            <AccountForm></AccountForm>
        </div>
    )
}

export default Account

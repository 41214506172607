import React from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderDivider,
  CHeaderNav,
  CHeaderToggler,
  CNavLink,
  CNavItem,
} from "@coreui/react";

const AppHeader = () => {
  const dispatch = useDispatch();
  const { sidebarShow } = useSelector((state: any) => state.system);

  return (
    <CHeader position="sticky" className="mb-4 bg-primary">
      <CContainer fluid  >
        <Link to="/">
          <img src="/images/Logo-white.svg" alt="" style={{ width: 100 }} />
        </Link>
      </CContainer>
    </CHeader>
  );
};

export default AppHeader;

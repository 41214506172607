import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { string, object } from "yup";
import axios from "../../../utils/axios";
import { Form } from "../../../components";
import { setLoading } from "../../../slices/auth";
import { useDispatch, useSelector } from "../../../store";
import { API_END_POINTS } from "../../../utils/constants";
import { BottomNav } from "../styles";

const schema = object().shape({
  email: string()
    .email("Address format not valid!")
    .required("Address not valid!"),
});
function ForgotPasswordForm({
  frgtUsrSuccess,
  setFrgtUsrSuccess,
  setFrgtUsrError,
  frgtUsrError,
  setUserEmail,
}: any) {
  const formRef = useRef<any>();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(setLoading(false));
  },[])

  const onSubmit = async (data: any) => {
    dispatch(setLoading(true));
    setUserEmail(data.email);
    try {
      const response: any = await axios.post(API_END_POINTS.forgotPswd, data);
      if (response?.status === 200 || response?.status === 201) {
        dispatch(setLoading(false));
        setFrgtUsrSuccess(true);
        setFrgtUsrError("");
        formRef.current.reset();
      }
    } catch (error) {
      dispatch(setLoading(false));
      setFrgtUsrError("This address is not registered!");
    }
  };
  return (
    <Form.Form onSubmit={onSubmit} validationSchema={schema} ref={formRef}>
      <Form.Input
        name="email"
        placeholder="Your email address"
        helperText={!!frgtUsrError && frgtUsrError}
      />
      <div className="text-center mt-2">
        <Form.Button label="Reset password" isLoading={isLoading} />
      </div>
      <BottomNav>
        <Link to="/login">Back to login</Link>
      </BottomNav>
    </Form.Form>
  );
}

export default ForgotPasswordForm;

import React from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarToggler,
  CCreateNavItem,
} from "@coreui/react";

import CIcon from "@coreui/icons-react";

import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

// sidebar nav config
import navigation from "../../../nav";
import SidebarContent from "./SidebarContent";
import { toggleSideBar, toggleSidebarUnfoldable } from "../../../slices/system";
import { useAuth } from "../../../hooks";

const AppSidebar = () => {
  const dispatch = useDispatch();
  const { active_status } = useAuth();
  const { sidebarShow, sidebarUnfoldable } = useSelector(
    (state: any) => state.system
  );
  
  return (
    <CSidebar
      position="fixed"
      selfHiding="md"
      unfoldable={sidebarUnfoldable}
      visible={sidebarShow}
      onShow={() => dispatch(toggleSideBar(true))}
      onHide={() => {
        dispatch(toggleSideBar(false));
      }}
    >
      <CSidebarBrand>
        <img src="/images/Logo-white.svg" alt="" style={{ width: 100 }} />
      </CSidebarBrand>
      <CSidebarNav className={!active_status ? 'in-active' : 'active'}>
        <SimpleBar>
          <SidebarContent items={navigation} />
        </SimpleBar>
      </CSidebarNav>
      {/* <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() => dispatch(toggleSidebarUnfoldable(!sidebarUnfoldable))}
      /> */}
    </CSidebar>
  );
};

export default React.memo(AppSidebar);

import _ from "lodash";
import moment from "moment";
import { toast } from "react-toastify";

export const showMsg = (msg: string) => toast.dark(msg);

export const formateDate = (date: string, format = "DD/MM/YY") =>
  moment(date).format(format);

export const numToFixed = (num: number | string, points: number = 2): string =>
  !!num
    ? (Math.floor(+num * Math.pow(10, points)) / Math.pow(10, points)).toFixed(points)
    : Number.parseFloat("0").toFixed(points);

export const toInt = (
  num: number | string,
  asString: boolean = false
): number | string => {
  const res = parseInt(num + "" || "0");
  return asString ? res + "" : res;
};

export const getQueryVariable = (variable: string) => {
  let query = window.location.search.substring(1);
  let vars = query.split("&");
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split("=");
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false;
};

export const groupByMonth = (data: Record<string, any>) => {
  const { details: listArray } = data || {};
  if (!!listArray) {
    const orderByFn = (list: Record<string, any>) => new Date(list.date).getTime();
    const groupByFn = (list: Record<string, any>) => moment(list.date).format('MMMM YYYY');
    const orderedListArray = _.orderBy(listArray, [orderByFn], ['desc']);
    const groupByMonthList = _.groupBy(orderedListArray, groupByFn);
    return groupByMonthList;
  }
}

import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { RootState, useSelector } from '../../store';

const GuestGuard = ({ children }: any) => {
  const auth = useSelector((state: RootState) => state.auth);
  const { isLoggedIn } = auth;
  if (isLoggedIn) {
    return <Redirect to="/" />;
  }

  return <>{children}</>;
};

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default GuestGuard;
import { EArtistTypes } from "./enums";

const {
  REACT_APP_API_URL: API_URL,
  REACT_APP_DEV_API_URL: DEV_API_URL,
  REACT_APP_CLOUD_API_URL: CLOUD_API_URL,
} = process.env;

export { API_URL, DEV_API_URL, CLOUD_API_URL };

export const API_END_POINTS = {
  login: "/studiowner/api/login",
  forgotPswd: "/studiowner/api/reset_password/",
  resetPassword: "/studiowner/api/reset_password/confirm/",
  signInEmailVerify: "/studiowner/api/verify/signin",
  signIn: "studiowner/api/signin",
  getStudioArtists: "/studiowner/api/artist/list",
  getCountryList: "/api/get/countries",
  updateArtistStatus: "/studiowner/api/artist/status",
  updateShareStatus: "/studiowner/api/artist/share/status",
  getStudioDetails: "/studiowner/api/studio/details",
  getBillingAddress: "/studiowner/api/studio/tax",
  updateBillingAddress: "/studiowner/api/studio/tax",
  changePassword: "/studiowner/api/changepassword",
  revokeArtist: "/studiowner/api/artist/activestatus",
  updateBankDetails: "/studiowner/api/payout/customaccount",
  vatDetails: "/studiowner/api/studio/vat",
  createCustomAccountLink: "/studiowner/api/payout/customaccount/link",
  getStudioPayoutHistory: "/studiowner/api/studio/history",
  getStudioUpcomingBookings: "/studiowner/api/offer/bookings",
  getStudioPayouts: "/studiowner/api/studio/payouts",
  getStudioPayoutDetail: "/studiowner/api/studio/single/payout",
  getStaticContents: "/api/cms/list",
  getDashboardStati: "/studiowner/api/studio/details/count",
  getAnalyticsCountCustomer: "/api/third_party/customer/details",
  getAnalyticsCountArtist: "/api/third_party/public-artist/details",
  getAnalyticsCountLeads: "/api/third_party/leads/count",
  getAnalyticsCountContactedCustomers: "/api/third_party/contacted_customer/count",
  getAnalyticsOfferCount: '/api/third_party/offers/details'
};

export const ROUTING_URLS = {
  login: "/login",
  logout: "/logout",
  signin: "/sign-in",
  artists: "/artists",
  dashboard: "/dashboard",
  studio: "/studio",
  account: "/account",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password/:token",
  changePassword: "/settings/password",
  address: "/settings/account-details",
  contact: "/settings/contact",
  bankDetails: "/payments/bank-details",
  vatDetails: "/payments/vat-details",
  billingAddress: "/payments/billing-address",
  history: "/payments/history",
  receipts: "/payments/receipts",
  payouts: "/payments/payouts",
  invoices: "/payments/invoices",
  informations: "/informations/:page",
  termsAndConditions: "/informations/terms&conditions",
  privacyPolicy: "/informations/privacy_policy",
  impressum: "/informations/impressum",
  rules: "/informations/rules",
  publicInfo: "/public/:page",
  publicTermsAndConditions: "/public/terms&conditions",
  publicPrivacyPolicy: "/public/privacy_policy",
  publicImpressum: "/public/impressum",
  publicRules: "/public/rules",
  upcomingBookings: "/bookings/upcoming",
  notifications: "/notifications",
  payoutReceipts: "/payments/receipts-payout",
  invoiceReceipts: "/payments/receipts-invoice",
  page404: "/404",
  page500: "/500",
  analytics: '/analytics'
};

export const responsiveScale = (value: number, width: number) => {
  return `${value / (width / 100)}vw`;
};

export const countryOptions = [
  { id: 2658434, name: "Switzerland" },
  { id: 2921044, name: "Germany" },
  { id: 1269750, name: "India" },
];

export const defaultImages = {
  user: "/images/default/user.png",
};

export const onlySpaceCheck = {
  match: /^(?!\s+$).*/,
  text: 'This field cannot contain only blankspaces'
}


export const EArtistTypeLabels: any = {
  [EArtistTypes.Freelancer] : "independent",
  [EArtistTypes.Employee] : "employee",
  [EArtistTypes.Owner] : "owner",
}
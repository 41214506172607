import React from "react";
import { Column } from "react-table";
import { CButton } from "@coreui/react";

import { Table } from "../../components";
import { useDispatch, useSelector } from "../../store";
import { formateDate } from "../../utils/functions";
import { Link, useHistory } from "react-router-dom";
import getRedirectionObj from "./functions";
import { readNotification } from "../../slices/notifications";
import { TButton } from "../../components/Table";

function Notifications() {
  const { rows, isLoading } = useSelector((state) => state.notifications);
  const dispatch = useDispatch();
  const history = useHistory();

  function handleClick(to: string, uid: string) {
    dispatch(readNotification(uid));
    history.push(to);
  }
  const columns = React.useMemo<Column<any>[]>(
    () => [
      {
        Header: () => <div style={{ paddingLeft: 10 }}>Notification</div>,
        style: {
          paddingLeft: 20,
          paddingBottom: 0
        },
        accessor: "title",
        Cell: ({ row }: any) => (
          <>
            <h6
              className={
                row.original.read_status === "read" ? "" : "text-primary"
              }
            >
              {row.original.title}
            </h6>
            <div dangerouslySetInnerHTML={{ __html: row.original.content }} />
          </>
        ),
      },
      {
        Header: "Date",
        id: 'date',
        accessor: (row) => formateDate(row.updated_at)
      },
      {
        Header: " ",
        className: "actions",
        accessor: (row) => {
          const { navigateTo } = getRedirectionObj(row);
          return (
            <TButton
              onClick={() => handleClick(navigateTo, row.notification_uid)}
            >
              View
            </TButton>
          );
        },
        getTrProps: (state: any, rowInfo: any, column: any) => {
          return {};
        },
      },
    ],
    []
  );

  return (
    <Table
      tableProps={{ hover: false }}
      columns={columns}
      data={rows}
      getRowProps={(row: any) => {
        return {
          style: {
            background:
              row.original.read_status === "read" ? "#fff" : "#f3ecff",
          },
        };
      }}
      extraConfig={{
        initialState: {
            sortBy: [
                {
                    id: "date",
                    desc: true,
                },
            ],
        },
    }}
    />
  );
}

export { Notifications };
export default Notifications;

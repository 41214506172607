import { string, object, SchemaOf, number, mixed, bool, boolean } from "yup";

export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const schemaObj = object({
  //checkedA: boolean(),
  country_id: string().when("checkedA", {
    is: true,
    then: string().required("Please fill out all fields or correct errors!"),
  }),
  vat_value: string().when("checkedA", {
    is: true,
    then: string()
      .min(1, "Invalid VAT format")
      .max(24, "Invalid VAT format")
      .required("Please fill out all fields or correct errors!"),
  }),
  checkedB: boolean().when("checkedA", {
    is: true,
    then: bool().oneOf([true], "Please accept disclaimer"),
  }),
});

export const defaultError = "Please fill out all fields or correct errors!";
export const validationSchema = object({
  name: string().required(defaultError),
  address1: string().required(defaultError),
  address2: mixed().notRequired(),
  zipcode: string().required(defaultError),
  city_name: string().required(defaultError),
  latitude: string().required(),
  longitude: string().required(),
  cover_image: string().required(),
  country: object({
    id: number().required(),
    name: string().required(),
  }),
  contact_first_name: string().required(defaultError),
  contact_last_name: string().required(defaultError),
  website: string().notRequired(),
  phone_no: string().matches(phoneRegExp, "Phone number is not valid"),
});

export const formStateDefault = {
  name: "",
  address1: "",
  address2: "",
  zipcode: "",
  city_name: "",
  latitude: "",
  longitude: "",
  country: {
    id: 0,
    name: "",
  },
  contact_first_name: "",
  contact_last_name: "",
  website: "",
  phone_no: "",
};

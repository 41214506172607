import Skeleton from "react-loading-skeleton";
const Loader = () => (
  <>
    <h1>
      <Skeleton />
    </h1>
    <Skeleton count={3} />
  </>
);
export default Loader;


import { FC, useRef, useState } from 'react';
import CIcon from '@coreui/icons-react';
import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle, CSpinner } from '@coreui/react';
import Cropper from 'cropperjs';

import './styles.scss';

export const ImageCropper: FC<Record<string, any>> = ({ data: [title], setImage, getValues, setValue }) => {
    const cover_image  = getValues('cover_image') || {};
    const inputRef = useRef<any>({});

    const [open, setOpen] = useState(false);
    const [file, setFile] = useState<any>(null);
    const [loading, setLoading] = useState(false);
    const [cropperInstance, setCropperInstance] = useState<any>(null)
    const [filePath, setFilePath] = useState<any[]>(['', cover_image]);


    const triggerChooseFile = () => inputRef?.current?.click();

    const initCropper = () => {
        setOpen(true);
        setLoading(true);
        setTimeout(() => {
            const image = document.getElementById('image') as HTMLImageElement;
            const cropper = new Cropper(image, {
                aspectRatio: 2.8,
                ready: () => {
                    setLoading(false);
                },
                dragMode: 'move'
            });
            setCropperInstance(cropper);
        }, 600);
    };

    const readURL = (file: any) => {
        if (!!file) {
            setFile(file);
            const reader = new FileReader();
            reader.onload = (e: any) => {
                setFilePath([e.target.result, cover_image]);
                initCropper();
            };
            reader.readAsDataURL(file);
        } else {
            triggerChooseFile();
        }
    };

    const fileHandler = () => {
        const { files } = inputRef?.current || {};
        readURL(files[0]);
    };

    const clearInput = ()=>{
        const input = document.getElementById('image-input') as HTMLInputElement;
        input.value = '';
    };

    const onSubmit = () => {
        cropperInstance?.getCroppedCanvas()?.toBlob((blob: any) => {
            blob.lastModifiedDate = new Date();
            file?.name && (blob.fileName = file.name);
            setValue('cover_image', URL.createObjectURL(blob),  { shouldDirty: true } );
            
            setFilePath([])
            setImage(blob);
            setOpen(false);
            clearInput();
        });
    };

    const handleClose = () => {
        setValue('cover_image', cover_image);
        clearInput();
        setOpen(false);
        setImage(null);
        setFilePath([]);
    };
    const isCoverImage = !!Object.keys(cover_image).length;
    return (
        <div>
            <div className={`image-thumb ${!isCoverImage && 'no-image'}`}>
                {isCoverImage && <img onClick={fileHandler} src={cover_image} alt="Studio cover image" /> }
                <input type="file" accept="image/*" ref={inputRef} onChange={fileHandler} id="image-input" />
                <div className="icon-container">
                    <CIcon icon="cil-image" />
                </div>
            </div>
            <CModal alignment="center" visible={open}>
                <CModalHeader onDismiss={handleClose}>
                    <CModalTitle>{title}</CModalTitle>
                </CModalHeader>
                <CModalBody>
                    <div>
                        <div className="image-cropper__content">
                            {loading && <CSpinner />}
                            <img className="cropper-hidden" id="image" src={filePath[0] || filePath[1]} />
                        </div>
                        <div className="image-cropper__footer">
                            <CButton onClick={onSubmit}>Done</CButton>
                        </div>
                    </div>
                </CModalBody>
            </CModal>
        </div>
    );

}

import moment from "moment";
import { forwardRef, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar"
import CustomToolbar from "./CustomToolbar";
import { getData, getEvents, getStatusColor } from "./functions";
import UpcomingCard from "./UpcomingCard";
import Tippy from '@tippyjs/react';
import { CModal, CSpinner } from "@coreui/react";
import { useDispatch, useSelector } from "../../../store";
import { updateDate, updtaeCurrentCalendarView } from "../../../slices/booking";
import MobileWeekTimeMobile from "./MobileWeekTimeMobile";
import { useMediaQuery } from "react-responsive";
import { isMobile } from "../../../components/AddToCalendar/helpers";

const localizer = momentLocalizer(moment)

const EventLabel = forwardRef((props: any, ref: any) => {
  const dispatch = useDispatch();
  const { event, data, view } = props;
  const [visible, setVisible] = useState(false)
  const onMonthEventClick = () => {
    if (view === 'month') {
      dispatch(updtaeCurrentCalendarView('day'))
      dispatch(updateDate(event.start))
    } else {
      setVisible(!visible)
    }
  }
  
  let statusLabel = event.titleLabels.status;
  const labelColorClass = getStatusColor(statusLabel);
  const individualData = getData(event.id, data);
  if (statusLabel === 'postponed_pending') statusLabel = 'new date pending';
  return (
    <>
      <div className={`${labelColorClass} event-label`} ref={ref} onClick={onMonthEventClick}>
        <span className="status">{statusLabel}</span>
        {
          individualData.duration >=120 && <span className="text">{event.titleLabels.text}</span>
        }
      </div>
      <CModal visible={visible} onDismiss={() => setVisible(false)} alignment="center" className='upcoming-card-modal'>
        <UpcomingCard data={individualData} setVisible={setVisible} />
      </CModal>
    </>
  )
})

function Event(props: any) {
  const { data, event, ...rest } = props;
  const isTouchDevice: boolean = isMobile()
  return (
    !isTouchDevice ?
      <Tippy
        content={
          <UpcomingCard data={getData(event.id, data)} />
        }
        placement="auto"
        arrow={true}
        interactive={true}
        appendTo={() => document.body}
      >
        <EventLabel event={event} data={data} {...rest} />
      </Tippy> : <EventLabel event={event} data={data} {...rest} />
  );
}

const BookingCalendar = (props: any) => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' })
  const {
    artistFilter,
    bookingData,
    currentCalendarView,
    artistsList,
    calDate,
    isMobileWeek
  } = useSelector((state) => state.bookings);
  const {loading} = props;
  const data = artistFilter.length ? artistFilter : bookingData;
  const now = new Date();
  const events = getEvents(data);
  let formats = {
    dayFormat: (date: Date) => {
      return moment(date).format('dddd, D MMM.')
    },
    dateFormat: (date: Date) => {
      return moment(date).format('D')
    },
    timeGutterFormat: 'HH:mm',
  }
  return (
    <>
      {loading ? <CSpinner /> : 
      <Calendar
        events={events}
        step={30}
        localizer={localizer}
        view={currentCalendarView}
        defaultDate={now}
        views={['day', 'week', 'month']}
        formats={formats}
        components={{
          toolbar: CustomToolbar,
          event: (props: any) => <Event {...props} data={data} view={currentCalendarView} />
        }}
        onView={(view: string) => dispatch(updtaeCurrentCalendarView(view))}
        onNavigate={(date: Date) => dispatch(updateDate(date))}
        date={new Date(calDate)}
        resources={
          isMobileWeek || currentCalendarView === 'week' || artistFilter.length ?
            null : artistsList}
        resourceIdAccessor="resourceId"
        resourceTitleAccessor={(el: any) => (
          <div className="resource-header">
            <img src={el.img} alt="" />
            <span className="name">{el.firstName} <span>{el.lastName}</span></span>
          </div>
        )}
      />}
    </>
  )
}

export default BookingCalendar;
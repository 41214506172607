import React from "react";
import { setPageName } from "../slices/page";
import { useDispatch } from "../store";

interface Props {
  layout?: any;
  component: any;
  // All other props
  [x: string]: any;
}

export default function WithLayout({
  component: Component,
  layout,
  guard,
  title,
  pageName,
  ...rest
}: Props): JSX.Element {
  const Guard = guard || React.Fragment;
  const Layout = layout || React.Fragment;
  const dispatch = useDispatch();
  dispatch(setPageName(pageName))
  return (
    <React.Fragment>
      <Guard>
        <Layout>
          <Component {...rest} />
        </Layout>
      </Guard>
    </React.Fragment>
  );
}

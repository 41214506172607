import styled from 'styled-components';
import { responsiveScale } from '../../utils/constants'

export const FormHeader = styled.div`
	height: 52px;
	background: #6B51A9;
    padding: 0 20px;
    .info-btn {
        font-weight: 500;
        background-color: #FF6A70;
        color: #fff;
        border-radius: 3px;
        font-size: 12px;
        padding: 0 8px;
        height: 24px;
        overflow: hidden;
        display: flex;
        align-items: center;
        text-decoration: none;
    }
    & {
        .form-header-inner {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            height: 100%;
            @media(max-width: 767px) {
                justify-content: space-between;
            }
            img {
                display: none;
                @media(max-width: 767px) {
                    display: block;
                    max-width: 72px;
                }
            }
        }
    }
`;

export const FormContainer = styled.div`
    background-color: #8C62DB;
    min-height: calc(100% - 52px);
    display: flex;
    @media(min-width: 1024px) {
        align-items: center;
        padding-left: 223px;
        padding-left: ${responsiveScale(223, 1920)};
    }
    @media(max-width: 1023px) {
        justify-content: center;
    }
    @media(min-width: 768px) and (max-width: 1023px) {
        align-items: center;
    }
    @media(max-width: 767px) {
        align-items: flex-end;
        padding-top: 50px;
    }
`;


const StyledCard = styled.div`
    position: relative;
    flex: 0 0 440px;
  	max-width: 100%;
	padding: 65px 65px;
	border-radius: 40px;
	box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.25);
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    @media(min-width: 768px) {
        min-height: 580px;
    }
    @media(max-width: 767px) {
        min-height: 400px;
        border-radius: 30px 30px 0px 0px;
        padding: 25px 35px 45px;
    }
`;



export const FormButtonWrap:any = styled.div`
    position: relative;
    text-align: center;
    @media(min-width: 768px) {
        margin-top: 10px;
    }
    & {
        .MuiCircularProgress-colorPrimary {
            position: absolute;
            right: 20px;
            top: 0;
            bottom: 0;
            margin: auto;
            color: #fff;
        }
    }
`;

export { 
	StyledCard as Card, 
	FormButtonWrap as FormBtn
};

import mixpanel from 'mixpanel-browser';

const MIXPANEL_TOKEN = '7de425a6bec744561662a54d5b40c133';
mixpanel.init(MIXPANEL_TOKEN);

let env_check = process.env.NODE_ENV === 'production';

let actions = {
  track: (name: string, props: {}) => {
    if (env_check) mixpanel.track(name, props);
  }
};

export let Mixpanel = actions;
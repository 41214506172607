import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCardTitle,
  CCol,
  CFormText,
  CRow,
  CSpinner,
} from "@coreui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm, useFormState } from "react-hook-form";
import { Prompt, useHistory } from "react-router-dom";
import { Form } from "../../../components";
import { ImageCropper } from "../../../components/ImageCropper";
import { useAxios } from "../../../hooks";
import { updateActiveStatus, updateUser } from "../../../slices/auth";
import { useDispatch, useSelector } from "../../../store";
import { API_END_POINTS, ROUTING_URLS } from "../../../utils/constants";

import {
  validationSchema,
  formStateDefault,
} from "./config";

export const AccountForm: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [coverImage, setCoverImage] = useState("");
  const [city, setCity] = useState("");

  const [formState, setFormState] = useState<any>(formStateDefault);

  const [studioId, setStudioId] = useState("");

  const getInputProps: any = (
    name: string,
    label: string,
    helperText: string
  ) => {
    return {
      name,
      label,
      control: methods.control,
      getValues: methods.getValues,
      error: errors ? !!errors[name] : false,
      helperText: errors && errors[name] ? errors[name]?.message : helperText,
    };
  };
  const methods = useForm<any>({
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
    defaultValues: "",
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    getValues,
    control,
  } = methods;

  const { isDirty } = useFormState({ control });

  useEffect(() => {
    reset({ ...getValues(), ...formState });
  }, [formState]);

  const [{ data: res, loading, error }, fetchStudioDetails] = useAxios(
    {
      url: API_END_POINTS.getStudioDetails,
      method: "GET",
    },
    { manual: true }
  );
  useEffect(() => {
    fetchStudioDetails();
  }, []);

  useEffect(() => {
    if (!!res) {
      const { data } = res;
      const {
        active_status,
        created_profile,
        email,
        id,
        phone_code,
        cover_image,
        ...rest
      } = data;
      setStudioId(id);
      setFormState({
        ...rest,
        ...(!!cover_image && {
          cover_image: `${cover_image}?${new Date().getTime()}`,
        }),
      });
      setCity(rest.city_name);
    }
    !!error && console.log(error);
  }, [res, error]);

  const [
    { data: updateRes, loading: updateLoading, error: updateErr },
    updateStudioDetails,
  ] = useAxios(
    {
      url: API_END_POINTS.getStudioDetails,
      method: "PUT",
    },
    { manual: true }
  );

  useEffect(() => {
    if (!!updateRes) {
      dispatch(updateActiveStatus(true));
      history.push(ROUTING_URLS.artists);
    }
  }, [updateRes, updateErr]);

  const onSubmit = (value: any) => {
    const {
      country: { id },
      country_id,
      cover_image,
      ...rest
    } = value;

    setFormState(value);
    const payloadObject: any = {
      studio_uid: studioId,
      country: id,
      ...(!!coverImage && { cover_image: coverImage }),
      ...rest,
    };

    const data = new FormData();
    Object.keys(payloadObject).forEach((key: string) =>
      data.append(key, payloadObject[key])
    );

    updateStudioDetails({ data });
    dispatch(
      updateUser({
        name: payloadObject.name,
        contact_first_name: payloadObject.contact_first_name,
        contact_last_name: payloadObject.contact_last_name,
        city: payloadObject.city_name,
        address_line1: payloadObject.address1,
        address_line2: payloadObject.address2,
      })
    );
  };

  return !!loading ? (
    <CSpinner />
  ) : (
    <>
      <Prompt when={isDirty} message="" />
      <CCard>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CCardHeader>
            Registration
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol sm="6">
                <Form.Input
                  gridtype="col"
                  {...getInputProps("name", "Studio name*")}
                />
                <div className="mb-4 pb-3 form-wrap">
                  <CRow>
                    <CCol lg={4}>
                      <label htmlFor="cover_image">Studio cover image</label>
                    </CCol>
                    <CCol lg={8}>
                      <ImageCropper
                        data={["Crop image"]}
                        setImage={setCoverImage}
                        getValues={getValues}
                        setValue={setValue}
                      />
                      <CFormText className="neutral-helper-text coverimage-text">
                        This image is visible for every customer who gets an offer from your artists.
                      </CFormText>
                      {
                        errors?.cover_image && !coverImage && 
                        <CFormText className='error'>Cover image is a required field</CFormText>
                      }
                    </CCol>
                  </CRow>
                </div>
                <Form.Input
                  gridtype="col"
                  {...getInputProps("address1", "Address line 1*")}
                />
                <Form.Input
                  gridtype="col"
                  {...getInputProps("address2", "Address line 2")}
                />
                <Form.Input
                  gridtype="col"
                  {...getInputProps("zipcode", "Zip code*")}
                />
                <Form.LocationSearchInput
                  setValue={setValue}
                  initialvalue={city}
                  {...getInputProps("city_name", "City*")}
                />
              </CCol>
              <CCol sm="6">
                <Form.Select
                  {...getInputProps("country", "Country*")}
                  disabled={true}
                  gridtype="col"
                ></Form.Select>
                <Form.Input
                  gridtype="col"
                  {...getInputProps(
                    "contact_first_name",
                    "Studio Owner First Name*"
                  )}
                />
                <Form.Input
                  gridtype="col"
                  {...getInputProps(
                    "contact_last_name",
                    "Studio Owner Last Name*"
                  )}
                />
                <Form.Input
                  gridtype="col"
                  {...getInputProps("website", "Studio’s Website")}
                />
                <Form.Input
                  gridtype="col"
                  {...getInputProps("phone_no", "Phone number*")}
                />
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter>
            <CButton type="submit" color="info" variant="outline">
              Submit
              {updateLoading && (
                <CSpinner className="ms-2" color="light" size="sm" />
              )}
            </CButton>
          </CCardFooter>
        </form>
      </CCard>
    </>
  );
};

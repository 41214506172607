import axios from "axios";
import { apiURL } from "./config";

const axiosInstancePublic = axios.create({
  baseURL: apiURL,
});

axiosInstancePublic.interceptors.response.use(
  (response: any) => {
    return response;
  },
  (error: any) => {
    const { response } = error;
    if (!response) return { status: 500, message: "No response from server" };
    return Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    );
  }
);

export { axiosInstancePublic };

export default axiosInstancePublic;

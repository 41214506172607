import React from "react";
import { CAlert } from "@coreui/react";
import CIcon from "@coreui/icons-react";
const ErrorNotice = () => (
  <CAlert color="danger" className="m-4 d-flex">
    <CIcon icon="cil-warning" size="lg" className="mx-4" />
    <div>Something went wrong!</div>
  </CAlert>
);
export default ErrorNotice;

import React, { useState } from "react";
import { Controller } from "react-hook-form";

import { CFormLabel, CFormControl, CFormText } from "@coreui/react";

interface Props {
  [key: string]: any;
}

const PasswordToggle = ({toggle, toggleVal}: any) => {
  return(
      <span 
        className={`password-toggle ${!toggleVal ? 'hide' : 'show'}`}
        onClick={() => toggle(!toggleVal)}
      ></span>
  )
}

export function Input(props: Props) {
  const {
    name,
    control,
    defaultValue,
    getValues,
    label,
    error,
    helperText,
    gridtype,
    type,
    helperTextClassName,
    ...rest
  } = props;
  const [passwordVisible, setPasswordVisible] = useState(false)
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field }) => {
        const { value, ...fieldRest } = field;
        if(gridtype === "col") {
          return (
            <div className="mb-3 pb-3 form-wrap">
              <div className="row">
                <div className="col-lg-4">
                  {label && <CFormLabel>{label}</CFormLabel>}
                </div>
                <div className="col-lg-8">
                  <CFormControl {...fieldRest} {...rest} value={value || ""} type={passwordVisible ? 'text': type } />
                  {type === 'password' && <PasswordToggle toggle={setPasswordVisible} toggleVal={passwordVisible} />}
                  {helperText && <CFormText className={!error && (helperTextClassName || '')}>{helperText}</CFormText>}
                </div>
              </div>
            </div>
          )
        } else {
          return (
            <div className="mb-4 pb-3 form-wrap">
              {label && <CFormLabel>{label}</CFormLabel>}
                <CFormControl {...fieldRest} {...rest} value={value || ""} type={passwordVisible ? 'text': type } />
                {type === 'password' && <PasswordToggle toggle={setPasswordVisible} toggleVal={passwordVisible} />}
                {helperText && <CFormText>{helperText}</CFormText>}
            </div>
          );
        }
      }}
    />
  );
}

import { Select } from "antd";
import { useEffect, useState } from "react";
import { updateArtistFilter } from "../../../slices/booking";
import { useDispatch, useSelector } from "../../../store";

import { ReactComponent as ChevronIcon } from './../../../icons/down-arrow.svg'

const ArtistDropDown = ({ view }: any) => {
    const dispatch = useDispatch();
    const { artistsList, activeArtistFilter, currentCalendarView, isMobileWeek } = useSelector((state) => state.bookings);
    const handleChange = (id: string) => {
        dispatch(updateArtistFilter(id))
    }
    useEffect(() => {
        if (artistsList.length &&
            currentCalendarView !== 'day' &&
            activeArtistFilter === 'all' &&
            view !== 'list') {
            dispatch(updateArtistFilter(artistsList[0]?.resourceId))
        }
    })
    return (
        <Select
            className="artist-select"
            value={activeArtistFilter}
            defaultValue={activeArtistFilter}
            onChange={handleChange}
            dropdownClassName='artist-select-dropdown'
            suffixIcon={<ChevronIcon />}
            getPopupContainer={(triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement}
        >
            {((currentCalendarView === 'day' &&
                !isMobileWeek) || (view === 'list')) &&
                (<Select.Option value="all">All Artists</Select.Option>)}
            {
                artistsList.map((i: any) => (
                    <Select.Option value={i.resourceId} key={i.resourceId}>{i.name}</Select.Option>
                ))
            }
        </Select>
    ) 
}

export default ArtistDropDown;
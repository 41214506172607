import { CContainer } from "@coreui/react";
import React from "react";
import AppSidebar from "./AppSidebar";
import AppHeader from "./AppHeader";
import { useAuth } from "../../hooks";
import { ROUTING_URLS } from "../../utils/constants";
import { useLocation } from "react-router";
import { useSelector } from "../../store";

const infoCardData = {
  text: 'Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. Lorem ipsum',
  url: ROUTING_URLS.account
}

const DefaultLayout: React.FC<any> = ({ children }): JSX.Element => {
  const {pathname}  = useLocation<any>()
  const { active_status } = useAuth();
  const {pageName} = useSelector((state: any) => state.page);
  return (
  <div>
    <AppSidebar />
    <div className="wrapper d-flex flex-column min-vh-100">
      <AppHeader />
      <div className="body flex-grow-1 px-md-3  mb-5">
        <CContainer fluid>
          <div className="page-title-xs">{pageName}</div>
          <div className="page-content-wrap">
            {children}
          </div>
        </CContainer>
      </div>
    </div>
  </div>
)};
export default DefaultLayout;

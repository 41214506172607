import { CBadge } from "@coreui/react";
import { Link } from "react-router-dom";

function Badge({data}: any) {
    return (
        <CBadge color="danger">
            <p>{data.text}</p>
            <Link to={data.url}>Go to registration</Link>
        </CBadge>
    )
}

export default Badge

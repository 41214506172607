import { CButton } from "@coreui/react";
import React from "react";

export const TButton = ({
  label,
  children,
  className,
  ...rest
}: any): JSX.Element => (
  <CButton
    color="primary"
    variant="outline"
    style={{ height: 33 }}
    className={`${className || ""}`}
    size="sm"
    {...rest}
  >
    {children}
  </CButton>
);

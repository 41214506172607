import { useState } from "react";
import { FormBody, FormHead, FormHeader, FormSubHead } from "../styles"
import ForgotPasswordForm from "./Form"

function ForgotPassword() {
    const [frgtUsrSuccess, setFrgtUsrSuccess] = useState(false);
    const [frgtUsrError, setFrgtUsrError] = useState('');
    const [userEmail, setUserEmail] = useState('');
    return (
        <>
            <FormHeader>
                <img src="/images/Logo.svg" alt="" />
                <FormHead>
                    {!frgtUsrSuccess ?
                        'Forgot your password?'
                        :
                        'Please check your mailbox.'
                    }
                </FormHead>
                <FormSubHead className="text-wrap-sm">
                    {!frgtUsrSuccess
                        ? "If we have your address in our database, we’ll send you a link to reset your password."
                        : (
                            <>
                                We’ve sent you a link to &nbsp;
                                <a href="">[{userEmail}]</a>
                                . If you didn’t receive anything, check your address and try again.
                            </>
                        )
                    }

                </FormSubHead>
            </FormHeader>
            <FormBody>
                    <ForgotPasswordForm 
                        setFrgtUsrSuccess={setFrgtUsrSuccess} 
                        frgtUsrError={frgtUsrError}
                        setFrgtUsrError={setFrgtUsrError}
                        setUserEmail={setUserEmail}
                    ></ForgotPasswordForm>
            </FormBody>
    </>
    )
}

export default ForgotPassword

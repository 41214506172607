import { CContainer } from "@coreui/react";
import React from "react";
import { useLocation } from "react-router-dom";
import AppHeader from "./AppHeader";
import { routesMapping as routes } from "../../Routes/config";

const DefaultLayout: React.FC<any> = ({ children }): JSX.Element => {
  const currentLocation = useLocation().pathname;

  const getRouteName = (pathname: any, routes: any) => {
    const currentRoute = routes.find((route: any) => route.path === pathname);
    return currentRoute?.name;
  };

  const getBreadcrumbs = (location: any) => {
    const breadcrumbs: any = [];
    location
      .split("/")
      .reduce((prev: any, curr: any, index: any, array: any) => {
        const currentPathname = `${prev}/${curr}`;
        const label = getRouteName(currentPathname, routes);
        if (label)
          breadcrumbs.push({
            pathname: currentPathname,
            name: label,
            active: index + 1 === array.length ? true : false,
          });
        return currentPathname;
      });
    return breadcrumbs;
  };

  const breadcrumbs = getBreadcrumbs(currentLocation);

  return (
    <div>
      <div className="wrapper d-flex flex-column min-vh-100 bg-light ">
        <AppHeader />
        <div className="body flex-grow-1 px-md-3  mb-5 container-md ">
          <CContainer fluid>
            <div className="bg-white p-3" ><h4>{breadcrumbs[0]?.name}</h4></div>
            {children}
          </CContainer>
        </div>
      </div>
    </div>
  );
};
export default DefaultLayout;

import React, { Fragment, useEffect, useState } from "react";
import { Column } from "react-table";
import { CAlert, CAvatar, CBadge, CButton, CTooltip } from "@coreui/react";

import { ContentModal, Table, ViewNavBar } from "../../components";
import { useAxios } from "../../hooks";
import { API_END_POINTS, EArtistTypeLabels } from "../../utils/constants";
import { EArtistStatusLabel, EArtistTypes } from "../../utils/enums";
import { RowActions } from "./components/RowActions";
import { artistStatusIcon, badgeBgGen, checkOnlyPeriodic, getArtistImage, getArtistLabel, getArtistName, getArtistRelLabel, getStatusLabel } from "./functions";
import Loader from "./components/Loader";
import CIcon from "@coreui/icons-react";
import ArtstDetails from "./components/ArtstDetails";
import { useDispatch } from "../../store";
import { updateIsOnlyPeriodic } from "../../slices/auth";
import { ArtistListDetail } from "./components/ArtistListDetail";
import ArtistCard from "./components/ArtistCard";
import { useMediaQuery } from "react-responsive";
import { Mixpanel } from "../../utils/mixpanel";

export const sahreReqInfo = "The new share split will only be applied to the upcoming offers. For the already agreed appointments, the old split will apply.";

export interface IArtistCardData {
  id: string;
  first_name: string;
  last_name: string;
  artist_name: string;
  active_status: boolean;
  profile_active: boolean;
  status: string;
  studio_share: {
    share: string | number;
    new_share: string | number;
    status: string;
  };
  studio: { id: string; name: string };
  artist_type: string;
  artist_image: string;
  [propName: string]: any;
}

function Artists() {
  const [view, setView] = useState('card')
  const dispatch = useDispatch();
  const [{ data, error, loading }, fecthList] = useAxios(
    API_END_POINTS.getStudioArtists,
    { manual: true }
  );
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' })
  const columns = React.useMemo<Column<any>[]>(
    () => [
      {
        Header: "Artist",
        sortType: (rowA: any, rowB: any, id: any, desc: any) => {
          const refValA = getArtistName(rowA.original);
          const refValB = getArtistName(rowB.original);
          if (refValA > refValB) return -1;
          if (refValB > refValA) return 1;
          return 0;
        },
        accessor: 'artist',
        className: 'artist-info',
        Cell: ({ row }: any) => {
          const { first_name, last_name } = row.original;
          const displayName = getArtistName(row.original);
          const displayImage = getArtistImage(row.original);
          return (
            <div className="position-relative">
              <div className="d-flex align-items-center">
                <CAvatar size={"sm"} src={displayImage} className="me-2 artist-avatar" />
                <span>{first_name}</span> &nbsp;
                <span className="hide-xs">{last_name}</span>
              </div>
              {/* <ContentModal
                buttonElm={
                }
                size="lg"
              >
                <ArtistListDetail data={row.original} fecthList={fecthList} />
              </ContentModal> */}
            </div>
          )
        }
      },
      {
        Header: "Relationship",
        className: 'hide-xs',
        accessor: (row) => {
          const relLabel = getArtistRelLabel(row)
          const artistLabel = getArtistLabel(relLabel)
          return (
            <div className="d-flex align-items-center">
              <CTooltip content={artistLabel} placement="right">
                <CBadge className={`text-capitalize rel-label me-2 ${relLabel}`}>{relLabel}</CBadge>
              </CTooltip>
            </div>
          );
        },
      },
      {
        Header: "Studio share",
        className: 'hide-xs',
        sortType: (rowA: any, rowB: any, id: any, desc: any) => {
          const refValA =
            rowA.original.artist_type === EArtistTypes.Freelancer
              ? parseInt(rowA.original.studio_share.share) || 0
              : -1;
          const refValB =
            rowB.original.artist_type === EArtistTypes.Freelancer
              ? parseInt(rowB.original.studio_share.share) || 0
              : -1;
          if (refValA > refValB) return -1;
          if (refValB > refValA) return 1;
          return 0;
        },
        accessor: (row: any) => {
          const isFreelance = row.artist_type === EArtistTypes.Freelancer;
          const isPeriodic = row.artist_type === EArtistTypes.Freelancer && row.artist_is_periodic;
          const isLateSplit = row.artist_is_late_split;
          if (!isFreelance) return (<span>100%</span>);
          const studioShare = parseInt(row.studio_share.share) || 0;
          const studioNewShare = parseInt(row.studio_share.new_share) || 0;
          const studioShareStatus = row.studio_share.status;
          if (isPeriodic || isLateSplit) return (<span className="share-label">0</span>);
          if (studioShareStatus === 'pending') {
            if (studioShare === studioNewShare) {
              return (<span className="share-label">{!!studioShare ? studioShare + '%' : 0}</span>)
            } else {
              return (
                <div className="share-cell">
                  <span className="share-label share-status-current">
                    <span className="share-text">
                      {!!studioShare ? studioShare + '%' : 0}
                    </span>
                    <span className="share-status-badge">
                      <span className="status-icon">
                        <img src="images/tick.svg" alt="" />
                      </span>
                      <span className="status-text">Now</span>
                    </span>
                  </span>
                  <span className="share-label me-2 share-status-pending">
                    <span className="share-text">
                      {!!studioNewShare ? studioNewShare + '%' : 0}
                    </span>
                    <span className="share-status-badge">
                      <CTooltip content={sahreReqInfo} placement="top">
                        <span className="status-icon">
                          <img src="images/info-icon.svg" alt="" />
                        </span>
                      </CTooltip>
                      <span className="status-text">Pending</span>
                    </span>
                  </span>
                  {/* <CTooltip content={sahreReqInfo} placement="top">
                    <CIcon name="cil-info" />
                  </CTooltip> */}
                </div>
              )
            }
          } else {
            return (
              <>
                <span className="share-label">{!!studioShare ? studioShare + '%' : 0}</span>
              </>
            )
          }
        },
      },
      {
        Header: "Artist share",
        className: 'hide-xs',
        sortType: (rowA: any, rowB: any, id: any, desc: any) => {
          const refValA =
            rowA.original.artist_type === EArtistTypes.Freelancer
              ? 100 - parseInt(rowA.original.studio_share.share) || 0
              : -1;
          const refValB =
            rowB.original.artist_type === EArtistTypes.Freelancer
              ? 100 - parseInt(rowB.original.studio_share.share) || 0
              : -1;
          if (refValA > refValB) return -1;
          if (refValB > refValA) return 1;
          return 0;
        },
        accessor: (row: any) => {
          const isFreelance = row.artist_type === EArtistTypes.Freelancer;
          const isPeriodic = row.artist_type === EArtistTypes.Freelancer && row.artist_is_periodic;
          const isLateSplit = row.artist_is_late_split;
          if (!isFreelance) return (<span>0%</span>);
          const artistShare = 100 - parseInt(row.studio_share.share) || 0;
          const artistNewShare = 100 - parseInt(row.studio_share.new_share) || 0;
          const artistShareStatus = row.studio_share.status;
          if (isPeriodic || isLateSplit) return (<span className="share-label">100%</span>);
          if (artistShareStatus === 'pending') {
            if (artistShare === artistNewShare) {
              return (<span className="share-label">{!!artistShare ? artistShare + '%' : 0}</span>)
            } else {
              return (
                <div className="share-cell">
                  <span className="share-label share-status-current">
                    <span className="share-text">
                      {!!artistShare ? artistShare + '%' : 0}
                    </span>
                    <span className="share-status-badge">
                      <span className="status-icon">
                        <img src="images/tick.svg" alt="" />
                      </span>
                      <span className="status-text">Now</span>
                    </span>
                  </span>
                  <span className="share-label me-2 share-status-pending">
                    <span className="share-text">
                      {!!artistNewShare ? artistNewShare + '%' : 0}
                    </span>
                    <span className="share-status-badge">
                      <CTooltip content={sahreReqInfo} placement="top">
                        <span className="status-icon">
                          <img src="images/info-icon.svg" alt="" />
                        </span>
                      </CTooltip>
                      <span className="status-text">Pending</span>
                    </span>
                  </span>
                  {/* <CTooltip content={sahreReqInfo} placement="top">
                    <CIcon name="cil-info" />
                  </CTooltip> */}
                </div>
              )
            }
          } else {
            return (
              <>
                <span className="share-label">{!!artistShare ? artistShare + '%' : 0}</span>
              </>
            )
          }
        },
      },
      {
        Header: "Status",
        className: 'hide-xs',
        accessor: (row) => {
          const status = getStatusLabel(row);
          const [badgeBg, badgeTextColor] = badgeBgGen(status);
          return (
            <CBadge
              textColor={badgeTextColor}
              color={badgeBg}
              style={{ ['--cui-secondary' as any]: (status === EArtistStatusLabel.Inactive || status === EArtistStatusLabel.Rejected) && '#ced2d8' }}
            >{status}</CBadge>
          )
        },
      },
      {
        Header: "",
        accessor: "id",
        className: "actions hide-xs",
        Cell: ({ row }: any) => (
          <>
            <RowActions row={row.original} fecthList={fecthList} />
          </>
        ),
      },
      {
        Header: "",
        accessor: "xs-action",
        className: "xs-action-none show-xs-table-cell text-end artist-action",
        Cell: ({ row }: any) => {
          const { original }: IArtistCardData = row;
          const status = getStatusLabel(original);
          const [badgeBg, badgeTextColor] = badgeBgGen(status);
          const statusLabel = status === EArtistStatusLabel.Pending ? 'Take Action' : status;
          const icon = artistStatusIcon(status);
          return (
            <ContentModal
              buttonElm={
                <div>
                  {status === EArtistStatusLabel.Pending ? (
                    <CButton color="danger" style={{ color: 'white' }}>{statusLabel}</CButton>
                  ) : (
                    <CBadge
                      textColor={badgeTextColor}
                      color={badgeBg}
                      style={{ ['--cui-secondary' as any]: (status === EArtistStatusLabel.Inactive || status === EArtistStatusLabel.Rejected) && '#ced2d8' }}
                    >{statusLabel}</CBadge>
                  )}
                  <CIcon
                    className={`ms-3`}
                    icon={icon}
                  />
                </div>
              }
              size="lg"
            >
              <ArtistListDetail data={original} fecthList={fecthList} />
            </ContentModal>
          )
        },
      },
    ],
    []
  );

  useEffect(() => {
    fecthList();
  }, []);

  useEffect(() => {
    setView('card')
  }, [isMobile])

  useEffect(() => {
    if (!!data) {
      const { data: artistList } = data;
      const isOnlyperiodic = checkOnlyPeriodic(artistList);
      dispatch(updateIsOnlyPeriodic(isOnlyperiodic))

      Mixpanel.track('Studios open Artist requests', {
        'Studio Name': artistList[0].studio?.name,
        'Studio Email': artistList[0].studio?.email,
      });
    }
  }, [data, error])

  const artistList: Array<IArtistCardData> = data?.data || [];

  if (loading && !artistList.length) return <Loader className="position-adj" />;

  return (
    <>
      {!isMobile &&
        <ViewNavBar labelOne="card" labelTwo="list" view={view} setView={setView} />
      }
      {
        view === 'list' ?
          <Table
            columns={columns}
            data={artistList}
            error={!!error}
          /> :
          <div className="artist-card-wrap">
            {
              !!data && (
                (artistList.length) ?
                  artistList.map((item: IArtistCardData) => {
                    return (
                      <ArtistCard
                        artist={item}
                        key={item.id}
                        fecthList={fecthList}
                      />
                    )
                  }) :
                  <CAlert color="info" className="d-flex" >
                    <CIcon icon="cil-bell" size="lg" className="mx-4" />
                    <div>No data available</div>
                  </CAlert>)
            }
          </div>
      }
    </>
  );
}

export { Artists };
export default Artists;

import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCardTitle,
  CCol,
  CRow,
  CSpinner,
} from "@coreui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm, useFormState } from "react-hook-form";
import { Prompt, useLocation, useHistory } from "react-router-dom";
import { boolean } from "yup";
import { useVatData, schemaObj } from "../../../components/Form/useVatData";
import { VatFields } from "../../../components/Form/VatField";
import { useAxios } from "../../../hooks";
import { API_END_POINTS, ROUTING_URLS } from "../../../utils/constants";
import { EArtistTypes } from "../../../utils/enums";
import { EToastType, showToast } from "../../../utils/toast";

export const VatDetailsForm = () => {
  const {search} = useLocation();
  const urlQuery = new URLSearchParams(search);
  const history = useHistory();
  const [vatDetailsRequired, setVatDetailsRequired] = useState(false);
  const [vatFormData, patchFormData, loading] = useVatData();
  const [
    {
      data: artistData,
      loading: getStudioArtistsLoading,
      error: getStudioArtistError,
    },
    fetchStudioArtists,
  ] = useAxios(API_END_POINTS.getStudioArtists, {
    manual: true
  });
  const [
    { data: vatData, error: vatError, loading: updateLoading },
    triggerVatDetails
  ] = useAxios(
    {
      url: API_END_POINTS.vatDetails,
      method: "POST",
    },
    { manual: true }
  );
  useEffect(() => {
    fetchStudioArtists();
  }, [])
  useEffect(() => {
    async function func() {
      if (!!vatData && !vatError) {
        await patchFormData(vatData);
        showToast("VAT details successfully updated", EToastType.success);
        !!urlQuery.get('redirect') && history.push(ROUTING_URLS.artists);
      }
      if (!!vatError) {
        const msg = Object.entries(vatError)[0][1];
        showToast(msg, EToastType.error);
      }
    }
    func();
  }, [vatData, vatError]);

  const onSubmit = (value: any) => {
    const { vat_value, country: {id} } = value;
    triggerVatDetails({
      data: {
        is_tax_payee: value.checkedA,
        vat_value: value.checkedA ? vat_value : null,
        country_id: id,
      },
    });
  };
  const schema = schemaObj.shape({
    ...(!!vatDetailsRequired && {
      checkedA: boolean()
        .notRequired()
    }),
  });

  const methods = useForm<any>({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: "",
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    reset,
    getValues,
    control,
  } = methods;
  const { isDirty } = useFormState({ control });

  
  useEffect(() => {
    reset(vatFormData);
  }, [vatFormData]);

  const checkVATDetailsRequired = () => {
    const { data: artistList } = artistData || {};
    if (artistList?.length) {
      const filterFn = (res: Record<string, any>) => {
        const { active_status, artist_type, artist_is_periodic } = res;
        const isFreelancer = artist_type === EArtistTypes.Freelancer;
        const isPeriodicalFreelancer = !!isFreelancer && !!artist_is_periodic;
        return !!active_status && !isPeriodicalFreelancer;
      };
      const nonPeriodicalArtists = artistList.filter(filterFn);

      if (!!nonPeriodicalArtists.length) {
        setVatDetailsRequired(true);
      }
    }
  };

  const getInputProps: any = (
    name: string,
    label: string,
    helperText: string
  ) => {
    return {
      name,
      label,
      control: methods.control,
      getValues: methods.getValues,
      error: errors ? !!errors[name] : false,
      helperText: errors && errors[name] ? errors[name]?.message : helperText,
    };
  };

  useEffect(() => {
    checkVATDetailsRequired();
  }, [artistData]);

  const handleReset = () => {
    reset(vatFormData);
  };

  return !!loading ? (
    <CSpinner />
  ) : (
    <>
      <Prompt when={isDirty} message="" />
      <CCard>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CCardHeader>
            VAT Details
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol sm="6">
                <VatFields
                  isDirty={isDirty}
                  setValue={setValue}
                  watch={watch}
                  register={register}
                  getValues={getValues}
                  getInputProps={getInputProps}
                  defaultVal={vatFormData}
                  reset={reset}
                ></VatFields>
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter>
            <CButton type="submit" color="info" variant="outline" disabled={!!vatFormData.vat_studio_uid && !isDirty}>
              Save Changes
              {updateLoading && (
                <CSpinner className="ms-2" color="light" size="sm" />
              )}
            </CButton>
            {isDirty && (
              <CButton
                className="mx-3"
                color="dark"
                variant="outline"
                onClick={handleReset}
              >
                Reset All
              </CButton>
            )}
          </CCardFooter>
        </form>
      </CCard>
    </>
  );
};

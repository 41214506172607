import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCardTitle,
  CCol,
  CRow,
  CSpinner,
} from "@coreui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { string, object } from "yup";
import { useForm, useFormState } from "react-hook-form";
import Form from "../../../components/Form";
import { API_END_POINTS, onlySpaceCheck } from "../../../utils/constants";
import { useEffect, useState } from "react";
import { ConnectAccount } from "./ConnectAccount";
import { Prompt, useLocation } from "react-router";
import { useAxios } from "../../../hooks";
import { EToastType, showToast } from "../../../utils/toast";

function BankDetailsForm() {
  const [country, setCountry] = useState<{ id: number; country: string }>();
  let location = useLocation();
  const [ibanPlaceholder, setIbanPlaceHolder] = useState("");
  const validationSchema = object().shape({
    account_number: string()
      .matches(onlySpaceCheck.match, onlySpaceCheck.text)
      .required("Please fill out all fields or correct errors!"),
    bank_name: string()
      .matches(onlySpaceCheck.match, onlySpaceCheck.text)
      .required("Please fill out all fields or correct errors!")
  });
  const [bankdetails, setBankdetails] = useState({
    account_number: "",
    bank_name: ""
  });
  const methods = useForm<any>({
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
    defaultValues: "",
  });

  const {
    handleSubmit,
    formState: { errors },
    reset,
    register,
    setValue,
    watch,
    control,
  } = methods;
  const { isDirty, dirtyFields } = useFormState({ control });
  const dirtyFieldsCount = Object.keys(dirtyFields).length;
  const [{ data: response, loading, error }, fetchBankDetails] = useAxios(
    API_END_POINTS.updateBankDetails,
    { manual: true }
  );

  const [{ data: studioData }, fetchStudioDetails] = useAxios(
    API_END_POINTS.getStudioDetails,
  );

  useEffect(() => {
    studioData && setCountry(studioData.data.country)
  }, [studioData])

  useEffect(() => {
    fetchBankDetails()
  }, [])

  const setBankDetailLocal = (res: any) => {
    if (res) {
      const { data } = response;
      if (!!data?.length) {
        const { last4, default_for_currency, fingerprint, routing_number, status, country, ...rest } = data[0] || {};
        setIbanPlaceHolder(`${country}***************${last4}`);
        setBankdetails({ ...rest, account_number: '', country });
      }
    }
  }

  useEffect(() => {
    reset(bankdetails);
  }, [bankdetails]);

  useEffect(() => {
    setBankDetailLocal(response)
  }, [response, error]);

  const [
    { data, loading: updateLoading, error: updateErr, response: responseData },
    updateBankDetails
  ] = useAxios(
    {
      url: API_END_POINTS.updateBankDetails,
      method: "PUT",
    },
    { manual: true }
  );

  useEffect(() => {
    setBankDetailLocal(responseData)
  }, [responseData]);

  const onSubmit = async (value: any) => {
    await updateBankDetails({
      data: {
        ...value,
        account_number: value.account_number.replaceAll(" ", ""),
        object: "bank_account",
      },
    });
  };

  useEffect(() => {
    if (!!data && !updateErr) {
      const { detail: { last4, country } } = data;
      setIbanPlaceHolder(`${country}***************${last4}`);
      showToast("Bank details successfully updated", EToastType.success);
    }

    !!updateErr && showToast("Something went wrong!", EToastType.error);
  }, [data, updateErr]);

  const getInputProps: any = (
    name: string,
    label: string,
    helperText: string
  ) => {
    return {
      name,
      label,
      control: methods.control,
      getValues: methods.getValues,
      error: errors ? !!errors[name] : false,
      helperText: errors && errors[name] ? errors[name]?.message : helperText,
    };
  };

  const handleReset = () => {
    reset(bankdetails);
  };

  return loading ? (
    <CSpinner />
  ) : (
    <>
      <Prompt when={!!dirtyFieldsCount} message="" />
      <CCard>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CCardHeader>
            Bank Details
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol sm="6">
                <Form.Input
                  gridtype="col"
                  placeholder={
                    ibanPlaceholder ? ibanPlaceholder :
                      "Please enter the IBAN number"
                  }
                  {...getInputProps("account_number", "IBAN")}
                />
                <Form.Input
                  gridtype="col"
                  {...getInputProps("bank_name", "Bank Name*")}
                />
                <ConnectAccount location={location}></ConnectAccount>
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter>
            <CButton type="submit" color="info" variant="outline" disabled={!dirtyFieldsCount}>
              Save Changes
              {updateLoading && (
                <CSpinner className="ms-2" color="light" size="sm" />
              )}
            </CButton>
            {!!dirtyFieldsCount && (
              <CButton
                className="mx-3"
                color="dark"
                variant="outline"
                onClick={handleReset}
              >
                Reset All
              </CButton>
            )}
          </CCardFooter>
        </form>
      </CCard>
    </>
  );
}

export default BankDetailsForm;

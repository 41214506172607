import React, {
  MouseEventHandler,
  PropsWithChildren,
  ReactElement,
} from "react";
import {
  Row,
  TableInstance,
  TableOptions,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

import {
  CCard,
  CCardBody,
  CCardFooter,
  CCol,
  CPagination,
  CPaginationItem,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import Loader from "./Loader";
import ErrorNotice from "./ErrorNotice";
import CIcon from "@coreui/icons-react";
import EmptyNotice from "./EmptyNotice";

const defaultPropGetter = () => ({});


export interface TableProperties<T extends Record<string, unknown>>
  extends TableOptions<T> {
  loading?: boolean;
  error?: boolean | undefined;
  name?: string;
  onAdd?: (instance: TableInstance<T>) => MouseEventHandler;
  onDelete?: (instance: TableInstance<T>) => MouseEventHandler;
  onEdit?: (instance: TableInstance<T>) => MouseEventHandler;
  onClick?: (row: Row<T>) => void;
  extraConfig?: any;
  tableProps?: any;
  getHeaderProps?: Function;
  getColumnProps?: Function;
  getRowProps?: Function;
  getCellProps?: Function;
  template?: string;
}

export function Table<T extends Record<string, unknown>>(
  props: PropsWithChildren<TableProperties<T>>
): ReactElement {
  // Use the state and functions returned from useTable to build your UI
  const {
    columns,
    data,
    loading,
    error,
    tableProps = {},
    extraConfig = {},
    getHeaderProps = defaultPropGetter,
    getColumnProps = defaultPropGetter,
    getRowProps = defaultPropGetter,
    getCellProps = defaultPropGetter,
    template
  } = props;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable<T>(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
      ...extraConfig,
    },
    useSortBy,
    usePagination
  );
  const colSpanProps = { colSpan: columns.length };
  // Render the UI for your table
  return (
      <CCard className="table-card">
        <CCardBody>
          <CTable
            {...getTableProps()}
            responsive
            align="middle"
            className={`mb-0 custom-table table-mod`}
            hover
            {...tableProps}
          >
            <CTableHead>
              {headerGroups.map((headerGroup) => (
                <CTableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => (
                    <CTableHeaderCell
                      {...column.getHeaderProps([
                        {
                          className: column.className,
                          style: column.style,
                        },
                        getColumnProps(column),
                        getHeaderProps(column),
                      ])}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <div className="d-flex align-items-center">
                        {column.render("Header")}
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <CIcon
                              icon="cil-arrow-bottom"
                              size="sm"
                              className="mx-1"
                            />
                          ) : (
                            <CIcon
                              icon="cil-arrow-top"
                              size="sm"
                              className="mx-1"
                            />
                          )
                        ) : (
                          ""
                        )}
                      </div>
                    </CTableHeaderCell>
                  ))}
                </CTableRow>
              ))}
            </CTableHead>
            <CTableBody {...getTableBodyProps()}>
              {loading && (
                <CTableRow>
                  <CTableDataCell {...colSpanProps}>
                    <Loader />
                  </CTableDataCell>
                </CTableRow>
              )}
              {error && (
                <CTableRow>
                  <CTableDataCell {...colSpanProps}>
                    <ErrorNotice />
                  </CTableDataCell>
                </CTableRow>
              )}
              {!data.length && !loading && !error && (
                <CTableRow>
                  <CTableDataCell {...colSpanProps}>
                    <EmptyNotice />
                  </CTableDataCell>
                </CTableRow>
              )}
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <CTableRow {...row.getRowProps(getRowProps(row))}>
                    {row.cells.map((cell: any) => {
                      return (
                        <CTableDataCell
                          {...cell.getCellProps([
                            {
                              className: cell.column.className,
                              style: cell.column.style,
                            },
                            getColumnProps(cell.column),
                            getCellProps(cell),
                          ])}
                        >
                          <div>{cell.render("Cell")}</div>
                        </CTableDataCell>
                      );
                    })}
                  </CTableRow>
                );
              })}
            </CTableBody>
          </CTable>
        </CCardBody>
        {data.length > 10 && (
          <CCardFooter>
            {/* 
          Pagination can be built however you'd like. 
          This is just a very basic UI implementation:
        */}
            <CPagination>
              <CPaginationItem
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </CPaginationItem>{" "}
              <CPaginationItem
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                {"<"}
              </CPaginationItem>{" "}
              <CPaginationItem onClick={() => nextPage()} disabled={!canNextPage}>
                {">"}
              </CPaginationItem>{" "}
              <CPaginationItem
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </CPaginationItem>{" "}
            </CPagination>
            {/* <span>
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{" "}
            </span>
            <span>
              | Go to page:{" "}
              <input
                type="number"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
                style={{ width: "100px" }}
              />
            </span>{" "}
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select> */}
          </CCardFooter>
        )}
      </CCard>

  );
}

export default Table;

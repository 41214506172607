import { CBadge } from "@coreui/react";
import moment from "moment";
import { formateDate, numToFixed } from "../../../utils/functions";
import { getArtistName } from "../../artists/functions";
import { getStatusColor } from "./functions";

const UpcomingCard = (props: any) => {
    const { data: {offer_date, duration, status, artist, customer, project_name, currency, price}, setVisible } = props;
    const displayName = getArtistName(artist);
    const displayCusName = getArtistName(customer);
    let statusLabel = status;
    const labelColorClass = getStatusColor(statusLabel);
    if (statusLabel === 'postponed_pending') statusLabel = 'new date pending';
    const startTime = moment(offer_date).format('HH:mm');
    const endTime = moment(offer_date).add(duration, 'minutes').format('HH:mm');
    return (
        <div className="upcoming-card">
            {
                setVisible && (
                    <span className="close-btn" onClick={() => setVisible(false)}>
                        <img src="/images/close.svg" alt="" />
                    </span>
                )
            }
            <div className="upcoming-card-header">
                <img src={artist.image_medium} alt="" />
                <span className="name">{displayName}</span>
            </div>
            <div className="proj-details">
                <div>
                    <p className="style">{project_name}</p>
                    <p className="customer">{displayCusName}</p>
                </div>
                <p className="price">
                    <span>{currency.code}</span>
                    {numToFixed(price)}
                </p>
            </div>
            <div className="proj-status">
                <div>
                    <p className="date">{formateDate(offer_date)}</p>
                    <span>{startTime} - {endTime}</span>
                </div>
                <CBadge style={{ minWidth: '112px' }} className={`text-capitalize rel-label ${labelColorClass} border-0 text-nowrap`}>{statusLabel}</CBadge>
            </div>
        </div>
    )
}

export default UpcomingCard
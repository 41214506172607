import React, { useState } from "react";
import { DateRangeSelect } from "../_lib/constants";
import DatePicker, { utils } from "@hassanmojab/react-modern-calendar-datepicker";
import CIcon from "@coreui/icons-react";
import * as icon from '@coreui/icons';
import _ from 'lodash';

import { FilterType } from "../_lib/constants";

interface FilterDataProps {
    showTodayBtn?: boolean;
    showDateRangeBtn?: boolean;
    showResetBtn?: boolean;
    onSelectToday?: Function;
    onSelectDateRange?: Function;
    onFilterReset?: Function;
}

const FilterData: React.FC<FilterDataProps> = ({
    showTodayBtn = true,
    showDateRangeBtn = true,
    showResetBtn = true,
    onSelectToday,
    onSelectDateRange,
    onFilterReset
}) => {
    const [filterActive, setFilterActive] = useState<string>('');
    const [dateRange, setDateRange] = useState<DateRangeSelect>({
        from: null,
        to: null
    });

    const handleTodayFilter = (): void => {
        setFilterActive(FilterType?.today);
        setDateRange({
            from: null,
            to: null
        })
        if (onSelectToday) onSelectToday();
    };

    const handleDateRangeChange = (dateValue: DateRangeSelect): void => {
        setDateRange(dateValue);
        const { from, to } = dateValue;
        const fromDt = `${from?.day}/${from?.month}/${from?.year}`;
        const toDt = `${to?.day}/${to?.month}/${to?.year}` || null;
        if (from && to) {
            setFilterActive(`${fromDt} to ${toDt}`);
            if (onSelectDateRange) onSelectDateRange(dateValue);
        }
    };

    const handleFilterReset = (): void => {
        setDateRange({
            from: null,
            to: null
        });
        setFilterActive("");
        if (onFilterReset) onFilterReset();
    }

    const renderCustomInput = ({ ref }: any) => (
        <button
            className={`filter-button-text
                ${filterActive && filterActive !== FilterType?.today
                    ? 'active-filter-btn' : ''}
            `}
            title="Today"
            ref={ref}
        >
            Filter By Date Range
        </button>
    );

    return (
        <div className="row justify-content-end">
            <div className="col-lg-6 col-md-7 col-12 mb-md-0 mb-2 mt-sm-0 mt-3">
                <div className='card-action-row'>
                    {showTodayBtn && (
                        <button
                            className={`filter-button-text ${filterActive && filterActive === FilterType?.today ? 'active-filter-btn' : ''}`}
                            onClick={handleTodayFilter}
                            title="Today"
                        >
                            Filter By Today
                        </button>
                    )}
                    {showDateRangeBtn && (
                        <div>
                            <DatePicker
                                value={dateRange}
                                onChange={handleDateRangeChange}
                                shouldHighlightWeekends
                                maximumDate={utils('en').getToday()}
                                renderInput={renderCustomInput}
                            />
                        </div>
                    )}
                    {filterActive && showResetBtn && (
                        <button
                            className='filter-button'
                            onClick={handleFilterReset}
                            title="Reset"
                        >
                            <CIcon icon={icon?.cilReload} />
                        </button>
                    )}
                </div>
                {filterActive && (
                    <div className="filter-data">Showing Analytics of <span>{filterActive}</span></div>
                )}
            </div>
        </div>
    )
};

export default FilterData;
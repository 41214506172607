import { createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "../store";
import axios from "../utils/axios";

export interface Notification {
  id: string;
  notification_uid: string;
  user: string;
  redirection_type: string;
  notification_type: string;
  content: string;
  read_status: string;
  done_status: string;
  delete_status: boolean;
  offer: object;
  studio: object;
  created_at: string;
  updated_at: string;
  created_by: string; // user name
  updated_by: string; // user name
}

export interface NotificationState {
  rows: Notification[];
  isLoading: boolean;
  error: boolean;
}

const initialState: NotificationState = {
  rows: [],
  isLoading: false,
  error: false,
};

const name = "notifications";
const API = "/studiowner/api/studio/notifications";
const API_READ = "/studiowner/api/studio/notifications";

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    getRows(state, action) {
      state.rows = action.payload;
      state.isLoading = false;
    },
  },
});

export const reducer = slice.reducer;

export const getRows = () => async (dispatch: AppDispatch) => {
  dispatch(slice.actions.setLoading(true));
  const response = await axios(`${API}`);
  dispatch(slice.actions.getRows(response.data?.data || []));
};

export const readNotification =
  (uid: string) => async (dispatch: AppDispatch) => {
    const response = await axios.post(API_READ, { uid });
    dispatch(slice.actions.getRows(response.data?.data || []));
    dispatch(getRows());
  };

export default slice;

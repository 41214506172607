import { toast } from "react-toastify";

export enum EToastType {
  success = "success  ",
  error = "error",
  warn = "warn",
  info = "info",
}

export const showToast = (msg: string, type: string | null = null) => {
  if (type === EToastType.success) toast.success(msg);
  else if (type === EToastType.success) toast.success(msg);
  else if (type === EToastType.error) toast.error(msg);
  else if (type === EToastType.warn) toast.warn(msg);
  else if (type === EToastType.info) toast.info(msg);
  else toast.dark(msg);
};

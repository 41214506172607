import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { string, object } from "yup";

import { Form } from "../../../components";
import { login, setLoading } from "../../../slices/auth";
import { useDispatch, useSelector } from "../../../store";
import { ROUTING_URLS } from "../../../utils/constants";

import { BottomNav } from "./../styles";

const schema = object().shape({
  username: string()
    .email("Address format not valid!")
    .required("Address not valid!"),
  password: string().required("Password not valid!"),
});

export default function LoginForm(props: any) {
  const { token } = props;
  const isSigningIn = !!token;
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(setLoading(false));
  }, []);

  const onSubmit = (data: any) => {
    !token
      ? dispatch(login(data, "login"))
      : dispatch(
          login(
            {
              ...data,
              email: data.username,
              token: token.replaceAll(" ", "+"),
            },
            "signIn"
          )
        );
  };

  return (
    <Form.Form onSubmit={onSubmit} validationSchema={schema}>
      <Form.Input name="username" placeholder="email address" />
      <Form.Input name="password" type="password" placeholder={!token ? "password" : 'choose a password'} />
      <div className={!token ? 'text-center mt-2' : 'text-center mt-2 mb-4'}>
        <Form.Button
          label={isSigningIn ? "Accept & Proceed" : "Log in"}
          isLoading={isLoading}
        />
      </div>
      {isSigningIn && (
        <div className="text-center my-2" style={{ fontSize: 13 }}>
          By selecting Agree & Proceed to go, I agree to Inckd's{" "}
          <Link to={ROUTING_URLS.publicTermsAndConditions}>
            Terms & Conditions
          </Link>{" "}
          and{" "}
          <Link to={ROUTING_URLS.publicPrivacyPolicy}>
            Privacy Policy.
          </Link>
        </div>
      )}
      {!token &&
      <BottomNav>
        <Link to="/forgot-password">Forgot password?</Link>
      </BottomNav>
      }
    </Form.Form>
  );
}

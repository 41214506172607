import { ROUTING_URLS } from "../../utils/constants";
import { RedirectionTypes } from "./constants";

export const getRedirectionObj = (
  notification: Record<string, any>
): Record<string, any> => {
  let navigateTo;
  let navigationParams;
  switch (notification.redirection_type) {
    case RedirectionTypes.STUDIO_LIST:
      case RedirectionTypes.STUDIO:
    case RedirectionTypes.STUDIO_SHARE:
    case RedirectionTypes.ARTIST_APPROVAL:
      navigateTo = ROUTING_URLS.artists;
      navigationParams = {};
      break;
    case RedirectionTypes.PAYOUT:
      navigateTo = ROUTING_URLS.payoutReceipts;
      navigationParams = {};
      break;
    case RedirectionTypes.HISTORY:
      navigateTo = ROUTING_URLS.history;
      navigationParams = {};
      break;
    case RedirectionTypes.BOOKINGS:
      navigateTo = ROUTING_URLS.upcomingBookings;
      navigationParams = {};
      break;
    case RedirectionTypes.KYC:
      navigateTo = ROUTING_URLS.bankDetails;
      navigationParams = {};
      break;
    default:
      navigateTo = ROUTING_URLS.notifications;
      navigationParams = {};
      break;
  }

  return { navigateTo, navigationParams };
};

export default getRedirectionObj;

import React from "react";
import { Controller } from "react-hook-form";

import { CFormLabel, CFormText, CFormCheck } from "@coreui/react";

interface Props {
  [key: string]: any;
}

export function RadioGroup(props: Props) {
  const {
    name,
    control,
    defaultValue,
    getValues,
    title,
    label,
    error,
    helperText,
    options,
    ...rest
  } = props;
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field }) => {
        const { value, onChange, ...fieldRest } = field;
        return (
          <React.Fragment key={`${name}-${value}`} >
            {title && <CFormLabel className="me-3">{title}</CFormLabel>}
            {options.map((option: any, i: any) => (
              <CFormCheck
                key={i}
                {...fieldRest}
                {...rest}
                label={option.label}
                type="radio"
                onChange={(e: any) => onChange(option.value)}
                defaultChecked={value === option.value}
                inline
              />
            ))}

            {helperText && (
              <CFormText className="mt-2" style={{ position: "relative" }}>
                {helperText}
              </CFormText>
            )}
          </React.Fragment>
        );
      }}
    />
  );
}

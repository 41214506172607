import { Select } from "./Select";
import { Input } from "./Input";
import { Form } from "./Form";
import { Checkbox } from "./CheckBox";
import { RadioGroup } from "./RadioGroup";
import { LocationSearchInput } from "./LocationSearchInput";
import { FormButton as Button } from "./Button";

export default {
  Form,
  Input,
  Select,
  Checkbox,
  LocationSearchInput,
  Button,
  RadioGroup,
};

import React from "react";
import CIcon from "@coreui/icons-react";
import { Link } from "react-router-dom";
import { ROUTING_URLS } from "./utils/constants";

const _nav: any = [
  {
    _component: "CNavItem",
    as: Link,
    anchor: "Dashboard",
    to: "/",
    icon: <CIcon icon="cil-speedometer" customClassName="nav-icon" />,
    badge: {
      color: "info",
      text: "NEW",
    },
  },
  {
    _component: "CNavItem",
    as: Link,
    anchor: "My Artists",
    to: ROUTING_URLS.artists,
    icon: <CIcon icon="cil-people" customClassName="nav-icon" />,
  },

  {
    _component: "CNavGroup",
    as: Link,
    anchor: "Bookings",
    to: "",
    icon: <CIcon icon="cil-calendar" customClassName="nav-icon" />,
    items: [
      {
        _component: "CNavItem",
        as: Link,
        anchor: "Upcoming",
        to: ROUTING_URLS.upcomingBookings,
        //icon: <CIcon name="cil-calendar-check" customClassName="nav-icon" />,
      },
    ],
  },
  {
    _component: "CNavGroup",
    as: Link,
    anchor: "Payments",
    to: "/to",
    icon: <CIcon icon="cil-credit-card" customClassName="nav-icon" />,
    items: [
      {
        _component: "CNavItem",
        as: Link,
        anchor: "History",
        to: ROUTING_URLS.history,
        //icon: <CIcon name="cil-history" customClassName="nav-icon" />,
      },
      {
        _component: "CNavItem",
        as: Link,
        anchor: "Payout Receipts",
        to: ROUTING_URLS.receipts + "-payout",
        // icon: <CIcon name="cil-drop" customClassName="nav-icon" />,
      },
      {
        _component: "CNavItem",
        as: Link,
        anchor: "Invoice Receipts",
        to: ROUTING_URLS.receipts + "-invoice",
        // icon: <CIcon name="cil-drop" customClassName="nav-icon" />,
      },
      {
        _component: "CNavItem",
        as: Link,
        anchor: "Billing Address",
        to: ROUTING_URLS.billingAddress,
        // icon: <CIcon name="cil-drop" customClassName="nav-icon" />,
      },
      {
        _component: "CNavItem",
        as: Link,
        anchor: "Bank Details",
        to: ROUTING_URLS.bankDetails,
        // icon: <CIcon name="cil-drop" customClassName="nav-icon" />,
      },
      {
        _component: "CNavItem",
        as: Link,
        anchor: "VAT Details",
        to: ROUTING_URLS.vatDetails,
        // icon: <CIcon name="cil-drop" customClassName="nav-icon" />,
      },
    ],
  },
  {
    _component: "CNavGroup",
    as: Link,
    anchor: "Studio settings ",
    to: "/to",
    icon: <CIcon icon="cil-settings" customClassName="nav-icon" />,
    items: [
      {
        _component: "CNavItem",
        as: Link,
        anchor: "Account Details",
        to: ROUTING_URLS.address,
        // icon: <CIcon name="cil-drop" customClassName="nav-icon" />,
      },
      {
        _component: "CNavItem",
        as: Link,
        anchor: "Contact",
        to: ROUTING_URLS.contact,
        // icon: <CIcon name="cil-drop" customClassName="nav-icon" />,
      },
      {
        _component: "CNavItem",
        as: Link,
        anchor: "Password",
        to: ROUTING_URLS.changePassword,
        // icon: <CIcon name="cil-drop" customClassName="nav-icon" />,
      },
    ],
  },
  {
    _component: "CNavGroup",
    as: Link,
    anchor: "Informations",
    to: "/to",
    icon: <CIcon icon="cil-clipboard" customClassName="nav-icon" />,
    items: [
      {
        _component: "CNavItem",
        as: Link,
        anchor: "Terms & Conditions",
        to: ROUTING_URLS.termsAndConditions,
        // icon: <CIcon name="cil-drop" customClassName="nav-icon" />,
      },
      {
        _component: "CNavItem",
        as: Link,
        anchor: "Privacy policy",
        to: ROUTING_URLS.privacyPolicy,
        // icon: <CIcon name="cil-drop" customClassName="nav-icon" />,
      },
      {
        _component: "CNavItem",
        as: Link,
        anchor: "Impressum",
        to: ROUTING_URLS.impressum,
        // icon: <CIcon name="cil-drop" customClassName="nav-icon" />,
      },
    ],
  },
];

export default _nav;

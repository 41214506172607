import { useEffect, useState } from "react";
import { string, bool, boolean, object, number } from "yup";
import { useAxios } from "../../hooks";
import { API_END_POINTS } from "../../utils/constants";

export const schemaObj = object({
  country: object({
    id: number().required(),
    name: string().required(),
  }),
  vat_value: string().when("checkedA", {
    is: true,
    then: string()
      .min(1, "Invalid VAT format")
      .required("Please fill out all fields or correct errors!"),
  }),
  checkedB: boolean().when("checkedA", {
    is: true,
    then: bool().oneOf([true], "Please accept disclaimer"),
  }),
});

export const useVatData = () => {
  const [vatFormData, setVatFormData] = useState<any>({
    country: {
      id: 2921044
    },
    checkedA: null,
    vat_value: "",
    checkedB: false,
  });

  const patchFormData: any = (res: any) => {
    const {
      data: { vat },
    } = res;
    if (!!Object.keys(vat).length) {
      const { country, vat_no, is_tax_payee, vat_studio_uid } = vat;
      setVatFormData({
        country,
        checkedA: is_tax_payee || false,
        vat_value: vat_no || "",
        checkedB: !!vat_no,
        vat_studio_uid
      });
    }
  };

  const [{ data, error, loading }, fetchVatDetails] = useAxios(
    API_END_POINTS.vatDetails,
    { manual: true }
  );

  useEffect(() => {
    fetchVatDetails();
  }, []);

  useEffect(() => {
    !!data && !error && patchFormData(data);
  }, [data, error]);

  return [vatFormData, patchFormData, loading];
};

import { useDispatch } from "react-redux";
import { clearResults, logout } from "../slices/auth";
import { useSelector } from "../store";

const useAuth = () => {
  const dispatch = useDispatch();
  const { isLoggedIn, active_status, user } = useSelector(
    (state) => state.auth
  );

  const signOut = () => {
    dispatch(logout());
    dispatch(clearResults());
    return true;
  };

  return {
    isAuthenticated: isLoggedIn,
    isLoggedIn,
    active_status,
    user,
    logout: signOut,
  };
};

export default useAuth;

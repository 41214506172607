import { useEffect, useState } from "react";
import { CModalBody, CModalFooter, CModalHeader, CModalTitle } from "@coreui/react"

import { EReceiptTypes, RECEIPT_CONFIG } from ".";
import moment from "moment";
import { API_END_POINTS } from "../../../utils/constants";
import './styles.scss';
import RecieptExport from "./RecieptExport";
import { useAxios } from "../../../hooks";
import Skeleton from "react-loading-skeleton";

function RecieptsDetailsModal({ id: payout_id, type, setDetailOpen }: any) {
    const [receiptData, setReceiptData] = useState<Record<string, any>>({ details: [], cancelled_details: [] });
    const [overlayHeaderData, setOverlayHeaderData] = useState<any>(['Receipts', '']);
    const isPayoutReceipt = type === EReceiptTypes.Payout;
    const isInvoiceReceipt = type === EReceiptTypes.Invoice;

    const formatDate = (date: string) => moment(date).format('DD.MM.YYYY');
    const numToFixed = (num: number) => (num || 0)?.toFixed(2);

    const [{ data: res, loading, error }, fetchPayoutsDetail] = useAxios(
        {
            url: API_END_POINTS.getStudioPayoutDetail,
            method: "POST",
            data: { payout_id, type },
        }
    );

    useEffect(() => {
        if (!!res) {
            const { data } = res;
            if (!!data) {
                const { payout_date } = data || {};
                updateHeaderTitle(type, formatDate(payout_date));
                setReceiptData(data || {});
            }
        }
        if (!!error) console.log(error)
    }, [res, error, type])
    const { details: transactionList, cancelled_details: cancelledTransactionList } = receiptData;
    const [header, subHeader] = overlayHeaderData;

    let totalAmount = 0;
    let totalAmountNet = 0;
    let totalAmountVat = 0;
    let totalAmountExclVat = 0;
    let inckdServiceFee = 0;
    let inckdServiceFeeVat = 0;
    let finalAmount = 0;
    let totalAmountInclVat = 0;
    let totalAmountCancelled = 0;
    let currency: any = '';

    totalAmountInclVat = isPayoutReceipt ? totalAmountNet : totalAmountExclVat + totalAmountVat;
    finalAmount = totalAmountInclVat - totalAmountCancelled;

    cancelledTransactionList?.map((x: Record<string, number>) => totalAmountCancelled += x.amount);
    transactionList?.map((x: Record<string, number>): void => {
        totalAmount += x.total_amount;
        totalAmountNet += x.net_total;
        totalAmountVat += x.vat_percent_amount;
        totalAmountExclVat += x.amount_excl_vat;
        inckdServiceFee += x.inckd_service_fee;
        inckdServiceFeeVat += x.inckd_vat_amount;
        currency = x.currency;
    });
    totalAmountInclVat = isPayoutReceipt ? totalAmountNet : totalAmountExclVat + totalAmountVat;
    finalAmount = totalAmountInclVat - totalAmountCancelled;

    const updateHeaderTitle: any = (receiptType: string, date = '') => {
        if (receiptType === EReceiptTypes.Payout) {
            setOverlayHeaderData(['Payout receipt', date]);
        } else if (receiptType === EReceiptTypes.Invoice) {
            setOverlayHeaderData(['Invoice receipt', date]);
        }
    }
    return (<>
        {
            loading ? <Skeleton height={500} />
                :
                (<><CModalHeader onDismiss={() => setDetailOpen(false)} className="align-items-start">
                    <CModalTitle>
                        {header} <br />
                        <span>{subHeader}</span>
                    </CModalTitle>
                </CModalHeader>
                    <CModalBody>
                        <section>
                            <div>
                                {
                                    transactionList?.map((transaction: Record<string, any>, i: number) => (
                                        <div key={i} className="tran-box transaction p-3 mb-2">
                                            <div>
                                                <span className="label">
                                                    {formatDate(transaction?.date)}
                                                    {transaction?.status === 'completed' ? ' - Tattoo done' : ' - Cancelation'} <br />
                                                    {transaction?.project} - {transaction?.artist_first_name} {transaction?.artist_last_name}
                                                </span>
                                                <div className="d-flex justify-content-between currency-wrap">
                                                    <span className="currency">{transaction?.currency}</span>
                                                    <span className="text">{numToFixed(transaction?.amount_excl_vat)}</span>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                                <div className="tran-box p-3">
                                    <div>
                                        <span className="label">Net total</span>
                                        <span className="text">{numToFixed(totalAmountExclVat)}</span>
                                    </div>
                                    <div>
                                        <span className="label">VAT {receiptData?.details[0]?.studio_vat}%</span>
                                        <span className="text">{numToFixed(totalAmountVat)}</span>
                                    </div>
                                    <div>
                                        <span className="divider flex-grow-1"></span>
                                    </div>
                                    {
                                        isPayoutReceipt &&
                                        <>
                                            <div className="mb-4">
                                                <span className="label"><b>Total</b></span>
                                                <div className="currency-wrap d-flex justify-content-between">
                                                    <span className="currency"><b>{currency}</b></span>
                                                    <span className="text"><b>{numToFixed(totalAmount)}</b></span>
                                                </div>
                                            </div>
                                            <div>
                                                <span className="label">Inckd. service fees</span>
                                                <div className="d-flex justify-content-between currency-wrap">
                                                    <span className="currency"></span>
                                                    <span className="text">{numToFixed(inckdServiceFee)}</span>
                                                </div>
                                            </div>
                                            <div>
                                                <span className="label">VAT {receiptData?.details[0]?.inckd_vat}%</span>
                                                <div className="d-flex justify-content-between currency-wrap">
                                                    <span className="currency"></span>
                                                    <span className="text">{numToFixed(inckdServiceFeeVat)}</span>
                                                </div>
                                            </div>
                                            <div>
                                                <span className="divider flex-grow-1"></span>
                                            </div>
                                        </>
                                    }
                                    <div>
                                        <span className="label">
                                            <b>
                                                {isPayoutReceipt ? 'Total' : 'Total service fee'}
                                            </b>
                                        </span>
                                        <div className="currency-wrap d-flex justify-content-between">
                                            <span className="currency"><b>{currency}</b></span>
                                            <span className="text">
                                                <b>{isPayoutReceipt ?
                                                    numToFixed(inckdServiceFee + inckdServiceFeeVat) :
                                                    numToFixed(totalAmountInclVat)}</b>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="tran-box total d-flex align-items-center p-3">
                                    <div className="flex-grow-1">
                                        <span className="label">Total {type}</span>
                                        <div className="currency-wrap d-flex justify-content-between">
                                            <span className="currency"><b>{currency}</b></span>
                                            <span className="text">{numToFixed(finalAmount)}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </CModalBody>
                    <CModalFooter>
                        <RecieptExport label="export" type={type} data={receiptData} />
                    </CModalFooter></>)
        }</>
    )
}

export default RecieptsDetailsModal

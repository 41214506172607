import { useEffect, useRef } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { string, object } from "yup";
import axios from "../../../utils/axios";
import { Form } from "../../../components";
import { setLoading } from "../../../slices/auth";
import { useDispatch, useSelector } from "../../../store";
import { API_END_POINTS } from "../../../utils/constants";
import { BottomNav } from "../styles";
import { EToastType, showToast } from "../../../utils/toast";

const schema = object().shape({
  password: string()
    .required("No password provided.")
    .min(8, "Password is too short - should be 8 chars minimum."),
});
function ResetPasswordForm({
  frgtUsrSuccess,
  setFrgtUsrSuccess,
  setFrgtUsrError,
  frgtUsrError,
  setUserEmail,
}: any) {
  const formRef = useRef<any>();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.auth);
  const history = useHistory();
  let { token } = useParams<any>();

  useEffect(() => {
    dispatch(setLoading(false));
  }, []);

  const onSubmit = async (data: any) => {
    dispatch(setLoading(true));
    try {
      const response: any = await axios.post(API_END_POINTS.resetPassword, {
        ...data,
        token,
      });
      if (response?.status === 200 || response?.status === 201) {
        dispatch(setLoading(false));
        setFrgtUsrSuccess(true);
        setFrgtUsrError("");
        formRef.current.reset();
        history.push("/login?fromRP=1");
        showToast("Success", EToastType.success);
      }
    } catch (error) {
      dispatch(setLoading(false));
      showToast("Something went wrong!", EToastType.error);
    }
  };
  return (
    <Form.Form onSubmit={onSubmit} validationSchema={schema} ref={formRef}>
      <Form.Input name="password" type="password" placeholder="New password" />
      <div className="text-center mt-2">
        <Form.Button label="Reset password" isLoading={isLoading} />
      </div>
      <BottomNav>
        <Link to="/login">Back to login</Link>
      </BottomNav>
    </Form.Form>
  );
}

export default ResetPasswordForm;

import React, { useEffect } from "react";
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CSpinner,
} from "@coreui/react";
import { useAuth, useAxios } from "../../../hooks";
import { EToastType, showToast } from "../../../utils/toast";
import { TButton } from "../../../components/Table";
import axiosInstance from "../../../utils/axios";
import { API_END_POINTS, ROUTING_URLS } from "../../../utils/constants";
import { ConfirmModal } from "../../../components";
import { useHistory } from "react-router-dom";
import { useDispatch } from "../../../store";
import { setModalVisible } from "../../../slices/modal";

interface IConfirm {
  title?: string;
  body?: string[];
  buttonText?: string;
}

interface Props {
  data: Record<string, any>;
  onSuccess?: Function;
  onError?: Function;
  api: string;
  label: string;
  buttonProps?: Record<string, any>;
  confirm?: IConfirm;
  successMessage?: string;
  color?: string;
  needVatConfirm?: boolean;
}

export const ActionButton = ({
  api,
  data,
  onSuccess,
  onError,
  label,
  buttonProps,
  confirm,
  successMessage,
  color,
  needVatConfirm = false,
}: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [visible, setVisible] = React.useState(false);
  const [confVisible, setConfVisible] = React.useState(false);
  const [vatModalVisible, setVatModalVisible] = React.useState(false);
  const [vatLoading, setVatLoading] = React.useState(false);
  const { active_status } = useAuth();
  const isActive = !!active_status;
  let needConfirmation = !!confirm;
  let modalTitle = confirm?.title || "Confirm";
  let modalBody: any = (confirm?.body || []).map((x, i) => <p key={i}>{x}</p>);

  if (!isActive) {
    needConfirmation = true;
    modalTitle = "Profile not complete!";
    modalBody = ["Please complete your profile to continue"];
  }

  const [{ data: response, loading, error }, executeUpdate] = useAxios(
    {
      url: api,
      method: "POST",
    },
    { manual: true }
  );

  const handleError = (error: any) => {
    if(error?.detail) {
      error?.detail && setConfVisible(true)
    } else {
      if (!!onError) onError(error);
      else showToast(error?.message || "Something went wrong");
    }
  };

  useEffect(() => {
    if (!!response && !loading) {
      dispatch(setModalVisible(false))
      if (successMessage) showToast(successMessage, EToastType.success);
      if (!!onSuccess) onSuccess(data);
      else showToast(successMessage || "Success", EToastType.success);
    }
    if (!!error && !loading) handleError(error);
  }, [response, error]);

  const handleClick = () => {
    if (!needVatConfirm) {
      executeUpdate({
        data,
      });
      return;
    }
    setVatLoading(true);
    axiosInstance.get(API_END_POINTS.vatDetails).then((res: any) => {
      setVatLoading(false);
      if (!res) handleError(null);
      const {
        data: { vat },
      } = res.data;
      if (vat && !!Object.keys(vat).length) {
        const { vat_studio_uid } = vat;
        if (!!vat_studio_uid) {
          executeUpdate({
            data,
          });
        } else {
          setVatModalVisible(true);
        }
      }
    });
  };

  return (
    <>
      <TButton
        onClick={() => (needConfirmation ? setVisible(true) : handleClick())}
        color={color || "primary"}
        {...buttonProps}
      >
        {label}
        {(loading || vatLoading) && (
          <CSpinner color="light" size="sm" className="mx-1" />
        )}
      </TButton>

      <ConfirmModal
        visible={visible}
        setVisible={setVisible}
        confirmButtonText={confirm?.buttonText || "Go to Registration"}
        title={modalTitle}
        body={modalBody}
        onSuccess={() =>
          isActive ? handleClick() : history.push(ROUTING_URLS.account)
        }
      />
      <ConfirmModal
        visible={vatModalVisible}
        setVisible={setVatModalVisible}
        confirmButtonText="Go to VAT details"
        cancelButtonText="Cancel"
        title="Missing VAT Details!"
        body={["Please update your VAT details to continue."]}
        onSuccess={() => history.push(ROUTING_URLS.vatDetails+'?redirect=artist')}
      />
      <CModal
          visible={confVisible}
          onDismiss={() => setConfVisible(false)}
          size="lg"
          alignment="center"
          scrollable
        >
          <CModalHeader onDismiss={() => setConfVisible(false)}>
            <CModalTitle>Action</CModalTitle>
          </CModalHeader>
          <CModalBody>Artist still have open offers in this studio. Please check and comeback later</CModalBody>
        </CModal>
    </>
  );
};

import { CFormLabel, CFormSelect } from "@coreui/react";
import { useEffect, useState } from "react";

interface Props {
  name: string;
  [key: string]: any;
}

export function Select({
  label,
  disabled,
  error,
  name,
  getValues,
  options,
  register = () => {},
  ...rest
}: Props) {
  const values = getValues(name);
  return (
    <div className="mb-4 pb-3 form-wrap" >
      <div className="row">
        <div className="col-lg-4">
          {label && <CFormLabel>{label}</CFormLabel>}
        </div>
        <div className={`col-lg-8 ${disabled ? 'select-wrap-disabled' : ''}`}>
          <CFormSelect {...register(name)}>
            
            {options ? (
              options.map((i: any)=>(
                <option value={i.id}>{i.name}</option>
              ))
            ): (
              <option value={values?.id}>{values?.name}</option>
            )}
          </CFormSelect>
          <div className="form-text">{error && error.message}</div>
        </div>
      </div>
    </div>
  )
}

import React from "react";
import { CButton, CCol, CContainer, CInputGroup, CRow } from "@coreui/react";

import { Link } from "react-router-dom";

const Page404 = () => {
  return (
    <div className="bg-primary text-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="6">
            <div className="clearfix">
              <h1 className="float-start display-3 me-4">404</h1>
              <h4 className="pt-3">Oops! You{"'"}re lost.</h4>
              <p className="text-medium-emphasis float-start">
                The page you are looking for was not found.
              </p>
            </div>
            <CInputGroup className="input-prepend">
              <CButton>
                <Link to="/" className="text-light b">
                  Go home
                </Link>
              </CButton>
            </CInputGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default Page404;

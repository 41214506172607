import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCardTitle,
  CCol,
  CContainer,
  CRow,
  CSpinner,
} from "@coreui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { string, object } from "yup";
import { useForm, useFormState } from "react-hook-form";
import Form from "../../../components/Form";
import { API_END_POINTS } from "../../../utils/constants";
import { useAxios } from "../../../hooks";
import { useEffect } from "react";
import { EToastType, showToast } from "../../../utils/toast";
import { Prompt } from "react-router-dom";

function ChangePasswordForm() {
  const validationSchema = object().shape({
    old_password: string().required(
      "Please fill out all fields or correct errors!"
    ),
    new_password: string()
      .min(8, "Password is too short")
      .required("Please fill out all fields or correct errors!"),
  });
  const defaultValues = {
    old_password: '',
    new_password: ''
  }
  const methods = useForm<any>({
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
    defaultValues
  });
  const {
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = methods;
  const { isDirty } = useFormState({ control });

  const [{ data, loading, error }, triggerChangePassword] = useAxios(
    {
      url: API_END_POINTS.changePassword,
      method: "PUT",
    },
    { manual: true }
  );

  useEffect(() => {
    !!data &&
      !error &&
      showToast("Password successfully updated", EToastType.success);
    !!error && showToast(Object.entries(error)[0][1][0], EToastType.error);
  }, [data, error]);

  const onSubmit = async (value: any) => {
    await triggerChangePassword({
      data: value,
    });
    reset(defaultValues);
  };
  const getInputProps: any = (
    name: string,
    label: string,
    helperText: string
  ) => {
    return {
      name,
      label,
      control: methods.control,
      getValues: methods.getValues,
      error: errors ? !!errors[name] : false,
      helperText: errors && errors[name] ? errors[name]?.message : helperText,
    };
  };

  const handleReset = () => {
    reset(defaultValues);
  };

  return (
    <>
      <Prompt when={isDirty} message="" />
      <CCol lg="8">
        <CCard>
          <form onSubmit={handleSubmit(onSubmit)}>
            <CCardHeader>
              Change Password
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol>
                  <Form.Input
                    gridtype="col"
                    type="password"
                    {...getInputProps("old_password", "Current Password*")}
                  />
                  <Form.Input
                    gridtype="col"
                    type="password"
                    {...getInputProps("new_password", "New Password*")}
                  />
                </CCol>
              </CRow>
            </CCardBody>
            <CCardFooter>
              <CButton type="submit" color="info" variant="outline" disabled={!isDirty}>
                Save Changes
                {loading && (
                  <CSpinner className="ms-2" color="light" size="sm" />
                )}
              </CButton>
              {isDirty && (
                <CButton
                  className="mx-3"
                  color="dark"
                  variant="outline"
                  onClick={handleReset}
                >
                  Reset All
                </CButton>
              )}
            </CCardFooter>
          </form>
        </CCard>
      </CCol>
    </>
  );
}

export default ChangePasswordForm;

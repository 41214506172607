import { createSlice } from "@reduxjs/toolkit";

interface IState {
    modalVisible: boolean;
}

const initialState: IState = {
    modalVisible: false,
};

const slice = createSlice({
    name: "modal",
    initialState,
    reducers: {
        setModalVisible(state, action) {
            state.modalVisible = action.payload
        },
    },
});

export const { setModalVisible } = slice.actions;
export const reducer = slice.reducer;

export default slice;

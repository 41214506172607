import { useMemo } from "react";
import { Column } from 'react-table';
import { Table } from "../../../components";
import { EReceiptTypes } from ".";
import moment from "moment";
import { TButton } from "../../../components/Table";

function RecieptsList({ data, type, navigate, loading, error }: any) {
  const isPayout = type === EReceiptTypes.Payout;
  const isInvoice = type === EReceiptTypes.Invoice;
  const columns = useMemo<Column<any>[]>(
    () => [
      {
        Header: "Date",
        accessor: ({ date }) => {
          const dateMod = moment(date).format('DD.MM.YY');
          return (
            <p className="mb-0">{dateMod}</p>
          );
        },
      },
      {
        Header: "Amount",
        accessor: ({ amount: studio_amount, inckd_amount, read_status, invoice_read_status, currency }) => {
          const amount = isPayout ? studio_amount : inckd_amount;
          const isNew = isPayout ? !read_status : !invoice_read_status;
          return (
            <p className={`mb-0`}><span>{currency}</span> {amount.toFixed(2)}</p>
          );
        },
      },
      {
        Header: "Payout Id",
        className:'hide-xs',
        accessor: (row) => {
          return (
            <p className="mb-0">{row.payout_id}</p>
          );
        },
      },
      {
        Header: '',
        className: 'reciept-actions text-end',
        accessor: "id",
        Cell: ({ row }: any) => <TButton onClick={() => { navigate(row.original.payout_id) }} size="sm">Details</TButton>,
      },
    ],
    [isPayout]
  );
  return (
    <Table
      columns={columns}
      data={data || []}
      loading={loading}
      error={!!error}
    />
  )
}

export default RecieptsList

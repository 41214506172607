import moment from "moment";
import { EBookingLabel } from "../../../utils/enums";
import { getArtistImage, getArtistName } from "../../artists/functions";

export const getStatusColor = (status: string) => {
    let color = '';
    switch (status) {
        case EBookingLabel.Scheduled:
            color = 'bg-scheduled'
            break;
        case EBookingLabel.Pending:
            color = 'bg-pending'
            break;
        case EBookingLabel.Postponed:
            color = 'bg-postponed'
            break;
        default:
            color = ''
    }
    return color;
}

export const datePickerLabel = (view: string, date: Date, isMobile: boolean = false, isMobileWeek: boolean = false) => {
    let label = ''
    switch (view) {
        case 'month':
            const days = moment(date).daysInMonth();
            label = `1 - ${days} ` + moment(date).format('MMM.YYYY')
            break;
        case 'week':
            const startDate = moment(date).startOf('week').format('D');
            const endDate = moment(date).endOf('week').format('D');
            label = `${startDate} - ${endDate} ${moment(date).format('MMM.YYYY')}`
            break;
        default:
            if (isMobile && isMobileWeek) {
                const startDate = moment(date).startOf('week').format('D');
                const endDate = moment(date).endOf('week').format('D');
                label = `${startDate} - ${endDate} ${moment(date).format('MMM.YYYY')}`
            } else {
                const day = moment(date).format('D');
                label = `${day} ${moment(date).format('MMM.YYYY')}`
            }
    }
    return label;
}

export const getEvents = (data: []) => {
    let events: object[] = [];
    data.forEach((i: any) => {
        var dt = new Date(i.offer_date);
        dt.setMinutes(dt.getMinutes() + parseInt(i.duration));
        events.push(
            {
                id: i.id,
                title: '',
                titleLabels: {
                    status: i.status,
                    text: i.project_name
                },
                start: new Date(i.offer_date),
                end: dt,
                allDay: false,
                resourceId: i.artist.id
            }
        )
    })
    return events;
}

export const getData = (id: any, data: any) => {
    return data.find((i: any) => id === i.id)
}

export const getArtists = (data: any = []) => {
    let artists: any[] = [];
    data.forEach((i: any) => {
        const found = artists.some((el: any) => el.resourceId === i.artist.id);
        if (!found) artists.push({
            resourceId: i.artist.id,
            name: getArtistName(i.artist),
            firstName: i.artist.first_name,
            lastName: i.artist.last_name,
            img: i.artist.image_medium
        });
    })
    return artists;
}

export const getArtistImgFromId = (data: [], id: string) => {
    if (id === 'all') return null;
    const foundItem: any = data.find((i: any) => id === i.resourceId)
    return foundItem?.img
}

export const getCurrentWeekDays = (date: Date) => {
    const weekStart = moment(date).startOf('week');
  
    const days = [];
    for (let i = 0; i <= 6; i++) {
      days.push(moment(weekStart).add(i, 'days'));
    }
  
    return days;
  }
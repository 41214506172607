const ViewNavBar = (props: any) => {
    const {labelOne, labelTwo, view, setView, children} = props;
    const updateView = (type: string) => {
        setView(type)
    }
    return (
        <div className="view-nav-bar">
            <div className="view-nav">
                <div
                    onClick={() => updateView(labelOne)}
                    className={view === labelOne ? 'active' : ''}
                >{labelOne}</div>
                <div
                    onClick={() => updateView(labelTwo)}
                    className={view !== labelOne ? 'active' : ''}
                >{labelTwo}</div>
            </div>
            {children && children}
        </div>
    )
}

export default ViewNavBar;
/**
 * Caution: Consider this file when using react-scripts
 *
 * You may delete this file and its occurrences from the project filesystem if you are using GatsbyJS or NextJS version
 */
import React from "react";
import { Switch, Route } from "react-router-dom";
import WithLayout from "./WithLayout";
import { DefaultLayout, FormLayout, PublicLayout } from "../layout";
import { AuthGuard, GuestGuard } from "../components/Wrappers";
import { ROUTING_URLS } from "../utils/constants";
import { ForgotPassword } from "../modules/auth/ForgotPassword";

import { Login } from "../modules/auth/Login";
import { Dashboard } from "../modules/Dashboard";
import { Account } from "../modules/Account";

import { Artists } from "../modules/artists";
import { Upcoming } from "../modules/bookings";
import {
  BillingAddress,
  PaymentsHistory,
  VatDetails,
} from "../modules/payments";

import { Address, Contact, ChangePassword } from "../modules/Settings";
import Logout from "../modules/auth/Logout";
import BankDetails from "../modules/payments/BankDetails";
import Reciepts from "../modules/payments/Receipts";
import Notifications from "../modules/notifications";
import Page404 from "../modules/pages/Page404";
import Page500 from "../modules/pages/Page500";
import DataAnalytics from "../modules/Analytics";

import { usePageViews } from "../hooks";
import { ResetPassword } from "../modules/auth/ResetPassword";
import Informations from "../modules/pages/Informations";
import { pageNames } from "./config";

const Routes = () => {
  usePageViews();
  return (
    <Switch>
      <Route
        path="/"
        exact
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={Dashboard}
            layout={DefaultLayout}
            guard={AuthGuard}
            pageName={pageNames.dashboard}
          />
        )}
      />
      <Route
        path={ROUTING_URLS.logout}
        exact
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={Logout}
            layout={DefaultLayout}
            guard={AuthGuard}
          />
        )}
      />
      <Route
        path={ROUTING_URLS.artists}
        exact
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={Artists}
            layout={DefaultLayout}
            guard={AuthGuard}
            pageName={pageNames.myArtist}
          />
        )}
      />
      <Route
        exact
        path={ROUTING_URLS.login}
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={Login}
            layout={FormLayout}
            guard={GuestGuard}
          />
        )}
      />
      <Route
        exact
        path={ROUTING_URLS.signin}
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={Login}
            layout={FormLayout}
            guard={GuestGuard}
          />
        )}
      />
      <Route
        exact
        path={ROUTING_URLS.forgotPassword}
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={ForgotPassword}
            layout={FormLayout}
            guard={GuestGuard}
          />
        )}
      />
      <Route
        exact
        path={ROUTING_URLS.resetPassword}
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={ResetPassword}
            layout={FormLayout}
            guard={GuestGuard}
          />
        )}
      />
      <Route
        exact
        path={ROUTING_URLS.account}
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={Account}
            layout={DefaultLayout}
            guard={AuthGuard}
            pageName={pageNames.account}
          />
        )}
      />
      <Route
        exact
        path={ROUTING_URLS.upcomingBookings}
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={Upcoming}
            layout={DefaultLayout}
            guard={AuthGuard}
            pageName={pageNames.upcoming}
          />
        )}
      />
      <Route
        exact
        path={ROUTING_URLS.history}
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={PaymentsHistory}
            layout={DefaultLayout}
            guard={AuthGuard}
            pageName={pageNames.paymentHistory}
          />
        )}
      />
      <Route
        exact
        path={ROUTING_URLS.address}
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={Address}
            layout={DefaultLayout}
            guard={AuthGuard}
            pageName={pageNames.accountDetails}
          />
        )}
      />
      <Route
        exact
        path={ROUTING_URLS.contact}
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={Contact}
            layout={DefaultLayout}
            guard={AuthGuard}
            pageName={pageNames.contact}
          />
        )}
      />
      <Route
        exact
        path={ROUTING_URLS.changePassword}
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={ChangePassword}
            layout={DefaultLayout}
            guard={AuthGuard}
            pageName={pageNames.password}
          />
        )}
      />
      <Route
        exact
        path={ROUTING_URLS.billingAddress}
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={BillingAddress}
            layout={DefaultLayout}
            guard={AuthGuard}
            pageName={pageNames.billingAddress}
          />
        )}
      />
      <Route
        exact
        path={ROUTING_URLS.bankDetails}
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={BankDetails}
            layout={DefaultLayout}
            guard={AuthGuard}
            pageName={pageNames.bankDetails}
          />
        )}
      />
      <Route
        exact
        path={ROUTING_URLS.vatDetails}
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={VatDetails}
            layout={DefaultLayout}
            guard={AuthGuard}
            pageName={pageNames.vatDetails}
          />
        )}
      />
      <Route
        exact
        path={ROUTING_URLS.receipts + "-:type"}
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={Reciepts}
            layout={DefaultLayout}
            guard={AuthGuard}
            pageName={pageNames.reciepts}
          />
        )}
      />
      <Route
        exact
        path={ROUTING_URLS.notifications}
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={Notifications}
            layout={DefaultLayout}
            guard={AuthGuard}
            
          />
        )}
      />
      <Route
        exact
        path={ROUTING_URLS.informations}
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={Informations}
            layout={DefaultLayout}
            guard={AuthGuard}
          />
        )}
      />
      <Route
        exact
        path={ROUTING_URLS.publicInfo}
        render={(matchProps) => (
          <WithLayout
            {...matchProps}
            component={Informations}
            layout={PublicLayout}
          />
        )}
      />
      <Route
        exact
        path={ROUTING_URLS.analytics}
        render={(matchProos) => <DataAnalytics />}
      />
      <Route
        exact
        path={ROUTING_URLS.page500}
        render={(matchProps) => (
          <WithLayout {...matchProps} component={Page500} />
        )}
      />
      <Route
        exact
        path={ROUTING_URLS.page404}
        render={(matchProps) => (
          <WithLayout {...matchProps} component={Page404} />
        )}
      />
      <Route
        exact
        path={"*"}
        render={(matchProps) => (
          <WithLayout {...matchProps} component={Page404} />
        )}
      />
    </Switch>
  );
};

export default Routes;

import React from "react";
import { FormHeader, FormContainer, Card } from "./style";
import { InkButton } from "../../components";

export const FormLayout: React.FC<any> = (props) => {
  const { children, ...rest } = props;
  return (
    <>
      <FormHeader>
        <div className="form-header-inner">
          <a href="./">
            <img src="/images/Logo-white.svg" alt="" />
          </a>
          <a className="info-btn" href="#">
            Studio account beta
          </a>
        </div>
      </FormHeader>
      <FormContainer>
        <Card>{React.cloneElement(children, { ...rest })}</Card>
      </FormContainer>
    </>
  );
};

export default FormLayout;

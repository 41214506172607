import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { RootState, useSelector } from '../../store';
import { ROUTING_URLS } from '../../utils/constants';

const AuthGuard = ({ children }: any) => {
  const { pathname } = useLocation<any>()
  const auth = useSelector((state: RootState) => state.auth);
  const { isLoggedIn, active_status } = auth;

  if (!isLoggedIn) {
    return <Redirect to="/login" />;
  }

  if (pathname !== ROUTING_URLS.account && pathname !== ROUTING_URLS.logout && !active_status) 
      return <Redirect to={ROUTING_URLS.account} />

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
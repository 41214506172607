import styled from 'styled-components';

const buttonVarient = (props: any) => {
    const { theme: { palette, typography }, className: classes, ...rest } = props;
    const [className] = classes?.split(' ') || [''];
    switch (className) {
        case 'info-btn':
            return `
                font-family: ${typography.subtitle1.fontFamily};
                font-weight: 500;
                background-color: ${palette.info.main};
                color: ${palette.common.white};
                border-radius: 3px;
                font-size: ${typography.pxToRem(10)};
                padding: 0 8px;
                height: 24px;
                overflow: hidden;
                .MuiButton-label {
                    position: relative;
                    top: -1px;
                }
            `
        case 'raised-btn':
            return `
                font-weight: 500;
                border-radius: 100px;
                overflow: hidden;
                font-size: ${typography.pxToRem(14.5)};
                line-height: ${typography.pxToRem(20)};
                padding: 0 30px;
                height: 38px;
                
                @media(max-width: 767px) {
                    height: 30px;
                    font-size: ${typography.pxToRem(13)};
                    padding: 0 10px;
                }

                &.success {
                    background: ${palette.success.main};
                    color: ${palette.common.white};
                }

                &.success-lite {
                    border-color: ${palette.common.white};
                    background: ${palette.common.white};
                    color: ${palette.success.main};
                }

                &.primary {
                    border-color: ${palette.primary.main};
                    background: ${palette.primary.main};
                    color: ${palette.common.white};
                }

                &.primary-lite {
                    border-color: ${palette.common.white};
                    background: ${palette.common.white};
                    color: ${palette.primary.main};
                }

                &.danger {
                    border-color: ${palette.warning.main};
                    background: ${palette.warning.main};
                    color: ${palette.common.white};
                }
            `
        case 'flat-btn':
            return `
                font-weight: 500;
                border-radius: 4px;
                font-size: ${typography.pxToRem(14.5)};
                line-height: ${typography.pxToRem(20)};
                padding: 0 30px;
                height: 38px;
                overflow: hidden;
                pointer-events: none;

                @media(max-width: 767px) {
                    height: 30px;
                    font-size: ${typography.pxToRem(13)};
                    padding: 0 10px;
                }

                &.success {
                    background: ${palette.success.main};
                    border-color: ${palette.success.main};
                    color: ${palette.common.white};
                }

                &.success-lite {
                    background: ${palette.primary[100]};
                    border-color: ${palette.primary[100]};
                    color: ${palette.primary[800]};
                }

                &.success-lite1 {
                    background: ${palette.success.light};
                    border-color: ${palette.success.light};
                    color: ${palette.common.white};
                }

                &.danger {
                    color: ${palette.warning.main};
                    border-color: ${palette.warning.main};
                }

                &.danger-lite {
                    color: ${palette.warning.dark};
                    border-color: ${palette.warning.light};
                    background-color: ${palette.warning.light};
                }

                &.rounded {
                    border-radius: 100px;
                    pointer-events: auto;
                    cursor: pointer;
                }

                &.crossed {
                    text-decoration: line-through;
                }
            `
    }
}

export const StyledButton = styled.button`
    font-weight: 700;
    border-radius: 20px;
    box-shadow: none;
    text-transform: initial;
    height: 40px;
    padding: 0 53px;
    letter-spacing: 0.03em;
    
    &:hover {
        box-shadow: none;
    }
    
    &.btn-sm {
        min-width: 48px;
        padding: 0 10px;
        height: 30px;
    }
    

    &.disable {
       
    }

    &.cancel {
       
    }

    &.confirm {
       
    }

    &.btn-sm .MuiCircularProgress-colorPrimary,
    &.loader .MuiCircularProgress-colorPrimary {
        margin-left: 8px;
        color: inherit;
    }

    &.MuiButton-outlined.default:not(.MuiButton-outlinedPrimary) {
        color: rgba(0, 0, 0, 0.4);
    }

    &.MuiButton-outlinedPrimary.default:hover {
        color: #8C62DB;
        border: 1px solid rgba(140, 98, 219, 0.5);
        opacity: 0.85;
    }

`;



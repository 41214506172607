import { createSlice } from "@reduxjs/toolkit";

interface IState {
    countries: [];
}

const initialState: IState = {
  countries: [],
};

const slice = createSlice({
  name: "countryList",
  initialState,
  reducers: {
    updateCountries(state, {payload}) {
        state.countries = payload;
    }
  }
});
export const { updateCountries } = slice.actions;

export const reducer = slice.reducer;

export default slice;

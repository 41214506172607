import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCardTitle,
  CCol,
  CContainer,
  CRow,
  CSpinner,
} from "@coreui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { string, object, number } from "yup";
import { useEffect, useState } from "react";
import { useForm, useFormState } from "react-hook-form";
import Form from "../../../components/Form";
import { API_END_POINTS, onlySpaceCheck } from "../../../utils/constants";
import { useAxios } from "../../../hooks";
import { EToastType, showToast } from "../../../utils/toast";
import { phoneRegExp } from "../../Account/Form/config";
import { updateUser } from "../../../slices/auth";
import { useDispatch } from "../../../store";
import { Prompt } from "react-router-dom";

function ContactForm() {
  const dispatch = useDispatch();
  const [studioId, setStudioId] = useState("");
  const [contactForm, setContactForm] = useState({
    email: "",
    phone_no: "",
    contact_first_name: "",
    contact_last_name: "",
    website: "",
  });
  const validationSchema = object().shape({
    email: string()
      .email("Format not valid!")
      .required("Email Address Required!"),
    phone_no: string().matches(phoneRegExp, "Phone number is not valid"),
    contact_first_name: string()
      .matches(onlySpaceCheck.match, onlySpaceCheck.text)
      .required("First Name Required!"),
    contact_last_name: string()
      .matches(onlySpaceCheck.match, onlySpaceCheck.text)
      .required("Last Name Required!"),
    website: string().notRequired(),
  });
  const methods = useForm<any>({
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
    defaultValues: "",
  });
  const {
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = methods;
  const { isDirty } = useFormState({ control });
  const [
    { data: updateRes, loading: updateLoading, error: updateErr },
    putStudioDetails,
  ] = useAxios(
    {
      url: API_END_POINTS.getStudioDetails,
      method: "PUT",
    },
    { manual: true }
  );

  const handleSuccess = (rData: any) => {
    showToast("Changes successfully saved!", EToastType.success);
    dispatch(
      updateUser({
        name: rData.name,
        contact_first_name: rData.contact_first_name,
        contact_last_name: rData.contact_last_name,
        avatar: rData.cover_image,
        city: rData.city_name,
        address_line1: rData.address1,
        address_line2: rData.address2,
      })
    );
  };

  useEffect(() => {
    !!updateRes && !updateErr && handleSuccess(updateRes.data);
    !!updateErr && showToast("Something went wrong!", EToastType.error);
  }, [updateRes, updateErr]);
  const onSubmit = (value: any) => {
    const updateContact = async () => {
      const payloadObject: Record<string, any> = {
        studio_uid: studioId,
        ...value,
      };
      const data = new FormData();
      Object.keys(payloadObject).forEach((key: string) =>
        data.append(key, payloadObject[key])
      );
      await putStudioDetails({ data });
      reset(value);
      setContactForm(value);
    };
    updateContact();
  };
  const handleReset = () => {
    reset(contactForm);
  };
  const getInputProps: any = (
    name: string,
    label: string,
    helperText: string
  ) => {
    return {
      name,
      label,
      control: methods.control,
      getValues: methods.getValues,
      error: errors ? !!errors[name] : false,
      helperText: errors && errors[name] ? errors[name]?.message : helperText,
    };
  };
  const [{ data: res, loading, error }, fetchStudioDetails] = useAxios(
    API_END_POINTS.getStudioDetails,
    { manual: true }
  );

  useEffect(() => {
    fetchStudioDetails();
  }, []);

  useEffect(() => {
    if (!!res) {
      const { data } = res;
      const {
        cover_image,
        active_status,
        address1,
        address2,
        city_name,
        country,
        created_profile,
        id,
        latitude,
        longitude,
        name,
        phone_code,
        zipcode,
        ...rest
      } = data;
      setContactForm(rest);
      reset(rest);
      setStudioId(id);
    }
    !!error && console.log(error);
  }, [res, error]);

  return !!loading ? (
    <CSpinner />
  ) : (
    <>
      <Prompt
        when={isDirty}
        message=""
      />
      <CCard>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CCardHeader>
            Contact
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol sm="6">
                <Form.Input
                  gridtype="col"
                  helperTextClassName="neutral-helper-text"
                  {...getInputProps("email", "Email address*", "Changing this mail also affects your login data")}
                />
                <Form.Input
                  gridtype="col"
                  {...getInputProps("phone_no", "Phone number*")}
                />
                <Form.Input
                  gridtype="col"
                  {...getInputProps(
                    "contact_first_name",
                    "Studio Owner First Name*"
                  )}
                />
              </CCol>
              <CCol sm="6">
                <Form.Input
                  gridtype="col"
                  {...getInputProps(
                    "contact_last_name",
                    "Studio Owner Last Name*"
                  )}
                />
                <Form.Input
                  gridtype="col"
                  {...getInputProps("website", "Studio’s Website")}
                />
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter>
            <CButton type="submit" color="info" variant="outline" disabled={!isDirty}>
              Save Changes
              {updateLoading && (
                <CSpinner className="ms-2" color="light" size="sm" />
              )}
            </CButton>
            {isDirty && (
              <CButton
                className="mx-3"
                color="dark"
                variant="outline"
                onClick={handleReset}
              >
                Reset All
              </CButton>
            )}
          </CCardFooter>
        </form>
      </CCard>
    </>
  );
}

export default ContactForm;

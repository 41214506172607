import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCardTitle,
  CCol,
  CRow,
  CSpinner,
} from "@coreui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { string, object, mixed, number } from "yup";
import { useForm, useFormState } from "react-hook-form";
import Form from "../../../components/Form";
import { API_END_POINTS, onlySpaceCheck } from "../../../utils/constants";
import { useEffect, useState } from "react";
import { useSelector } from "../../../store";
import { useAxios } from "../../../hooks";
import { EToastType, showToast } from "../../../utils/toast";
import { Prompt } from "react-router-dom";

function BillingAddressForm() {
  const { countries } = useSelector((state) => state.countries);
  const validationSchema = object().shape({
    company_name: mixed().notRequired(),
    first_name: string()
      .matches(onlySpaceCheck.match, onlySpaceCheck.text)
      .required("First Name Required!"),
    last_name: string()
      .matches(onlySpaceCheck.match, onlySpaceCheck.text)
      .required("Last Name Required!"),
    address_line1: string()
      .matches(onlySpaceCheck.match, onlySpaceCheck.text)
      .required("Address Required!"),
    address_line2: mixed().notRequired(),
    zipcode: string()
      .matches(onlySpaceCheck.match, onlySpaceCheck.text)
      .required("Zipcode Required!"),
    city: string()
      .matches(onlySpaceCheck.match, onlySpaceCheck.text)
      .required("City Required!"),
    country: object({
      id: number()
        .typeError("Please select a country")
        .required("Please select a country"),
      name: string().notRequired(),
    }),
  });

  const [addressFormData, setAddressFormData] = useState({
    company_name: "",
    first_name: "",
    last_name: "",
    address_line1: "",
    address_line2: "",
    zipcode: "",
    city: "",
    country: {
      id: "",
      name: "",
    },
  });


  const methods = useForm<any>({
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
    defaultValues: "",
  });

  const {
    handleSubmit,
    formState: { errors },
    reset,
    register,
    control
  } = methods;
  const { isDirty } = useFormState({ control });
  const [{ data: response, loading, error }, fetchBillingAddress] = useAxios(
    API_END_POINTS.getBillingAddress, {
    manual: true
  }
  );
  useEffect(() => {
    fetchBillingAddress()
  }, [])
  useEffect(() => {
    if (!!response) {
      const {
        data: { tax },
      } = response;
      if (!!tax && Object.keys(tax).length) {
        const { id, country, ...rest } = tax;
        setAddressFormData({
          ...rest,
          country: {
            id: country?.id || 0,
            name: country?.name || "",
          },
        });
      }
    }
    !!error && console.log(error);
  }, [response, error]);

  useEffect(() => {
    reset(addressFormData);
  }, [addressFormData]);

  const [
    { data: updateData, loading: updateLoading, error: updateError },
    updateBillingAddress,
  ] = useAxios(
    {
      url: API_END_POINTS.updateBillingAddress,
      method: "POST",
    },
    { manual: true }
  );

  useEffect(() => {
    !!updateData &&
      !updateError &&
      showToast("Changes successfully saved!", EToastType.success);
    !!updateError && showToast("Something went wrong!", EToastType.error);
  }, [updateData, updateError]);
  const onSubmit = async (value: any) => {
    const {
      country: { id },
      ...rest
    } = value;
    await updateBillingAddress({
      data: {
        country_id: id,
        ...rest,
      },
    });
    setAddressFormData(value);
  };

  const handleReset = () => {
    reset(addressFormData);
  };

  const getInputProps: any = (
    name: string,
    label: string,
    helperText: string
  ) => {
    return {
      name,
      label,
      control: methods.control,
      getValues: methods.getValues,
      error: errors ? !!errors[name] : false,
      helperText: errors && errors[name] ? errors[name]?.message : helperText,
    };
  };

  return !!loading ? (
    <CSpinner />
  ) : (
    <>
      <Prompt when={isDirty} message="" />
      <CCard>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CCardHeader>
            Billing Address
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol sm="6">
                <Form.Input
                  gridtype="col"
                  {...getInputProps("company_name", "Company Name")}
                />
                <Form.Input
                  gridtype="col"
                  {...getInputProps("first_name", "Your first name*")}
                />
                <Form.Input
                  gridtype="col"
                  {...getInputProps("last_name", "Your last name*")}
                />
                <Form.Input
                  gridtype="col"
                  {...getInputProps("address_line1", "Address line 1*")}
                />
              </CCol>
              <CCol sm="6">
                <Form.Input
                  gridtype="col"
                  {...getInputProps("address_line2", "Address line 2")}
                />
                <Form.Input
                  gridtype="col"
                  {...getInputProps("zipcode", "Zip code*")}
                />
                <Form.Input
                  gridtype="col"
                  {...getInputProps("city", "City*")}
                />
                <Form.Select
                  {...getInputProps("country.id", "Country*")}
                  register={register}
                  options={countries}
                ></Form.Select>
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter>
            <CButton type="submit" color="info" variant="outline" disabled={!isDirty}>
              Save Changes
              {updateLoading && (
                <CSpinner className="ms-2" color="light" size="sm" />
              )}
            </CButton>
            {isDirty && (
              <CButton
                className="mx-3"
                color="dark"
                variant="outline"
                onClick={handleReset}
              >
                Reset All
              </CButton>
            )}
          </CCardFooter>
        </form>
      </CCard>
    </>
  );
}

export default BillingAddressForm;

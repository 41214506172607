
import {VatDetailsForm} from "./Form";

function VatDetails() {
    return (
        <div>
            <VatDetailsForm></VatDetailsForm>
        </div>
    )
}

export default VatDetails

import React from "react";
import {
  CAvatar,
  CBadge,
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { useSelector } from "../../../store";
import { ReactComponent as UserIcon } from './../../../icons/user-sm.svg';

const AppHeaderDropdown = () => {
  const { user } = useSelector((state) => state.auth);
  return (
    <CDropdown variant="nav-item" className="account-dd-wrapper" >
      <CDropdownToggle className="py-0" caret={false}>
        {/* <CIcon name="cil-user" className="me-2" /> */}
        <UserIcon />
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownHeader className="bg-light fw-semibold py-2">
          Account
        </CDropdownHeader>
        <CDropdownItem>
          <span className="text-strong" style={{fontWeight: 500}} >
            {" "}
            {user?.contact_first_name
              ? `${user.contact_first_name} ${user.contact_last_name || ""}`
              : "Studio owner"}
          </span>
          <br />
          <span className="text-strong" style={{fontSize: 14}} > {user?.name || ""}</span>
        </CDropdownItem>

        <CDropdownDivider />
        <CDropdownItem href="/logout">
          <CIcon icon="cil-account-logout" className="me-2" />
          Log out
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default AppHeaderDropdown;

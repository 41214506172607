import React from "react";

interface FilterChipProps {
    filterKey?: string | null;
    onClickButton?: Function;
    isGlobal?: boolean;
}

const FilterChip: React.FC<FilterChipProps> = ({
    filterKey,
    onClickButton,
    isGlobal = false
}) => {
    return (
        <div
            className={`filter-chip ${isGlobal ? 'chip-disabled' : ''}`}
            style={{
                cursor: onClickButton ? 'pointer' : 'initial'
            }}
            onClick={() => onClickButton && onClickButton()}
        >
            <h6>{filterKey}</h6>
        </div>
    )
}

export default FilterChip;

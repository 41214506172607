import CIcon from "@coreui/icons-react";
import { CAvatar, CBadge, CButton, CButtonGroup, CModalFooter, CTooltip } from "@coreui/react"
import { IArtistCardData, sahreReqInfo } from "..";
import { EArtistTypeLabels } from "../../../utils/constants";
import { EArtistStatusLabel, EArtistTypes } from "../../../utils/enums";
import { badgeBgGen, decodeArtistData, getArtistImage, getArtistLabel, getArtistName, getArtistRelLabel, getStatusLabel, isFreelancer } from "../functions";
import { RowActions } from "./RowActions";

function ArtistListDetail({ data, fecthList }: any) {
    const { address_line1, address_line2, city, zipcode, email, phone_no, artist_type, phone_code, country: { name } } = data;
    const displayName = getArtistName(data);
    const displayImage = getArtistImage(data);
    const status = getStatusLabel(data);
    const isFreelance = data.artist_type === EArtistTypes.Freelancer;
    const isPeriodic = data.artist_type === EArtistTypes.Freelancer && data.artist_is_periodic;
    const studioShare = parseInt(data.studio_share.share) || 0;
    const studioNewShare = parseInt(data.studio_share.new_share) || 0;
    const artistShare = 100 - parseInt(data.studio_share.share) || 0;
    const artistNewShare = 100 - parseInt(data.studio_share.new_share) || 0;
    const studioShareStatus = data.studio_share.status;
    const {
        isActive,
    } = decodeArtistData(data);
    const relLabel = getArtistRelLabel(data);
    const [badgeBg, badgeTextColor] = badgeBgGen(status);
    const artistLabel = getArtistLabel(relLabel)
    return (
        <div className="px-md-3">
            {/* <div className="d-flex justify-content-between align-items-start mb-4"> */}
            <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center justify-content-between mb-2">
                    <h6 className="text-capitalize mb-0 text-truncate pe-2 title-xs-artist">
                        <b>{displayName}</b>
                    </h6>
                </div>
                <CTooltip content={artistLabel} placement="left">
                    <CBadge className={`text-capitalize rel-label ${relLabel}`}>{relLabel}</CBadge>
                </CTooltip>

            </div>
            <div className="d-flex align-items-end justify-content-between mb-4">
                <div></div>
                <CBadge
                    textColor={badgeTextColor}
                    color={badgeBg}
                    style={{ ['--cui-secondary' as any]: (status === EArtistStatusLabel.Inactive || status === EArtistStatusLabel.Rejected) && '#ced2d8' }}
                >{status}</CBadge>
            </div>
            <div>
                {
                    isFreelance && !isPeriodic && (
                        <div className="mb-5">
                            <div className="pb-2 mb-3 text-center">
                                <h6 className="mb-0"><b>Revenue Split</b></h6>
                            </div>
                            <div className="d-flex flex-column justify-content-center text-center">
                                <div className="d-flex justify-content-between">
                                    <p className="text-uppercase h6 flex-grow-1">Artist</p>
                                    <p className="text-uppercase h6 flex-grow-1">Studio</p>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <div className="flex-grow-1">
                                        {
                                            studioShareStatus !== 'pending' &&
                                            <p className="h2 flex-grow-1 popup-line-full-width share-active-bg" style={{ fontWeight: 800 }}>{artistShare}%</p>

                                        }
                                        {
                                            (studioShareStatus === 'pending' && studioShare === studioNewShare) &&
                                            <p className="h2 flex-grow-1 popup-line-full-width share-pending-bg" style={{ fontWeight: 800 }}>{artistShare}%</p>
                                        }
                                        {studioShare !== studioNewShare && (
                                            <>
                                                <p className="h2 flex-grow-1 popup-line-full-width mb-2 share-active-bg" style={{ fontWeight: 800 }}>{artistShare}%</p>
                                                <p className="h2 flex-grow-1 popup-line-full-width share-pending-bg" style={{ fontWeight: 800 }}>
                                                    <span className="position-relative">{artistNewShare}%
                                                        <CTooltip content={sahreReqInfo} placement="top">
                                                            <CIcon icon="cil-info" className="icon ms-3 position-absolute top-50 translate-middle-y text-dark" />
                                                        </CTooltip>
                                                    </span>
                                                </p>
                                            </>
                                        )}
                                    </div>
                                    <div className="flex-grow-1">
                                        {
                                            studioShareStatus !== 'pending' &&
                                            <p className="h2 flex-grow-1 popup-line-full-width share-active-bg" style={{ fontWeight: 800 }}>{studioShare}%</p>
                                        }
                                        {
                                            (studioShareStatus === 'pending' && studioShare === studioNewShare) &&
                                            <p className="h2 flex-grow-1 popup-line-full-width share-pending-bg" style={{ fontWeight: 800 }}>{studioShare}%</p>
                                        }
                                        {studioShare !== studioNewShare && (
                                            <>
                                                <p className="h2 flex-grow-1 mb-2 popup-line-full-width share-active-bg" style={{ fontWeight: 800 }}>{studioShare}%</p>
                                                <p className="h2 flex-grow-1 popup-line-full-width share-pending-bg" style={{ fontWeight: 800 }}>
                                                    <span className="position-relative">{studioNewShare}%
                                                        <CTooltip content={sahreReqInfo} placement="top">
                                                            <CIcon icon="cil-info" className="icon ms-3 position-absolute top-50 translate-middle-y text-dark" />
                                                        </CTooltip>
                                                    </span>
                                                </p>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }

                <div className="mb-4">
                    <div className="pb-2 mb-3">
                        <h6 className="mb-0"><b>Artist details</b></h6>
                    </div>
                    <div>
                        <div className="d-flex mb-1 list-bg popup-line-full-width">
                            <span style={{ width: '70px' }}><b>Email:</b></span>
                            <span>{email}</span>
                        </div>
                        <div className="d-flex mb-1 list-bg popup-line-full-width">
                            <span style={{ width: '70px' }}><b>Phone:</b></span>
                            <span>{phone_code} {phone_no}</span>
                        </div>
                        <div className="d-flex mb-1 list-bg popup-line-full-width">
                            <span style={{ width: '70px' }}><b>Address:</b></span>
                            <span> {address_line1 && (<>{address_line1} <br /></>)}
                                {address_line2 && (<>{address_line2} <br /></>)}
                                {city && (<>{city} </>)}
                                {zipcode && (<>{zipcode} <br /></>)}
                                {name && (<>{name} <br /></>)}</span>
                        </div>
                    </div>
                </div>
                {
                    (status === EArtistStatusLabel.Pending || status === EArtistStatusLabel.Accepted) && (
                        <CModalFooter className="mt-4 px-0 pb-0 popup-line-full-width">
                            <RowActions row={data} fecthList={fecthList} />
                        </CModalFooter>
                    )
                }
            </div>
        </div>
    )
}

export { ArtistListDetail }

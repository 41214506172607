import { useEffect } from "react";
import { updateCountries } from "../../../slices/countryLists";
import { useDispatch } from "../../../store";
import axios from "../../../utils/axios";
import { API_END_POINTS } from "../../../utils/constants";
import BillingAddressForm from "./Form"

function BillingAddress() {
    const dispatch = useDispatch();
    useEffect(() => {
        async function fetchCountryList() {
            const response = await axios.post(API_END_POINTS.getCountryList);
            const counttryList = response.data.data.countries;
            dispatch(updateCountries(counttryList))
        }
        fetchCountryList();
    }, []);
    return (
       <BillingAddressForm></BillingAddressForm>
    )
}

export default BillingAddress

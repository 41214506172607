import React from "react";
import { Controller } from "react-hook-form";

import { CFormLabel, CFormText, CFormCheck } from "@coreui/react";

interface Props {
  [key: string]: any;
}

export function Checkbox(props: Props) {
  const {
    name,
    control,
    defaultValue,
    getValues,
    title,
    label,
    error,
    helperText,
    ...rest
  } = props;
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field }) => {
        const { value, ...fieldRest } = field;
        return (
          <>
            {title && <CFormLabel>{title}</CFormLabel>}
            <CFormCheck {...fieldRest} {...rest} label={label} />
            {helperText && (
              <CFormText style={{ position: "relative" }}>
                {helperText}
              </CFormText>
            )}
          </>
        );
      }}
    />
  );
}

import React, { ReactNode, useState } from "react";
import CIcon from "@coreui/icons-react";
import * as icon from '@coreui/icons';
import DatePicker, { utils } from '@hassanmojab/react-modern-calendar-datepicker';
import { DateRangeSelect } from "../_lib/constants";

interface ContentHeaderProps {
    title?: string;
    description?: ReactNode;
    onClickLeftButton?: Function;
    onClikResetButton?: Function;
}

const ContentHeader: React.FC<ContentHeaderProps> = ({
    title = '',
    description,
    onClickLeftButton,
    onClikResetButton
}) => {
    const [isActive, setIsActive] = useState<boolean>(false);
    const [globalDayRange, setGlobalDayRanbe] = useState<DateRangeSelect>({
        from: null,
        to: null
    });

    const toggleGlobalToday = (): void => {
        setIsActive(true);
        if (onClickLeftButton) {
            onClickLeftButton();
        }
    };

    const toggleReset = (): void => {
        setIsActive(false);
        if (onClikResetButton) {
            onClikResetButton();
        }
    };

    const handleGlobalDateRangeFilter = (dateRange: DateRangeSelect): void => {
        setGlobalDayRanbe(dateRange);
    };

    const renderCustomInput = ({ ref }: any) => (
        <button
            className='filter-button-text'
            title="Today"
            ref={ref}
        >
            Filter By Date Range
        </button>
      );

    return (
        <div className="row align-items-start">
            <div className="col-lg-12 col-md-12 col-12 mb-lg-0 mb-sm-2 mb-2">
                <div className="detail-content">
                    <h2>{title}</h2>
                    {description}
                </div>
            </div>
            {onClickLeftButton && (
                <div className="col-lg-4 col-md-5 col-12 mb-sm-0 mb-2 mt-sm-0 mt-3">
                    <div className='card-action-row'>
                        <button
                            className='filter-button-text'
                            onClick={toggleGlobalToday}
                            title="Today"
                        >
                            Filter By Today
                        </button>
                        <div>
                            <DatePicker
                                value={globalDayRange}
                                onChange={handleGlobalDateRangeFilter}
                                shouldHighlightWeekends
                                maximumDate={utils('en').getToday()}
                                renderInput={renderCustomInput}
                            />
                        </div>
                        {isActive && (
                            <button
                                className='filter-button'
                                onClick={toggleReset}
                                title="Reset"
                            >
                                <CIcon icon={icon?.cilReload} />
                            </button>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}

export default ContentHeader;
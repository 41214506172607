export enum FilterType {
    total = 'total',
    today = 'today',
    dateRange = 'date-range'
}

interface DateObject {
    day: number;
    month: number;
    year: number;
}

export interface DateRangeSelect {
    from : DateObject | null;
    to : DateObject | null;
}

export interface OfferAnalyticData {
    completed_count: number;
    pending_count: number;
    scheduled_count: number;
    draft_count: number;
    rejected_count: number;
    completed_offer_amount_chf: string;
    completed_offer_amount_eur: string;
    completed_offer_amount_gbp: string;
    pending_offer_amount_chf: string;
    pending_offer_amount_eur: string;
    pending_offer_amount_gbp: string;
    scheduled_offer_amount_chf: string;
    scheduled_offer_amount_eur: string;
    scheduled_offer_amount_gbp: string;
    rejected_offer_amount_chf: string;
    rejected_offer_amount_eur: string;
    rejected_offer_amount_gbp: string;
    draft_offer_amount_chf: string;
    draft_offer_amount_eur: string;
    draft_offer_amount_gbp: string;
}

export enum OfferType {
    pending = 'pending',
    scheduled = 'scheduled',
    completed = 'completed',
    draft = 'draft',
    rejected = 'rejected'
}

export interface AnalyticCardRef {
    toggleToday: Function;
    resetFilter: Function;
    toggleDateRange: Function;
}

export interface OfferAnalyticCardRef {
    toggleOfferTodayFilter: Function;
    toggleOfferDateRangeFilter: Function;
    toggleOfferReset: Function;
}

export interface OfferAnalyticRef {
    resetFilter: Function;
}


import { useEffect } from "react";
import CIcon from "@coreui/icons-react";
import {
  CAvatar,
  CCard,
  CCardBody,
  CCardGroup,
  CCardHeader,
  CCol,
  CRow,
  CSpinner,
  CTable,
  CWidgetProgressIcon,
} from "@coreui/react";
import { useAxios } from "../../hooks";
import { useSelector } from "../../store";
import {
  API_END_POINTS,
  defaultImages,
  ROUTING_URLS,
} from "../../utils/constants";
import { numToFixed, toInt } from "../../utils/functions";
import { StatiCard } from "./components/StatiCard";
export const Dashboard = () => {
  const { user, isOnlyperiodic } = useSelector((state) => state.auth);
  const [{ data: response, error, loading }, getDashboardCount] = useAxios(
    API_END_POINTS.getDashboardStati,
    {
      manual: true
    }
  );

  useEffect(() => {
    getDashboardCount();
  }, [])

  const data = response?.data || {};

  const cardGroupArr = [
    {
      title: "Bookings",
      value: toInt(data?.total_bookings || 0, true),
      icon: "cil-list-rich",
      color: "light",
      to: ROUTING_URLS.upcomingBookings,
    },
    {
      title: "Earnings",
      value: `${data?.currency?.code || ""} ${numToFixed(
        data?.total_earnings || 0
      )}`,
      icon: "cil-chart-pie",
      color: "light",
      to: ROUTING_URLS.history,
    },
    {
      title: "Next Payment",
      value: `${data?.currency?.code || ""} ${numToFixed(
        data?.next_payout_amount || 0
      )}`,
      icon: "cil-money",
      color: "light",
      to: ROUTING_URLS.history,
    },
    {
      title: "Affiliated Artists",
      value: toInt(data?.affiliated_artists || 0, true),
      color: "light",
      icon: "cil-people",
      to: ROUTING_URLS.artists,
    },
    {
      title: "Inactive Artists",
      value: toInt(data?.inactive_artists || 0, true),
      icon: "cil-people",
      color: "light",
      to: ROUTING_URLS.artists,
    },
    {
      title: "Pending Artists",
      value: toInt(data?.pending_artists || 0, true),
      icon: "cil-people",
      color: "light",
      to: ROUTING_URLS.artists,
    },
  ];


  return (
    <div className="dashboard-cards">
      <CCardGroup>
        {cardGroupArr.map((x: any) => {
          if (((x.title === 'Earnings') || (x.title === 'Next Payment')) && isOnlyperiodic) {
            return;
          } else {
            return (
              <StatiCard {...x} />
            )
          }
        })}
      </CCardGroup>
    </div>
  );
};

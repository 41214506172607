import CIcon from "@coreui/icons-react";
import { CWidgetProgressIcon } from "@coreui/react";
import { useHistory } from "react-router-dom";
export const StatiCard = (props: any) => {
  const history = useHistory();
  const { icon, value, title, color, to, ...rest } = props;
  return (
    <CWidgetProgressIcon
      icon={<CIcon icon={icon} size="xl" />}
      value={<span>{value}</span>}
      title={title}
      progressValue={100}
      className={`m-2 rounded-3 dashboard-tile ${!!+value && title==="Pending Artists" ? 'card-highlight' : ''}`}
      style={{ 
        cursor: "pointer", 
      }}
      onClick={() => history.push(to || "/")}
      {...rest}
    />
  );
};

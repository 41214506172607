import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCardTitle,
  CCol,
  CRow,
  CSpinner,
} from "@coreui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { string, object, number, mixed } from "yup";
import { useEffect, useState } from "react";
import { useForm, useFormState } from "react-hook-form";
import Form from "../../../components/Form";
import { useDispatch, useSelector } from "../../../store";
import { API_END_POINTS, onlySpaceCheck } from "../../../utils/constants";
import { useAxios } from "../../../hooks";
import { ImageCropper } from "../../../components/ImageCropper";
import { EToastType, showToast } from "../../../utils/toast";
import DeleteAccount from "./DeleteAccount";
import { updateUser } from "../../../slices/auth";
import { Prompt } from "react-router-dom";

function AddressForm() {
  const dispatch = useDispatch();
  const [coverImage, setCoverImage]: any = useState("");
  const [city, setCity] = useState("");
  const [studioId, setStudioId] = useState("");
  const validationSchema = object().shape({
    name: string()
      .matches(onlySpaceCheck.match, onlySpaceCheck.text)
      .required("Studio name required!"),
    address1: string()
      .matches(onlySpaceCheck.match, onlySpaceCheck.text)
      .required("Address required!"),
    address2: mixed().notRequired(),
    zipcode: string()
      .matches(onlySpaceCheck.match, onlySpaceCheck.text)
      .required("Zipcode required!"),
    city_name: string()
      .matches(onlySpaceCheck.match, onlySpaceCheck.text)
      .required("City required!"),
    latitude: string().required(),
    longitude: string().required(),
    country: object({
      id: number().required("Country required!"),
      name: string().required(),
    }),
    cover_image: mixed().notRequired(),
  });

  const [addressForm, setAddressForm] = useState({
    name: "",
    address1: "",
    address2: "",
    zipcode: "",
    city_name: "",
    latitude: "",
    longitude: "",
    cover_image: "",
    country: {
      id: 0,
      name: "",
    },
  });
  const methods = useForm<any>({
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
    defaultValues: "",
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    getValues,
    control,
  } = methods;
  const { isDirty } = useFormState({ control });

  const [
    { data: response, loading: formSubmitLoading, error: formSubmitError },
    putStudioDetails,
  ] = useAxios(
    {
      url: API_END_POINTS.getStudioDetails,
      method: "PUT",
    },
    { manual: true }
  );

  const handleSuccess = (rData: any) => {
    showToast("Changes successfully saved!", EToastType.success);
    dispatch(
      updateUser({
        name: rData.name,
        contact_first_name: rData.contact_first_name,
        contact_last_name: rData.contact_last_name,
        avatar: rData.cover_image,
        city: rData.city_name,
        address_line1: rData.address1,
        address_line2: rData.address2,
      })
    );
  };
  useEffect(() => {
    !!response && !formSubmitError && handleSuccess(response.data);
    !!formSubmitError && showToast("Something went wrong!", EToastType.error);
  }, [response, formSubmitError]);
  const onSubmit = (value: any) => {
    const { cover_image, country, ...rest } = value;
    const updateStudioDetails = async () => {
      const payloadObject: any = {
        ...rest,
        studio_uid: studioId,
        ...(!!coverImage && { cover_image: coverImage }),
        country: addressForm.country.id,
      };
      const data = new FormData();
      const coverImgLabel = new Date().getTime() + coverImage?.fileName;
      Object.keys(payloadObject).forEach((key: string) => {
        key === "cover_image"
          ? data.append(key, payloadObject[key], coverImgLabel)
          : data.append(key, payloadObject[key]);
      });
      await putStudioDetails({ data });
      reset(value);
      setAddressForm({ ...value, country: addressForm.country });
    };
    updateStudioDetails();
  };

  const getInputProps: any = (
    name: string,
    label: string,
    helperText: string
  ) => {
    return {
      name,
      label,
      control: methods.control,
      getValues: methods.getValues,
      error: errors ? !!errors[name] : false,
      helperText: errors && errors[name] ? errors[name]?.message : helperText,
    };
  };

  const [{ data: res, loading, error }, fetchStudioDetails] = useAxios(
    API_END_POINTS.getStudioDetails,
    { manual: true }
  );

  useEffect(() => {
    fetchStudioDetails();
  }, []);

  const handleReset = () => {
    reset(addressForm);
  };
  useEffect(() => {
    if (!!res) {
      const { data } = res;
      const {
        cover_image,
        active_status,
        created_profile,
        email,
        id,
        phone_code,
        contact_first_name,
        contact_last_name,
        website,
        phone_no,
        ...rest
      } = data;
      setStudioId(id);
      const formData = {
        ...rest,
        ...(!!cover_image && {
          cover_image: `${cover_image}?${new Date().getTime()}`,
        }),
      };
      setAddressForm(formData);
      setCity(rest.city_name);
    }
    !!error && console.log(error);
  }, [res, error]);

  useEffect(() => {
    reset(addressForm);
  }, [addressForm]);

  return loading ? (
    <CSpinner />
  ) : (
    <>
      <Prompt when={isDirty} message="" />
      <CCard>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CCardHeader>
            Account Details
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol sm="6">
                <Form.Input
                  gridtype="col"
                  {...getInputProps("name", "Studio name*")}
                />
                <div className="mb-4 pb-3 form-wrap">
                  <CRow>
                    <CCol lg={4}>
                      <label htmlFor="cover_image">Studio cover image</label>
                    </CCol>
                    <CCol lg={8}>
                      <ImageCropper
                        data={["Crop image"]}
                        setImage={setCoverImage}
                        getValues={getValues}
                        setValue={setValue}
                      />
                    </CCol>
                  </CRow>
                </div>
                <Form.Input
                  gridtype="col"
                  {...getInputProps("address1", "Address line 1*")}
                />
                <Form.Input
                  gridtype="col"
                  {...getInputProps("address2", "Address line 2")}
                />
              </CCol>
              <CCol sm="6">
                <Form.Input
                  gridtype="col"
                  {...getInputProps("zipcode", "Zip code*")}
                />
                <Form.LocationSearchInput
                  getValues={getValues}
                  setValue={setValue}
                  gridtype="col"
                  initialvalue={addressForm.city_name}
                  {...getInputProps("city_name", "City*")}
                />
                <Form.Select
                  {...getInputProps("country", "Country*")}
                  disabled={true}
                  gridtype="col"
                ></Form.Select>
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter>
            <CRow>
              <CCol xs={10}>
                <CButton type="submit" color="info" variant="outline" disabled={!isDirty}>
                  Save Changes
                  {formSubmitLoading && (
                    <CSpinner className="ms-2" color="light" size="sm" />
                  )}
                </CButton>
                {isDirty && (
                  <CButton
                    className="mx-2"
                    color="dark"
                    variant="outline"
                    onClick={handleReset}
                  >
                    Reset All
                  </CButton>
                )}
              </CCol>
              <CCol xs="2">
                <DeleteAccount />
              </CCol>
            </CRow>
          </CCardFooter>
        </form>
      </CCard>
    </>
  );
}

export default AddressForm;

import { CAvatar, CBadge } from '@coreui/react';
import { useMemo } from 'react';
import { Column } from "react-table";
import { ContentModal } from '../../../components';
import Table, { TButton } from '../../../components/Table';
import { formateDate, numToFixed } from '../../../utils/functions';
import BookingDetails from '../../bookings/Upcoming/Details';
import HistoryDetailsPopup from './DetailsPopup';

const PaymentList = (props: any) => {
    const { data, error, loading } = props;
    const columns = useMemo<Column<any>[]>(
        () => [
            {
                Header: "Artist",
                accessor: (row) => {
                    const displayName = `${row.artist_firstname} ${row.artist_lastname}`;
                    const displayImage = `${row.artist_profile_image}`;
                    return (
                        <div className="d-flex align-items-center">
                            <CAvatar size={"sm"} src={displayImage} className="me-2 artist-avatar hide-xs" />
                            <span>{row.artist_firstname}</span> &nbsp;
                            <span className="hide-xs">{row.artist_lastname}</span>
                        </div>
                    );
                },
            },
            {
                Header: "Customer",
                className: 'hide-xs',
                accessor: (row) => {
                    const displayName = `${row.customer_firstname} ${row.customer_lastname}`;
                    return <>{displayName}</>;
                },
            },
            {
                Header: "Project",
                accessor: "project",
                className: 'hide-xs',
            },
            {
                Header: 'Date',
                accessor: "created_at",
                Cell: ({ row }: any) => <span className="pe-2">{formateDate(row.original.offer.offer_date)}</span>,
            },
            {
                Header: "Earnings",
                accessor: (row) => (
                    <>
                        <div className="hide-xs">
                            {`${row.currency || ""} ${numToFixed(row.amount - row.studio_service_fee)}`}
                        </div>
                        <div className="show-xs xs-action-none">
                            {numToFixed(row.amount - row.studio_service_fee)}
                        </div>
                    </>
                ),
            },
            {
                Header: "Status",
                accessor: (row) => {
                    const payoutStatus = row.payout_status;
                    let payoutLabel = '';
                    (payoutStatus === 'complete') ? payoutLabel = 'Payout executed' : payoutLabel = 'Payout Pending';
                    return (
                        <>
                            <CBadge
                                textColor={payoutStatus === 'complete' ? 'white' : 'dark'}
                                color={payoutStatus === 'complete' ? 'payout-success' : 'warning'} className="text-capitalize hide-xs">{payoutLabel}</CBadge>
                            <div
                                style={{ height: "15px", width: '15px', margin: 'auto' }}
                                className={`${payoutStatus === 'complete' ? 'bg-payout-success' : 'bg-warning'} rounded-circle show-xs xs-action-none`}></div>
                        </>
                    )
                }
            },
            {
                Header: "",
                accessor: "id",
                className: "actions",
                Cell: ({ row }: any) => {
                    return (
                        <>
                            <ContentModal
                                buttonElm={<TButton size="sm">Details</TButton>}
                                size="lg"
                            >
                                <HistoryDetailsPopup row={row.original} />
                            </ContentModal>
                        </>
                    )
                }
            },
        ],
        []
    );

    return (
        <Table
            columns={columns}
            data={data || []}
            loading={loading}
            error={!!error}
            extraConfig={{
                initialState: {
                    sortBy: [
                        {
                            id: "created_at",
                            desc: true,
                        },
                    ],
                },
            }}
        />
    );
}

export default PaymentList

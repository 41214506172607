import moment, { Moment } from "moment";
import { SyntheticEvent, useEffect, useMemo, useState } from "react";
import { updateDate } from "../../../slices/booking";
import { useDispatch, useSelector } from "../../../store";
import { getCurrentWeekDays } from "./functions";

const MobileWeekTimeMobile = () => {
    const { calDate } = useSelector(state => state.bookings);
    const dispatch = useDispatch();
    const [activeNum, setActiveNum] = useState<number>();
    const dates = useMemo(() => {
        return getCurrentWeekDays(calDate)
    }, [calDate]);
    const onWeekItemClick = (date: Date, i: number) => {
        setActiveNum(i);
        dispatch(updateDate(date))
    }
    return (
        <div className="mobile-week-header">
            {dates?.map((item: any, i: any) => {
                const currentDate = moment(calDate).format('YYYMMDD');
                const itemDate = moment(item._d).format('YYYMMDD');
                return (
                    <div key={i} onClick={(e) => onWeekItemClick(item._d, i)}
                        className={activeNum === i || currentDate === itemDate ? 'active' : ''}>
                        <span className="day">{moment(item).format('ddd')}</span>
                        <span className="date">{moment(item).format('D')}</span>
                    </div>
                )
            })}
        </div>
    )
}

export default MobileWeekTimeMobile;